import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import builderimg from "../../img/builderimg.png";
import lock from "../../img/lock.png";

import axios from "axios";

import BuilderArray from "../../components/BuilderArray";
import CustomBtn from "../../components/CustomBtn";
import DropDown from "../../components/UI/DropDown/DropDown";
import styled, { useTheme } from "styled-components";
import { saveAs } from 'file-saver';

import Loader from "../../img/loading.gif";
import Reload from "../../img/reload.png";
import Failed from "../../img/failedtransaction.png";
import { Checkmark } from 'react-checkmark'

const ScrollableDiv = styled.div`
  background-color: ${({ theme }) => theme?.color2};
  &::-webkit-slider-thumb {
    background: ${({ theme }) => theme?.color1} !important;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }
`;

const MemeGenerator = (props) => {
  console.log(props)
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();

  const [selectedCategory, setSelectedCategory] = useState();

  const [assetDict, setAssetDict] = useState();

  const [metadata, setMetadata] = useState();
  const [idMetadata, setIDMetadata] = useState()
  const [tokenID, setTokenID] = useState()

  const [imageArray, setImageArray] = useState([]);
  const [categoryMappings, setCategoryMappings] = useState({});
  const [secondaryCategories, setSecondaryCategories] = useState([]);

  const [blockedTraits, setBlockedTraits] = useState();

  const [builderState, setBuilderState] = useState("Base")
  const [builderStates, setBuilderStates] = useState([])
  const [readyToMeme, setReadyToMeme] = useState(false)
  const [allMemes, setAllMemes] = useState([])
  const [selectedMeme, setSelectedMeme] = useState({})

  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("default");

  const [wrapperSize, setWrapperSize] = useState();
  const imgRef = useRef();

  // setting size of screen for responsiveness
  useEffect(() => {
    const section = document.getElementById("nav-section");
    let rtime;
    let timeout = false;
    const delta = 200;
    const handleResize = () => {
      rtime = new Date();
      if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
      }
    };
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      const wrapperWidth = imgRef?.current?.clientWidth;
      const wrapperHeight = imgRef?.current?.clientHeight;
      setWrapperSize(wrapperHeight > wrapperWidth ? wrapperWidth : wrapperHeight);
    }, 500);
    function resizeend() {
      if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
      } else {
        timeout = false;
        const currentWidth = imgRef?.current?.clientWidth;
        const currentHeight = imgRef?.current?.clientHeight;
        setWrapperSize(currentHeight > currentWidth ? currentWidth : currentHeight);
      }
    }
    section.style.height = "100vh";
    return () => {
      window.removeEventListener("resize", handleResize);
      section.style.height = "auto";
    };
  }, []);

  // setting the traits and the variables on load of page
  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": builderState,
      "action": "setMemeGenerator"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)

        response.data.hasUpgrade ? setBuilderStates(Object.keys(response.data.assetDict)) : setBuilderStates(["Base"])

        // setHasUpgrade(response.data.hasUpgrade)
        setImageArray(response.data.imageArray)

        setAssetDict(response.data.assetDict[builderState])
        setSelectedCategory(Object.keys(response.data.assetDict[builderState])[0])

        setCategoryMappings(response.data.categoryMappings);
        setSecondaryCategories(response.data.secondaryCategories);

        setMetadata(response.data.sampleMetadata);
        setIDMetadata(response.data.sampleIDMetadata)

        setBlockedTraits(response.data.blockedTraits);
        setAllMemes(response.data.allMemes)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function handleChange(event) {
    setTokenID(event.target.value);
  }

  const renderWithMeme = async (meme) => {
    console.log(metadata, meme, imageArray)
    setSelectedMeme(meme)
    var data = JSON.stringify({
      "projectID": props.projectID,
      "metadata": metadata,
      "idMetadata": idMetadata,
      "selectedMeme": meme,
      "builderState": builderState,
      "action": "renderWithMeme"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setImageArray(response.data.imageArray)
        setReadyToMeme(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderWithTokenID = async () => {
    if (tokenID >= 1 && tokenID <= props.projectCollectionSupply){
      var data = JSON.stringify({
        "projectID": props.projectID,
        "tokenID": tokenID,
        "selectedMeme": selectedMeme,
        "builderState": builderState,
        "action": "renderWithTokenID"
      });
      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(response)
          setImageArray(response.data.imageArray)
          setMetadata(response.data.metadata)
          setIDMetadata(response.data.idMetadata)
        })
        .catch(function (error) {
          console.log(error);
        });

    }
    else {
      setPopupState("invalidID")
      setPopup(true)
    }
  };

  const renderRandom = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": builderState,
      "selectedMeme": selectedMeme,
      "action": "randomizeMemeGenerator"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setImageArray(response.data.imageArray)
      setMetadata(response.data.metadata)
      setIDMetadata(response.data.idMetadata)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const handleDownloadAndTweet = async () => {
      setPopupState("creatingImage")
      setPopup(true)
      let identifyingHash = randomHash(50);

      var data = JSON.stringify({
        action: "createMeme",
        imageArray: imageArray,
        identifyingHash: identifyingHash
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(async (response) => {
          let blob = await fetch(response.data.imageLink).then((r) => r.blob());
          await saveAs(blob, props.projectName + '_meme.png');

          let twitterAt = props.twitterHandle.replace('https://twitter.com/', '');
          // console.log(twitterAt)
          const tweetText = `Just memefied my @${twitterAt} NFT! Memeify your ${props.projectTitle} at the @maxinNFT body shop! \n\nCheck it out:`;
          const imageUrl = "https://www.body-shop.xyz/rektgang";
          const mediaId = "1646561367388717064";
          const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(imageUrl)}&media_ids=${encodeURIComponent(mediaId)}`;
          window.open(tweetUrl, "_blank");
          setPopupState('successfulCreation')

        })
        .catch(function (error) {
          console.log(error);
          setPopupState('transactionError')

        });
}

  // popups for pending, error, and successful
  const renderPopup = () => {
    if (popupState === "creatingImage") {
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Downloading Image...</p>
          </div>
        </div>
      );
    }
    else if(popupState === "transactionError"){
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Failed to download. Please Try Again.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "successfulCreation") {
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Image downloaded. Tweet your Creation!</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "invalidID"){
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Invalid Token ID.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
  };

  const resetPopup = (reason) => {
    setPopup(false);
    setPopupState("default");
  };

  return (

      <section className="h-full flex-grow flex-col flex px-10 py-5 bg-[#8C838A] bg-opacity-10">
        {popup ? renderPopup() : <div></div>}
        <div className="flex justify-between w-full">
            <div className="flex flex-col gap-5">

              <p className="text-lg md:text-4xl font-text font-bold text-bold text-dark-gray">MEME GENERATOR</p>

            </div>

          </div>
        <div className="flex my-4">
          <input className="bg-white text-sm md:text-xl font-lekton text-gray p-1" type="number" placeholder='search by token ID' tokenID={tokenID} onChange={e => {handleChange(e)}}/>
          <button className="bg-primary-red rounded-xl rounded-l-none text-white py-3 px-6  text-xl sm:text-2xl font-text uppercase font-bold hover:bg-red-light hover:opacity-50" onClick={() => renderWithTokenID()}>search</button>
        </div>
        <div className="w-full h-full grid grid-cols-2 gap-3 lg:gap-6 ">

          <div
            ref={imgRef}
            className="w-full pb-[100%] overflow-hidden relative     mx-auto  "
          >
            {wrapperSize && imageArray.length ? (
              imageArray
              .filter(link => link !== 'none')
              .map((link, index) => {
                return (
                  <img
                    key={index}
                    // style={{ width: wrapperSize, height: wrapperSize }}
                    className="absolute w-full aspect-square h-auto top-0 left-1/2 -translate-x-1/2 border-gray-300    rounded-lg   object-contain mx-auto"
                    src={link}
                  />
                );
              })
            ) : (
              <div></div>
            )}
          </div>
        <div className="w-full h-full relative row-span-2">
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center text-lg font-text  px-2 xl:px-10  bg-gray/10 row-span-2 overflow-hidden">
          <div className="p-5">
            <p className="text-sm md:text-4xl font-text font-bold text-bold text-dark-gray">SELECT MEME</p>
          </div>
            <div className="h-full w-full mx-auto flex-grow  overflow-auto ">
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 max-h-[77vh] overflow-y-scroll">
                  {
                    allMemes.map((meme) => {
                      const isSelected = selectedMeme?.id === meme.id;
                      return (
                        <div>
                          {isSelected ? (
                            <img
                                src={meme.memeImage}
                                onClick={() => {
                                  renderWithMeme(meme);
                                }}
                                className={`w-full cursor-pointer border border-primary-red p-1 bg-white`}
                                style={{
                                  marginTop: 10,
                                  borderRadius: 10,
                                  borderColor: theme?.color1,
                                }}
                              />
                          ) : (
                            <img
                            src={meme.memeImage}
                            onClick={() => {
                              renderWithMeme(meme);
                            }}
                            className={`w-full cursor-pointer border active:border-primary-red focus:border-primary-red p-1 bg-white`}
                            style={{ marginTop: 10, borderRadius: 10 }}
                          />
                          )}
                          <p className="text-xs md:text-lg font-text text-center font-bold text-bold text-dark-gray">{meme.memeName}</p>
                        </div>
                      )
                    })
                  }
               </div>
            </div>
          </div>
        </div>

          <div className="flex justify-center items-center">

            <button
                style={{ backgroundColor: theme?.color2, color: theme?.color1 }}
                className="bg-dark-gray w-full md:w-[40%] rounded-full text-primary-yellow p-3  text-sm xl:text-2xl font-text uppercase font-bold disabled:bg-opacity-50 hover:bg-opacity-50"
                disabled={!readyToMeme}
                onClick={() => {
                  handleDownloadAndTweet();
                }}
              >
                Download & tweet
            </button>
          </div>
        </div>
      </section>

  );
};

export default MemeGenerator;

// Button to add randomization when the time is right.
// <button
//     style={{ backgroundColor: theme?.color2, color: theme?.color1 }}
//     className="bg-primary-red w-[40%] rounded-full text-white p-3  text-sm xl:text-2xl font-text uppercase font-bold hover:bg-red-light hover:opacity-50"
//     onClick={() => {
//       renderRandom();
//     }}
//   >
//     Randomize
// </button>
