import React from "react";
// import { Link } from "react-router-dom";
import { NavItem } from "./UI/StyledComponents";
import Logo from "../img/initial_image.png";

const SideBarNew = (props) => {
  console.log(props);

  let tabs = [
    { label: "shop", tabName: "traitShop", bg: "bg-primary-red" },
    { label: "customizer", tabName: "customize", bg: "bg-primary-red-tint" },
    { label: "builder", tabName: "builder", bg: "bg-primary-yellow" },
  ];

  if (props.fusions === 1) {
    tabs.push({ label: "FUSIONS", tabName: "fusions", bg: "bg-primary-yellow" });
  }

  if (props.mutations === 1) {
    tabs.push({ label: "UPGRADE ART", tabName: "mutations", bg: "bg-primary-red" });
  }

  if (props.slotMachine === 1) {
    tabs.push({ label: "SLOT MACHINE", tabName: "slotMachine", bg: "bg-primary-red" });
  }

  if (props.projectID === 40) {
    tabs = [
      { label: "TRAIT RE-ROLL", tabName: "slotMachine", bg: "traitShop" },
      { label: "CUSTOMIZER", tabName: "customize", bg: "customize" },
      { label: "BUILDER", tabName: "builder", bg: "builder" },
      { label: "TRAIT SHOP", tabName: "traitShop", bg: "bg-primary-red" },
    ];
  }

  if (props.projectID === 41) {
    tabs = [
      { label: "UPGRADE ART", tabName: "mutations", bg: "traitShop" },
      { label: "BUILDER", tabName: "builder", bg: "customize" },
      { label: "MUTATIONS", tabName: "rrTails", bg: "builder" },
    ];
  }

  if (props.projectID === 44) {
    tabs = [{ label: "EXPLORE TRAITS", tabName: "traitShop", bg: "bg-primary-red" }];
  }
  if (props.projectID === 1 || props.projectID === 14){
    tabs = [{ label: "meme generator", tabName: "memeGenerator", bg: "bg-primary-yellow" }];
  }
  if (props.projectID === 9 || props.projectID === 15){
    tabs = [{ label: "meme generator", tabName: "memeGen2", bg: "bg-primary-yellow" }];
  }

  return (
    <nav className="h-full flex flex-col items-center justify-center">
      <ul className="flex flex-col items-center justify-center h-full flex-grow gap-5">
        {tabs.map((tab) => (
          <NavItem
            onClick={() => {
              props.setPage(tab.tabName);
              props?.closeMenu();
            }}
            key={`${tab.tabName}_${tab.label}`}
            className={`px-6 sm:px-8 text-xs sm:text-base cursor-pointer sm:text-black lowercase font-lekton text-white ${
              tab.tabName === props?.page ? "current" : ""
            }`}
          >
            {tab.label}
          </NavItem>
        ))}
      </ul>
    </nav>
  );
};
export default SideBarNew;
