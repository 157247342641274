import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import * as web3 from "@solana/web3.js";
import axios from "axios";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  getAccount,
  createTransferCheckedInstruction,
  createTransferInstruction,
} from "@solana/spl-token";
import {
  bundlrStorage,
  findMetadataPda,
  Metaplex,
  UploadMetadataInput,
  walletAdapterIdentity,
  keypairIdentity,
  findMasterEditionV2Pda,
} from "@metaplex-foundation/js";
import {
  DataV2,
  createCreateMetadataAccountV2Instruction,
  createVerifyCollectionInstruction,
} from "@metaplex-foundation/mpl-token-metadata";
import * as bs58 from "bs58";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import pointer from "../img/pointer.svg";
import NotAllowed from "../img/not_allowed.png";
import NoImage from "../img/no-image.png";
import Backdrop from "../components/UI/Backdrop";
import { P, SelectNFTBtn } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import DropDown from "../components/UI/DropDown/DropDown";
import styled from "styled-components";
import BurnIcon from "../components/Icons/BurnIcon";
import lock from "../img/lock.png";
import { Checkmark } from 'react-checkmark'
const ScrollableDiv = styled.div`
  background-color: ${({ theme }) => theme?.color2};
  &::-webkit-slider-thumb {
    background: ${({ theme }) => theme?.color1} !important;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }
`;

const Fusions = (props) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction } = useWallet();
  const wallet = useWallet();

  // UI variables
  const theme = useTheme();
  const [wrapperSize, setWrapperSize] = useState();
  const imgRef = useRef();

  // project variables
  const projectHashURL = props.projectHashURL;
  const [projectHashArray, setProjectHashArray] = useState([]);
  const [assetDict, setAssetDict] = useState({});
  const [assetDictTemp, setAssetDictTemp] = useState({});
  const [builderState, setBuilderState] = useState("Base");
  const [upgradeCategory, setUpgradeCategory] = useState();
  const [upgradeNameDict, setUpgradeNameDict] = useState({});
  const [hasUpgrade, setHasUpgrade] = useState(0);
  const [upgradingProjectNFTs, setUpgradingProjectNFTs] = useState({});

  // User variables
  const [walletNFTs, setWalletNFTs] = useState([]);
  const [ownedNFTDict, setOwnedNFTDict] = useState({});
  const [userNFTDict, setUserNFTDict] = useState({ Base: [] });
  const [fusedMetadata, setFusedMetadata] = useState({});
  const [fusedIDMetadata, setFusedIDMetadata] = useState({});
  const [fusionArray, setFusionArray] = useState([]);

  // render variables
  const [popupNFTs, setPopupNFTs] = useState(0);
  const [imageArray, setImageArray] = useState([]);
  const [startImage, setStartImage] = useState();
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [nft1Selected, setNFT1Selected] = useState(0);
  const [nft1, setNFT1] = useState();
  const [nft2Selected, setNFT2Selected] = useState(0);
  const [nft2, setNFT2] = useState();
  const [nft3Selected, setNFT3Selected] = useState(0);
  const [nft3, setNFT3] = useState();
  const [showFusionBuilder, setShowFusionBuilder] = useState(0);
  const [nonArtworkArray, setNonArtworkArray] = useState([]);
  const [sortedData, setSortedData] = useState([])
  const [blockedTraits, setBlockedTraits] = useState([])
  const [mobileNFT, setMobileNFT] = useState(1)

  // Booleans
  const [popup, setPopup] = useState(false);
  const [fetchedFusionData, setFetchedFusionData] = useState(false);
  const [fetchedWalletNFTs, setFetchedWalletNFTs] = useState(false);
  const [filteredProjectNFTs, setFilteredProjectNFTs] = useState(false);
  const [createdOriginalRender, setCreatedOriginalRender] = useState(false);
  const [errorImage, setErrorImage] = useState(false);

  // Transaction variables
  const [popupState, setPopupState] = useState("default");
  const hasPaymentRequirement = props.hasFusionFee;
  const paymentRequirements = props.hasFusionFee
    ? { hash: props.fusionFeeCurrency, price: props.fusionFeePrice }
    : 0;

  const currencyDict = {
    CA6nNPCCKhf4AEEnXBnzcxms4c4gu7scFrA2WRqczHoW: "$OTAKU",
    AxXoJZhSfeVUe3qgFZTt4NwQRJB61pBQAHTdWTN9PNms: "$KAYAC",
    popwcrLzjetHAFCH91LBTK78zapZ54Rftpc7PGoHpuh: "$POP",
    WERKZCY6o4eYu9cSh94s1RYC9rQG1parxuvwoW6FZAa: "$WERK",
    "97yD6vUGLUx4qUi8CpuGesvnrNMag4UxPfyQnYkEK1az": "$PEPE",
    XXX: "SOL",
    SOL: "SOL",
    "5yxNbU8DgYJZNi3mPD9rs4XLh9ckXrhPjJ5VCujUWg5H": "$FRONK",
    CCxnn3XmZARNVNCS31z8zkcuDEQnR6oJQubrvUd1phFX: "$CC",
  };

  // NOT SURE THIS IS NECESSARY
  // TODO: determine whether to delete this or not
  useEffect(() => {
    const section = document.getElementById("nav-section");
    let rtime;
    let timeout = false;
    const delta = 200;
    const handleResize = () => {
      rtime = new Date();
      if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
      }
    };
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      const wrapperWidth = imgRef?.current?.clientWidth;
      const wrapperHeight = imgRef?.current?.clientHeight;
      setWrapperSize(wrapperHeight > wrapperWidth ? wrapperWidth : wrapperHeight);
    }, 500);
    function resizeend() {
      if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
      } else {
        timeout = false;
        const currentWidth = imgRef?.current?.clientWidth;
        const currentHeight = imgRef?.current?.clientHeight;
        setWrapperSize(currentHeight > currentWidth ? currentWidth : currentHeight);
      }
    }
    section.style.height = "100vh";
    return () => {
      window.removeEventListener("resize", handleResize);
      section.style.height = "auto";
    };
  }, []);

  // NOT SURE THIS IS NECESSARY
  // TODO: determine whether to delete this or not
  useEffect(() => {
    const section = document.getElementById("nav-section");
    section.style.height = "100vh";
  }, []);

  // Get all the NFTs from the wallet and store it to iterate through later
  // Get all the fusion data and assetDict from DB
  useEffect(() => {
    if (publicKey && !fetchedWalletNFTs){
      const getNFTs = async () => {
        let myNfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        let walletDictTemp = {}
        myNfts.forEach((nft) => {
          walletDictTemp[nft.mint] = nft.data.uri
        });
        setFetchedWalletNFTs(true)
        setWalletNFTs(myNfts)
      };
      getNFTs();
    }

    if (publicKey && !fetchedFusionData){
      var data = JSON.stringify({
        "projectID": props.projectID,
        "builderState": builderState,
        "action": "getFusions2"
      });

      var config = {
        method: 'post',
        url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(response)
          if (response.data.hasUpgrade){
            setHasUpgrade(1)
            setUpgradeCategory(response.data.upgradeCategory)
            setUpgradeNameDict(response.data.upgradeNameDict)
            let ownedDictTemp = {}
            Object.keys(response.data.assetDict).forEach(upgradeName => {
              ownedDictTemp[upgradeName] = {}
            })
            setOwnedNFTDict(ownedDictTemp)
          }
          else{
            setOwnedNFTDict({Base: {}})
          }
          setAssetDict(response.data.assetDict)
          setUpgradingProjectNFTs(response.data.upgradingNFTDict)
          setFetchedFusionData(true)
          setBlockedTraits(response.data.blockedTraits)
        })
        .catch(function (error) {
        });
    }
  }, [publicKey, fetchedWalletNFTs, fetchedFusionData]);

  // Set the hashlist for the project
  useEffect(() => {
    if (projectHashURL && !projectHashArray.length) {
      var config = {
        method: "get",
        url: projectHashURL,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          setProjectHashArray(response.data);
        })
        .catch(function (error) {
          // // console.log(error);
        });
    }
  }, [projectHashURL]);

  // Set the owned NFT dict
  useEffect(() => {
    if (walletNFTs.length && projectHashArray.length && fetchedFusionData && !filteredProjectNFTs){
      const filterUserNFTs = async () => {
        let ownedNFTDictTemp = {...ownedNFTDict}
        await walletNFTs.map(async (nft) => {
          if (projectHashArray.includes(nft.mint)) {

            var configTemp = {
              method: "get",
              url: nft.data.uri + '?nocache='  + (new Date()).getTime(),
              headers: {
                "Content-Type": "application/json"
              }
            };

            let nftTemp = await axios(configTemp).then(results => {
              return (results.data)
            })

            if (hasUpgrade) {
              let upgradeName = 'Base'
              nftTemp.attributes.forEach(attribute => {
                if (attribute.trait_type === upgradeCategory){
                  if (Object.keys(upgradeNameDict).includes(attribute.value)){
                    upgradeName = upgradeNameDict[attribute.value]
                  }
                }
              })

              var data = JSON.stringify({
                "action": "checkIfMutating",
                "projectID": props.projectID,
                "nftHash": nft.mint,
                "upgradeNameDict": upgradeNameDict,
                "upgradeCategory": upgradeCategory
              });

              var config = {
                method: 'post',
                url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade',
                headers: {
                  'x-api-key': process.env.GATEWAY_KEY,
                  'Content-Type': 'application/json'
                },
                data: data
              };

              axios(config)
                .then(function (response) {
                  if (response.data.upgradeName){
                    upgradeName = response.data.upgradeName
                  }
                  ownedNFTDictTemp[upgradeName][nft.mint] = {...nftTemp, metadataLink: nft.data.uri}
              })
            }
            else{
              ownedNFTDictTemp["Base"][nft.mint] = {...nftTemp, metadataLink: nft.data.uri}
            }
          }
        })
        setOwnedNFTDict(ownedNFTDictTemp)
        setUserNFTDict(ownedNFTDictTemp)
        setFilteredProjectNFTs(true)
      };
      filterUserNFTs();
    }
  }, [walletNFTs, projectHashArray, filteredProjectNFTs, fetchedFusionData])

  useEffect(() => {
    if (Object.keys(fusedMetadata).length && Object.keys(fusedMetadata).length && showFusionBuilder && !createdOriginalRender){
      renderImage(0)
      setCreatedOriginalRender(true)
    }
  }, [fusedMetadata, fusedIDMetadata, showFusionBuilder, createdOriginalRender])

  const renderTraits = () => {
    const traitArray = [];
    const blockedTraitArray = [];

    blockedTraits.map((blockedTrait) => {
      if (fusedIDMetadata[blockedTrait.traitCategory] === blockedTrait.primaryTraitID) {
        blockedTrait.blockedTraits.map((tempBlockedTraits) => {
          blockedTraitArray.push(tempBlockedTraits.id);
        });
      }
      blockedTrait.blockedTraits.map((tempBlockedTraits) => {
        // console.log(tempBlockedTraits)
        if (fusedIDMetadata[tempBlockedTraits.category] === tempBlockedTraits.id){
          blockedTraitArray.push(blockedTrait.primaryTraitID)
        }
      })
    });

    if (nonArtworkArray.includes(selectedCategory)) {
      assetDictTemp[selectedCategory].forEach((attributeName) => {
        traitArray.push(
          <div>
            <li
              onClick={() => renderImage(attributeName)}
              className="font-bold text-white cursor-pointer hover:text-red-light"
            >
              {attributeName}
            </li>
          </div>
        );
      });
    } else {
      assetDictTemp[selectedCategory].forEach((trait) => {
        if (blockedTraitArray.includes(trait.id)) {
          // console.log(trait);
          traitArray.push(
            <div className="flex items-start">
              <li className="w-full h-full relative rounded-lg pointer-events-none overflow-hidden border-2 border-primary-red grayscale">
                <img className="w-full h-full object-cover" src={trait.image} alt={trait.traitName} />
                <img className="absolute inset-0 m-auto w-1/3" src={lock} alt="Locked" />
              </li>
            </div>
          );
        }
        else {
          if (trait.id === fusedIDMetadata[selectedCategory]) {
            traitArray.push(
              <li
                // onClick={() => renderImage(trait)}
                className="w-full h-full relative rounded-lg pointer-events-none overflow-hidden border-2 border-primary-red"
              >
                <img className="w-full h-full object-cover" src={trait.image} alt={trait.traitName} />
              </li>
            );
          } else {
            traitArray.push(
              <li
                onClick={() => renderImage(trait)}
                className="w-full h-full relative rounded-lg cursor-pointer overflow-hidden border-2 border-dark-gray"
              >
                <img className="w-full h-full object-cover" src={trait.image} alt={trait.traitName} />
              </li>
            );
          }
        }
      });
    }
    return traitArray;
  };

  const renderImage = async (trait) => {
    let newMetadata = {...fusedMetadata}
    let newIDMetadata = {...fusedIDMetadata}

    if (trait){
      if (nonArtworkArray.includes(selectedCategory)){
        newMetadata[selectedCategory] = trait
        console.log(newMetadata)
      }
      else{
        newMetadata[selectedCategory] = trait.traitName
        newIDMetadata[selectedCategory] = trait.id
      }
    }
    console.log(newMetadata,newIDMetadata)
    var data = JSON.stringify({
      "projectID": props.projectID,
      "metadata": newMetadata,
      "idMetadata": newIDMetadata,
      "builderState": builderState,
      "action": "renderImage2",
      "fusionArray": fusionArray
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        setFusedMetadata(newMetadata)
        setFusedIDMetadata(newIDMetadata)
        if (response.data.error){
          setErrorImage(response.data.error)
        }
        else{
          setImageArray(response.data.imageArray)
          setSortedData(response.data.finalSortedData)
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const selectNFT = (nftHash, nft, nftNumber) => {
    if (nftNumber === 1){
      setNFT1({...nft, mint: nftHash})
      setNFT1Selected(1)
    }
    else if (nftNumber === 2){
      setNFT2({...nft, mint: nftHash})
      setNFT2Selected(1)
    }
    else if (nftNumber === 3){
      setNFT3({...nft, mint: nftHash})
      setNFT3Selected(1)
    }
    setPopupNFTs(0)
  };

  const deselectNFT = (nftHash, nft, nftNumber) => {
    setSelectedNFTs((current) =>
      current.filter((id) => id !== nft));
    if (nftNumber === 1){
      setNFT1()
      setNFT1Selected(0)
    }
    else if (nftNumber === 2){
      setNFT2()
      setNFT2Selected(0)
    }
    else if (nftNumber === 3){
      setNFT3()
      setNFT3Selected()
    }
    setPopupNFTs(nftNumber)
  }

  const reload = () => {
    setPopup(false);
    setPopupState("default");

    setNFT1Selected(0)
    setNFT1()
    setNFT2Selected(0)
    setNFT2()
    setNFT3Selected(0)
    setNFT3()

    setFetchedWalletNFTs(false)
    setFetchedFusionData(false)
    setFilteredProjectNFTs(false)
    setCreatedOriginalRender(false)
    setShowFusionBuilder(false)
  }

  const switchBuilderState = (newState) => {
    setNFT1Selected(0)
    setNFT1()
    setNFT2Selected(0)
    setNFT2()
    setNFT3Selected(0)
    setNFT3()
    setBuilderState(newState)
  }

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const fuseNFTs = useCallback(async () => {

    let metadataFinal = {...fusedMetadata}
    let metadataIDFinal = {...fusedIDMetadata}

    if (props.projectID === 43){
      fusedMetadata['Alpha'] = 'Yes'
    }

    Object.keys(metadataIDFinal).forEach(category => {
      if (metadataIDFinal[category] === -1){
        delete metadataFinal[category]
        delete metadataIDFinal[category]
      }
    })

    setPopup(true);
    let identifyingHash = randomHash(50);
    if (!publicKey) throw new WalletNotConnectedError();
    const creatorDestination = await new web3.PublicKey(props.projectCreator);
    const feeWallet = await new web3.PublicKey(
      "DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix"
    );

    const nftPublicKey = await new web3.PublicKey(fusionArray[1].mint);
    const destinationWalletNFTAccount = await getAssociatedTokenAddress(
      nftPublicKey,
      creatorDestination
    );

    let nft_account;

    try {
      nft_account = await getAccount(connection, destinationWalletNFTAccount);
    } catch (error) {
      try {
        const transaction = new web3.Transaction().add(
          createAssociatedTokenAccountInstruction(
            publicKey,
            destinationWalletNFTAccount,
            creatorDestination,
            nftPublicKey,
            TOKEN_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        );
        const signature = await sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, "processed");
      } catch (error) {
        setPopup(true)
        setPopupState("transactionError");
        throw error;
      }
    }

    const sourceWalletNFTAccount = await getAssociatedTokenAddress(
      nftPublicKey,
      publicKey
    );

    var transaction = new web3.Transaction().add(
      createTransferCheckedInstruction(
        sourceWalletNFTAccount,
        nftPublicKey,
        destinationWalletNFTAccount,
        publicKey,
        1,
        0
      )
    );

    if (fusionArray.length > 2){
      const nftPublicKeyTwo = await new web3.PublicKey(fusionArray[2].mint);
      const destinationWalletNFTAccountTwo = await getAssociatedTokenAddress(
        nftPublicKeyTwo,
        creatorDestination
      );

      let nft_account_two;

      try {
        nft_account_two = await getAccount(connection, destinationWalletNFTAccountTwo);
      } catch (error) {
        try {
          const transaction = new web3.Transaction().add(
            createAssociatedTokenAccountInstruction(
              publicKey,
              destinationWalletNFTAccountTwo,
              creatorDestination,
              nftPublicKeyTwo,
              TOKEN_PROGRAM_ID,
              ASSOCIATED_TOKEN_PROGRAM_ID
            )
          );
          const signature = await sendTransaction(transaction, connection);
          await connection.confirmTransaction(signature, "processed");
        } catch (error) {
          setPopup(true)
          setPopupState("transactionError");
          throw error;
        }
      }

      const sourceWalletNFTAccountTwo = await getAssociatedTokenAddress(
        nftPublicKeyTwo,
        publicKey
      );

      transaction.add(
        createTransferCheckedInstruction(
          sourceWalletNFTAccountTwo,
          nftPublicKeyTwo,
          destinationWalletNFTAccountTwo,
          publicKey,
          1,
          0
        )
      )

    }

    const latestBlockhash = await connection.getLatestBlockhash();

    if (props.hasReferral){
      const referralWallet = await new web3.PublicKey(props.referralWallet);
      let maxinPercentage = (100 - props.referralPercentage) / 100
      let referralPercentage = props.referralPercentage / 100
      transaction.add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: feeWallet,
          lamports: web3.LAMPORTS_PER_SOL * (0.03*maxinPercentage),
        })
      );
      transaction.add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: referralWallet,
          lamports: web3.LAMPORTS_PER_SOL * (0.03*referralPercentage),
        })
      );
    }
    else{
      transaction.add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: feeWallet,
          lamports: web3.LAMPORTS_PER_SOL * 0.03,
        })
      );
    }
    // console.log(paymentRequirements)
    if (hasPaymentRequirement > 0){
      if (paymentRequirements.hash === 'SOL'){
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: creatorDestination,
            lamports: web3.LAMPORTS_PER_SOL * paymentRequirements.price,
          })
        )
      }
      else {
        const tokenPublicKey = await new web3.PublicKey(paymentRequirements.hash);

        const destinationWalletCoinAccount = await getAssociatedTokenAddress(
          tokenPublicKey,
          creatorDestination
        );

        let coin_account;

        try {
          coin_account = await getAccount(connection, destinationWalletCoinAccount);

        } catch (error) {

          try {
            const coinTransaction = new web3.Transaction().add(
              createAssociatedTokenAccountInstruction(
                publicKey,
                destinationWalletCoinAccount,
                creatorDestination,
                tokenPublicKey,
                TOKEN_PROGRAM_ID,
                ASSOCIATED_TOKEN_PROGRAM_ID
              )
            );

            const signature = await sendTransaction(coinTransaction, connection);

            await connection.confirmTransaction(signature, "processed");
          } catch (error) {
            // setSentStatus("unsuccessful");
            throw error;
          }
        }

        const sourceWalletCoinAccount = await getAssociatedTokenAddress(
          tokenPublicKey,
          publicKey
        );

        let tokenInfo = await connection.getTokenSupply(tokenPublicKey)
        let decimal = tokenInfo.value.decimals
        let finalDecimal = 10 ** decimal

        if (finalDecimal === 1){
          transaction.add(
            createTransferCheckedInstruction(
              sourceWalletCoinAccount,
              tokenPublicKey,
              destinationWalletCoinAccount,
              publicKey,
              paymentRequirements.price,
              0
            )
          );
        }
        else{
          transaction.add(
            createTransferInstruction(
              sourceWalletCoinAccount,
              destinationWalletCoinAccount,
              publicKey,
              paymentRequirements.price * finalDecimal,
              [],
              TOKEN_PROGRAM_ID
            )
          );
        }

      }
    }

    var data = JSON.stringify({
      action: "transactionTemp",
      nftHash: fusionArray[0].mint,
      metadata: props.projectID === 31 ? metadataFinal : fusedMetadata,
      attributeIDDict: props.projectID === 31 ? metadataIDFinal : fusedIDMetadata,
      projectID: props.projectID,
      metadataLink: "N/A",
      identifyingHash: identifyingHash,
      traitHash: "none",
      imageArray: imageArray,
      upgradeType: "fusion",
      secondaryHash: fusionArray,
      collectionName: builderState,
      sortedData: sortedData
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
    .then(async (response) => {
      try {
          const signature = await sendTransaction(transaction, connection);
          await confirmFusion(identifyingHash, signature)
          const latestBlockhash = await connection.getLatestBlockhash();
          connection
            .confirmTransaction({
              blockhash: latestBlockhash.blockhash,
              lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
              signature: signature,
            })
            .then((sentData) => {
              setPopup(true);
              setPopupState('successfulPurchase')
            });
      } catch (error) {
          // // // console.log(error);
          setPopup(true)
          setPopupState("transactionError");
          throw error;
        }
    })
    .catch(function (error) {
        setPopup(true)
        setPopupState("transactionError");
        // // // console.log(error);
      });

  }, [publicKey, connection, selectedNFTs, fusionArray, fusedMetadata, fusedIDMetadata, paymentRequirements, sortedData])

  const fuseNFTsNew = useCallback(async () => {
    let metadataFinal = {...fusedMetadata}
    let metadataIDFinal = {...fusedIDMetadata}
    let clientKey
    if (props.projectID === 43){
      fusedMetadata['Alpha'] = 'Yes'
      clientKey = web3.Keypair.fromSecretKey(bs58.decode(process.env.CAT_WALLET));
    }
    Object.keys(metadataIDFinal).forEach(category => {
      if (metadataIDFinal[category] === -1){
        delete metadataFinal[category]
        delete metadataIDFinal[category]
      }
    })

    setPopup(true);
    let identifyingHash = randomHash(50);
    if (!publicKey) throw new WalletNotConnectedError();
    let triggerPause = false
    const creatorDestination = await new web3.PublicKey(props.projectCreator);
    const feeWallet = await new web3.PublicKey(
      "DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix"
    );
    const quicknodeURL = process.env.QUICKNODE;
    const solanaConnection = await new web3.Connection(quicknodeURL);
    const walletKey = web3.Keypair.fromSecretKey(bs58.decode(process.env.WALLET));
    let metaplex = Metaplex.make(solanaConnection).use(keypairIdentity(clientKey))

    const latestBlockhash = await connection.getLatestBlockhash();

    if (props.hasReferral){
      const referralWallet = await new web3.PublicKey(props.referralWallet);
      let maxinPercentage = (100 - props.referralPercentage) / 100
      let referralPercentage = props.referralPercentage / 100
      var transaction = new web3.Transaction().add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: feeWallet,
          lamports: web3.LAMPORTS_PER_SOL * (0.03*maxinPercentage),
        })
      );
      transaction.add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: referralWallet,
          lamports: web3.LAMPORTS_PER_SOL * (0.03*referralPercentage),
        })
      );
    }
    else{
      var transaction = new web3.Transaction().add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: feeWallet,
          lamports: web3.LAMPORTS_PER_SOL * 0.03,
        })
      );
    }

    // if (hasPaymentRequirement > 0){
    //   if (paymentRequirements.hash === 'SOL'){
    //     transaction.add(
    //       web3.SystemProgram.transfer({
    //         fromPubkey: publicKey,
    //         toPubkey: creatorDestination,
    //         lamports: web3.LAMPORTS_PER_SOL * paymentRequirements.price,
    //       })
    //     )
    //   }
    //   else {
    //     const tokenPublicKey = await new web3.PublicKey(paymentRequirements.hash);
    //
    //     const destinationWalletCoinAccount = await getAssociatedTokenAddress(
    //       tokenPublicKey,
    //       creatorDestination
    //     );
    //
    //     let coin_account;
    //
    //     try {
    //       coin_account = await getAccount(connection, destinationWalletCoinAccount);
    //
    //     } catch (error) {
    //
    //       try {
    //         const coinTransaction = new web3.Transaction().add(
    //           createAssociatedTokenAccountInstruction(
    //             publicKey,
    //             destinationWalletCoinAccount,
    //             creatorDestination,
    //             tokenPublicKey,
    //             TOKEN_PROGRAM_ID,
    //             ASSOCIATED_TOKEN_PROGRAM_ID
    //           )
    //         );
    //
    //         const signature = await sendTransaction(coinTransaction, connection);
    //
    //         await connection.confirmTransaction(signature, "processed");
    //       } catch (error) {
    //         // setSentStatus("unsuccessful");
    //         throw error;
    //       }
    //     }
    //
    //     const sourceWalletCoinAccount = await getAssociatedTokenAddress(
    //       tokenPublicKey,
    //       publicKey
    //     );
    //
    //     let tokenInfo = await connection.getTokenSupply(tokenPublicKey)
    //     let decimal = tokenInfo.value.decimals
    //     let finalDecimal = 10 ** decimal
    //     // paymentRequirements.price
    //     if (finalDecimal === 1){
    //       transaction.add(
    //         createTransferCheckedInstruction(
    //           sourceWalletCoinAccount,
    //           tokenPublicKey,
    //           destinationWalletCoinAccount,
    //           publicKey,
    //           1,
    //           0
    //         )
    //       );
    //     }
    //     else{
    //       transaction.add(
    //         createTransferInstruction(
    //           sourceWalletCoinAccount,
    //           destinationWalletCoinAccount,
    //           publicKey,
    //           1 * finalDecimal,
    //           [],
    //           TOKEN_PROGRAM_ID
    //         )
    //       );
    //     }
    //
    //   }
    // }

    var data = JSON.stringify({
      action: "setUpgrade",
      nftHash: fusionArray[0].mint,
      metadata: fusedMetadata,
      attributeIDDict: fusedIDMetadata,
      projectID: props.projectID,
      metadataLinkOld: fusionArray[0].metadataLink,
      metadataLink: fusionArray[0].metadataLink,
      identifyingHash: identifyingHash,
      traitHash: "none",
      imageArray: imageArray,
      upgradeType: "fusion",
      secondaryHash: fusionArray,
      collectionName: builderState
    });
    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/generateimage",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
    .then(async (response) => {
      try {
        if (triggerPause){
          setTimeout(async () => {
            const mintAddress = new web3.PublicKey(fusionArray[0].mint)
            console.log(mintAddress)
            const nft = await metaplex.nfts().findByMint({ mintAddress });

            let updateNftBuilder = await metaplex.nfts().builders().update({
              nftOrSft: nft,
              name: nft.name,
              uri: response.data.newJSON,
              authorizationDetails: {
                rules: new web3.PublicKey("eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9")
              }
            });

            transaction.add(...updateNftBuilder.getInstructions())

            try {
              let latestBlockhash = await solanaConnection.getLatestBlockhash();
              transaction.recentBlockhash = latestBlockhash.blockhash
              transaction.feePayer = publicKey;

              let sendSigned = await signTransaction(transaction);
              sendSigned.partialSign(clientKey);

              const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize())
              const latestBlockhashNew = await solanaConnection.getLatestBlockhash();
              connection.confirmTransaction({
                  blockhash: latestBlockhash.blockhash,
                  lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                  signature: signature,
                }).then(() => {
                  var data = JSON.stringify({
                    action: "finalizeUpdate",
                    nftHash: fusionArray[0].mint,
                    metadata: fusedMetadata,
                    attributeIDDict: fusedIDMetadata,
                    projectID: props.projectID,
                    metadataLinkOld: fusionArray[0].metadataLink,
                    metadataLink: response.data.newJSON,
                    identifyingHash: identifyingHash,
                    traitHash: 'none',
                    imageArray: imageArray,
                    upgradeType: "fusion",
                    secondaryHash: fusionArray,
                    collectionName: builderState,
                    userWallet: publicKey.toBase58(),
                    transactionID: signature
                  });

                  var config = {
                    method: "post",
                    url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/generateimage",
                    headers: {
                      "x-api-key": process.env.GATEWAY_KEY,
                      "Content-Type": "application/json",
                    },
                    data: data,
                  };
                  axios(config)
                    .then(async (response) => {
                      // console.log(response)
                      await confirmFusion(identifyingHash, signature);
                      setPopup(true);
                      setPopupState("successfulPurchase");
                    })
                    .catch(function (error) {
                      setPopup(true);
                      setPopupState("transactionError");
                      // // console.log(error);
                    })
                }).catch(function (error) {
                  setPopup(true);
                  setPopupState("transactionError");
                })
            } catch {
              setPopup(true);
              setPopupState("transactionError");
            }
          }, 20000)
        } else {
          let mintAddress = new web3.PublicKey(fusionArray[0].mint)
          const nftOne = await metaplex.nfts().findByMint({ mintAddress });

          let updateNftBuilder = await metaplex.nfts().builders().update({
            nftOrSft: nftOne,
            name: nftOne.name,
            uri: response.data.newJSON,
            authorizationDetails: {
              rules: new web3.PublicKey("eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9")
            }
          });
          transaction.add(...updateNftBuilder.getInstructions())

          metaplex = Metaplex.make(solanaConnection).use(walletAdapterIdentity(wallet))
          mintAddress = new web3.PublicKey(fusionArray[1].mint)
          const nftTwo = await metaplex.nfts().findByMint({ mintAddress });

          let sendNftBuilder = await metaplex.nfts().builders().transfer({
              nftOrSft: nftTwo,
              fromOwner: publicKey,
              toOwner: creatorDestination,
              authorizationDetails: {
                rules: new web3.PublicKey("eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9")
              }
          });
          transaction.add(...sendNftBuilder.getInstructions())

          // if (fusionArray.length > 2){
          //   mintAddress = new web3.PublicKey(fusionArray[2].mint)
          //   const nftThree = await metaplex.nfts().findByMint({ mintAddress });
          //
          //   let sendNftBuilderTwo = await metaplex.nfts().builders().transfer({
          //       nftOrSft: nftThree,
          //       fromOwner: publicKey,
          //       toOwner: creatorDestination,
          //       authorizationDetails: {
          //         rules: new web3.PublicKey("eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9")
          //       }
          //   });
          //   transaction.add(...sendNftBuilderTwo.getInstructions())
          // }

          try {
            let latestBlockhash = await solanaConnection.getLatestBlockhash();
            transaction.recentBlockhash = latestBlockhash.blockhash
            transaction.feePayer = publicKey;

            let sendSigned = await signTransaction(transaction);
            sendSigned.partialSign(clientKey);
            const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize())
            const latestBlockhashNew = await solanaConnection.getLatestBlockhash();
            connection.confirmTransaction({
                blockhash: latestBlockhash.blockhash,
                lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                signature: signature,
              }).then(() => {
                var data = JSON.stringify({
                  action: "finalizeUpdate",
                  nftHash: fusionArray[0].mint,
                  metadata: fusedMetadata,
                  attributeIDDict: fusedIDMetadata,
                  projectID: props.projectID,
                  metadataLinkOld: fusionArray[0].metadataLink,
                  metadataLink: response.data.newJSON,
                  identifyingHash: identifyingHash,
                  traitHash: 'none',
                  imageArray: imageArray,
                  upgradeType: "fusion",
                  secondaryHash: fusionArray,
                  collectionName: builderState,
                  userWallet: publicKey.toBase58(),
                  transactionID: signature
                });

                var config = {
                  method: "post",
                  url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/generateimage",
                  headers: {
                    "x-api-key": process.env.GATEWAY_KEY,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };
                axios(config)
                  .then(async (response) => {
                    console.log(response)
                    await confirmFusion(identifyingHash, signature);
                    setPopup(true);
                    setPopupState("successfulPurchase");
                  })
                  .catch(function (error) {
                    setPopup(true);
                    setPopupState("transactionError");
                    // // console.log(error);
                  })
              }).catch(function (error) {
                setPopup(true);
                setPopupState("transactionError");
              })
          } catch (error) {
            console.log(error)
            setPopup(true);
            setPopupState("transactionError");
          }
        }
      } catch (error) {
          console.log(error);
          setPopup(true)
          setPopupState("transactionError");
          throw error;
        }
    })
    .catch(function (error) {
        setPopup(true)
        setPopupState("transactionError");
        // // // console.log(error);
      });

  }, [publicKey, connection, selectedNFTs, fusionArray, fusedMetadata, fusedIDMetadata, paymentRequirements])

  const confirmFusion = async (identifyingHash, signature) => {
    var data = JSON.stringify({
      action: "confirmFusion",
      projectID: props.projectID,
      nftArray: fusionArray,
      identifyingHash: identifyingHash,
      purchasingWallet: publicKey.toBase58(),
      transactionID: signature
    });
    console.log(data)

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        return true;
      })
      .catch(function (error) {
        // // console.log(error);
        return false;
      });

    return returnResponse;
  };

  const renderPopup = () => {
    if (popupState === "default") {
        return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Upgrade in progress - please follow the prompt on your wallet.</p>
          </div>
        </div>
      );
    } else if (popupState === "transactionError") {
        const timer = setTimeout(() => {
          resetPopup();
        }, 5000);

        return (
          <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
            <>
              <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
                <img
                  className="h-5 w-5 mr-4"
                  src={Failed}
                  alt="Loading"
                />
                <p className="text-white font-lekton mr-2">Transaction Failed. Please Try Again.</p>
                <button
                  onClick={() => {
                    resetPopup();
                  }}
                  className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
                >
                  &#10761;
                </button>
              </div>
              <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
                <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
              </div>
            </>
          </div>
        );
    } else if (popupState === "successfulPurchase") {
        const timer = setTimeout(() => {
          reload();
        }, 5000);

        return (
          <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
            <>
              <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
                <Checkmark size='24px'/>
                <p className="text-white font-lekton ml-2 mr-2">Congrats! Your upgrade was successful.</p>
                <button
                  onClick={() => {
                    reload();
                  }}
                  className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
                >
                  &#10761;
                </button>
              </div>
              <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
                <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
              </div>
            </>
          </div>
        );
    }
  };

  const resetPopup = async () => {
    setPopup(false);
    setPopupState("default");
  };

  const setNFTs = (nft) => {
    console.log('got here')
    if (nft === 1) {
      setNFT1();
      setNFT1Selected(0);
    } else if (nft === 2) {
      setNFT2();
      setNFT2Selected(0);
    } else if (nft === 3) {
      setNFT3();
      setNFT3Selected(0);
    }
    setPopupNFTs(nft)
  };

  const renderNFTsNew = () => (
    <div className="mt-4 flex-grow overflow-y-auto">
      <div className="flex items-center justify-between">
        <p className="font-bold text-dark-gray text-lg xl:text-3xl font-text  relative">
          MY NFTs
        </p>
        <button
          className="font-title-regular  top-8 z-10 text-lg text-dark-gray uppercase"
          onClick={() => setPopupNFTs(false)}
        >
          &#9664; Back
        </button>
      </div>
      {Object.keys(userNFTDict[builderState]).length > 0 ? (
        <div className="w-full grid grid-cols-2 xl:grid-cols-3 gap-5">
          {Object.keys(userNFTDict[builderState]).map((nftHash) =>
            nftHash !== nft1?.mint && nftHash !== nft2?.mint && nftHash !== nft3?.mint ? (
              Object.keys(upgradingProjectNFTs).includes(nftHash) ? (
                upgradingProjectNFTs[nftHash].image ? (
                  <div class="">
                    <img
                      onClick={() =>
                        selectNFT(nftHash, userNFTDict[builderState][nftHash], popupNFTs)
                      }
                      src={`${upgradingProjectNFTs[nftHash].image}?${new Date().getTime()}`}
                      className="w-full cursor-pointer border-2 border-dark-gray"
                      style={{ marginTop: 10, borderRadius: 10 }}
                    />
                    <p className="text-center py-2 text-gray-deep  font-text font-bold text-[12px] xl:text-2xl">
                      {userNFTDict[builderState][nftHash].name}
                    </p>
                  </div>
                ) : (
                  <div class="">
                    <div className="w-full h-[86.5%] bordercursor-pointer border-2 border-dark-gray flex justify-center items-center" style={{ marginTop: 10, borderRadius: 10 }}>
                      <img
                        src={Loader}
                        alt="loading..."
                        className="w-1/3"
                      />
                    </div>
                    <p className="text-center py-2 text-gray-deep  font-text font-bold text-[12px] xl:text-2xl">
                      {userNFTDict[builderState][nftHash].name}
                    </p>
                  </div>
                )
              ) : (
                <div class="">
                  <img
                    src={userNFTDict[builderState][nftHash].image}
                    className="w-full cursor-pointer border-2 border-dark-gray"
                    style={{ marginTop: 10, borderRadius: 10 }}
                    onClick={() =>
                      selectNFT(nftHash, userNFTDict[builderState][nftHash], popupNFTs)
                    }
                  />
                  <p className="text-center py-2 text-gray-deep  font-text font-bold text-[12px] xl:text-2xl">
                    {userNFTDict[builderState][nftHash].name}
                  </p>
                </div>
              )
            ) : (
              Object.keys(upgradingProjectNFTs).includes(nftHash) ?
              <div class="">
                <img
                  src={`${upgradingProjectNFTs[nftHash].image}?${new Date().getTime()}`}
                  className="w-full cursor-pointer border-2 border-dark-gray opacity-50"
                  style={{ marginTop: 10, borderRadius: 10 }}
                />
                <p className="text-center py-2 text-gray-deep uppercase font-text text-lg xl:text-xl 2xl:text-2xl">
                  {userNFTDict[builderState][nftHash].name}
                </p>
              </div>
              :
              <div class="">
                <img
                  src={userNFTDict[builderState][nftHash].image}
                  className="w-full cursor-pointer border-2 border-dark-gray opacity-50"
                  style={{ marginTop: 10, borderRadius: 10 }}
                />
                <p className="text-center py-2 text-gray-deep uppercase font-text text-lg xl:text-xl 2xl:text-2xl">
                  {userNFTDict[builderState][nftHash].name}
                </p>
              </div>
            )
          )}
        </div>
      ) : (
          <P className="font-title-bold text-primary-red text-[24px] text-center mb-5 relative">
            There is no NFT in your wallet from this particular collection. <br></br>Please connect
            a new wallet and try again or switch the collection.
          </P>
        )}
    </div>
  );

  const startFusions = () => {
    let nftArray = [];
    if (nft1Selected) {
      nftArray.push(nft1);
    }
    if (nft2Selected) {
      nftArray.push(nft2);
    }
    if (nft3Selected) {
      nftArray.push(nft3);
    }

    setStartImage(nftArray[0]);
    setFusionArray(nftArray);

    let initialMetadata = {};
    let fusedIDMetadataTemp = {};

    let assetDictTemporary = {};
    let assetDictIDs = [];

    Object.keys(assetDict[builderState]).forEach((category) => {
      initialMetadata[category] = "None";
      fusedIDMetadataTemp[category] = -1;
    });

    let nonArtworkArrayTemp = [];

    nftArray.forEach((nft, index) => {
      let artworkArray = [];

      // Generating the original metadata for the base NFT
      if (index === 0) {
        if (!Object.keys(upgradingProjectNFTs).includes(nft.mint)) {
          nft.attributes.forEach((trait) => {
            // attribute is an art asset
            if (Object.keys(assetDict[builderState]).includes(trait.trait_type)) {
              artworkArray.push(trait.trait_type);
              if (trait.value === "Colonel's Cap" && props.projectID === 43) {
                trait.value = "Colonels Cap";
              }
              initialMetadata[trait.trait_type] = trait.value;
              // match the attribute on the asset ID and set the metadataIDDict
              assetDict[builderState][trait.trait_type].forEach((thing) => {
                if (thing.traitName === trait.value) {
                  fusedIDMetadataTemp[trait.trait_type] = thing.id;
                  // adding the values to the trait choice dict
                  assetDictTemporary[trait.trait_type] = [thing];
                  assetDictIDs.push(thing.id);
                }
              });
            }
            // attribute is not an art asset
            else {
              nonArtworkArrayTemp.push(trait.trait_type);
              // add the category and trait to the metadata and to the trait choice dict
              assetDictTemporary[trait.trait_type] = [trait.value];
              initialMetadata[trait.trait_type] = trait.value;
              // if (!Object.keys(assetDictTemporary).includes(trait.trait_type)){
              //   assetDictTemporary[trait.trait_type] = [trait.value]
              //   initialMetadata[trait.trait_type] = trait.value
              // }
            }
          });
        } else {
          console.log(upgradingProjectNFTs[nft.mint]);
          Object.keys(upgradingProjectNFTs[nft.mint].attributeDict).forEach((category) => {
            if (Object.keys(assetDict[builderState]).includes(category)) {
              artworkArray.push(category);
              if (
                upgradingProjectNFTs[nft.mint].attributeDict[category] === "Colonel's Cap" &&
                props.projectID === 43
              ) {
                upgradingProjectNFTs[nft.mint].attributeDict[category] = "Colonels Cap";
              }
              initialMetadata[category] = upgradingProjectNFTs[nft.mint].attributeDict[category];
              // match the attribute on the asset ID and set the metadataIDDict
              assetDict[builderState][category].forEach((thing) => {
                if (thing.traitName === upgradingProjectNFTs[nft.mint].attributeDict[category]) {
                  fusedIDMetadataTemp[category] = thing.id;
                  // adding the values to the trait choice dict
                  assetDictTemporary[category] = [thing];
                  assetDictIDs.push(thing.id);
                }
              });
            }
            // attribute is not an art asset
            else {
              nonArtworkArrayTemp.push(category);
              // add the category and trait to the metadata and to the trait choice dict
              assetDictTemporary[category] = [
                upgradingProjectNFTs[nft.mint].attributeDict[category],
              ];
              initialMetadata[category] = upgradingProjectNFTs[nft.mint].attributeDict[category];
              // if (!Object.keys(assetDictTemporary).includes(trait.trait_type)){
              //   assetDictTemporary[trait.trait_type] = [trait.value]
              //   initialMetadata[trait.trait_type] = trait.value
              // }
            }
          });
        }
      }

      // for nft 2 and 3
      else {
        console.log(nft);
        // nonArtworks grabs the non artwork array categories from these particular NFTs to add None to them in the case they dont match with the original NFT
        let nonArtworks = [];
        if (!Object.keys(upgradingProjectNFTs).includes(nft.mint)) {
          nft.attributes.forEach((trait) => {
            // artwork array trait category
            if (Object.keys(assetDict[builderState]).includes(trait.trait_type)) {
              if (!artworkArray.includes(trait.trait_type)) {
                artworkArray.push(trait.trait_type);
              }
              assetDict[builderState][trait.trait_type].forEach((thing) => {
                if (trait.value === "Colonel's Cap" && props.projectID === 43) {
                  trait.value = "Colonels Cap";
                }
                // matching on the trait name to get the assetID
                if (thing.traitName === trait.value) {
                  // if the assetID doesnt already exist in the trait choice dict, add it
                  if (!assetDictIDs.includes(thing.id)) {
                    // category already exists in the traits so were pushing
                    if (Object.keys(assetDictTemporary).includes(trait.trait_type)) {
                      assetDictTemporary[trait.trait_type].push(thing);
                      assetDictIDs.push(thing.id);
                    }
                    // new category so were creating
                    else {
                      assetDictTemporary[trait.trait_type] = [thing];
                      assetDictIDs.push(thing.id);
                    }
                  }
                }
              });
            }
            // non artwork array trait category
            else {
              if (!nonArtworks.includes(trait.trait_type)) {
                nonArtworks.push(trait.trait_type);
              }
              if (!nonArtworkArrayTemp.includes(trait.trait_type)) {
                nonArtworkArrayTemp.push(trait.trait_type);
              }
              // if this category doesnt exist already then we need to add none for the first NFT
              if (!Object.keys(assetDictTemporary).includes(trait.trait_type)) {
                assetDictTemporary[trait.trait_type] = [trait.value, "None"];
                initialMetadata[trait.trait_type] = trait.value;
              } else {
                if (!assetDictTemporary[trait.trait_type].includes(trait.value)) {
                  assetDictTemporary[trait.trait_type].push(trait.value);
                }
              }
            }
          });
          // Iterate through all non artwork array categories and add None to the ones that didnt appear in the 2nd and 3rd NFT
          nonArtworkArrayTemp.forEach((category) => {
            if (!nonArtworks.includes(category)) {
              if (!assetDictTemporary[category].includes("None")) {
                assetDictTemporary[category].push("None");
              }
            }
          });
        } else {
          Object.keys(upgradingProjectNFTs[nft.mint].attributeDict).forEach((category) => {
            // artwork array trait category
            if (Object.keys(assetDict[builderState]).includes(category)) {
              if (!artworkArray.includes(category)) {
                artworkArray.push(category);
              }
              assetDict[builderState][category].forEach((thing) => {
                if (
                  upgradingProjectNFTs[nft.mint].attributeDict[category] === "Colonel's Cap" &&
                  props.projectID === 43
                ) {
                  upgradingProjectNFTs[nft.mint].attributeDict[category] = "Colonels Cap";
                }
                // matching on the trait name to get the assetID
                if (thing.traitName === upgradingProjectNFTs[nft.mint].attributeDict[category]) {
                  // if the assetID doesnt already exist in the trait choice dict, add it
                  if (!assetDictIDs.includes(thing.id)) {
                    // category already exists in the traits so were pushing
                    if (Object.keys(assetDictTemporary).includes(category)) {
                      assetDictTemporary[category].push(thing);
                      assetDictIDs.push(thing.id);
                    }
                    // new category so were creating
                    else {
                      assetDictTemporary[category] = [thing];
                      assetDictIDs.push(thing.id);
                    }
                  }
                }
              });
            }
            // non artwork array trait category
            else {
              if (!nonArtworks.includes(category)) {
                nonArtworks.push(category);
              }
              if (!nonArtworkArrayTemp.includes(category)) {
                nonArtworkArrayTemp.push(category);
              }
              // if this category doesnt exist already then we need to add none for the first NFT
              if (!Object.keys(assetDictTemporary).includes(category)) {
                assetDictTemporary[category] = [
                  upgradingProjectNFTs[nft.mint].attributeDict[category],
                  "None",
                ];
                initialMetadata[category] = upgradingProjectNFTs[nft.mint].attributeDict[category];
              } else {
                if (
                  !assetDictTemporary[category].includes(
                    upgradingProjectNFTs[nft.mint].attributeDict[category]
                  )
                ) {
                  assetDictTemporary[category].push(
                    upgradingProjectNFTs[nft.mint].attributeDict[category]
                  );
                }
              }
            }
          });
          // Iterate through all non artwork array categories and add None to the ones that didnt appear in the 2nd and 3rd NFT
          nonArtworkArrayTemp.forEach((category) => {
            if (!nonArtworks.includes(category)) {
              if (!assetDictTemporary[category].includes("None")) {
                assetDictTemporary[category].push("None");
              }
            }
          });
        }
      }

      Object.keys(assetDict[builderState]).forEach((category) => {
        // If there was a category that didnt appear in any of the NFTs
        if (!artworkArray.includes(category)) {
          // add a none value to the category so that it appears in the metadata
          if (!Object.keys(assetDictTemporary).includes(category)) {
            assetDictTemporary[category] = [{ traitName: "None", id: -1 }];
          }
          // TODO: I dont think this is necessary but need to ask augusto -> dont think I wrote it
          else {
            let nonePresent = false;
            assetDictTemporary[category].forEach((dicts) => {
              if (dicts.traitName === "None") {
                nonePresent = true;
              }
            });
            if (!nonePresent) {
              assetDictTemporary[category].push({ traitName: "None", id: -1 });
            }
          }
        }
      });
    });

    console.log(
      assetDictTemporary,
      assetDict,
      initialMetadata,
      fusedIDMetadataTemp,
      nonArtworkArrayTemp
    );
    setNonArtworkArray(nonArtworkArrayTemp);
    setSelectedCategory(Object.keys(assetDictTemporary)[0]);
    setAssetDictTemp(assetDictTemporary);
    setFusedMetadata(initialMetadata);
    setFusedIDMetadata(fusedIDMetadataTemp);
    setShowFusionBuilder(true);
  };

  const resetFuser = () => {
    setShowFusionBuilder(false);
    setCreatedOriginalRender(false);
  };

  let nftsSelected = [
    { nft: nft1, isSelected: nft1Selected, noSelectBG: "bg-primary-yellow" },
    { nft: nft2, isSelected: nft2Selected, noSelectBG: "bg-primary-red-tint" },
    { nft: nft3, isSelected: nft3Selected, noSelectBG: "bg-primary-red" },
  ];
  if (props.projectID === 43){
    nftsSelected = [
      { nft: nft1, isSelected: nft1Selected, noSelectBG: "bg-primary-yellow" },
      { nft: nft2, isSelected: nft2Selected, noSelectBG: "bg-primary-red-tint" }
    ];
  }

  return (
    <>
      {popup ? renderPopup() : ""}
      <div className="h-full flex-grow flex-col flex px-10 py-5 bg-[#8C838A] bg-opacity-10">
        <div className="flex justify-between w-full">
          <p className="text-lg md:text-4xl font-text font-bold text-bold text-dark-gray">FUSIONS</p>

          {showFusionBuilder ? (
            <button
              className="font-title-regular  top-8 z-10 text-lg text-dark-gray uppercase"
              onClick={() => resetFuser()}
            >
              &#9664; Back
            </button>
          ) : (
            ""
          )}
        </div>
        {showFusionBuilder ? (
          <div className="w-full  grid sm:grid-cols-2 gap-3 lg:gap-6 xl:gap-10 2xl:gap-20 mt-8">
            <div
              ref={imgRef}
              className="w-full h-0 pb-[100%] overflow-hidden relative shadow-border rounded-lg mx-auto  "
            >
              {errorImage ? (
                <div className="absolute w-full aspect-square h-auto bottom-0 left-1/2 -translate-x-1/2 rounded-lg   object-contain mx-auto">
                  <img
                    // style={{ width: 500, height: 500 }}
                    className="absolute w-full aspect-square h-auto bottom-0 left-1/2 -translate-x-1/2 border-gray-300    rounded-lg   object-contain mx-auto"
                    src={NotAllowed}
                  />
                </div>
              ) : imageArray.length ? (
                <div>
                  {imageArray.map((link, index) => (
                    <img
                      key={index}
                      // style={{ width: wrapperSize, height: wrapperSize }}
                      className="absolute w-full aspect-square h-auto top-0 left-1/2 -translate-x-1/2 border-gray-300    rounded-lg   object-contain mx-auto"
                      src={link}
                    />
                  ))}
                </div>
              ) : (
                <div className="absolute w-full aspect-square h-auto bottom-0 left-1/2 -translate-x-1/2 rounded-lg   object-contain mx-auto">
                  <img
                    // style={{ width: 500, height: 500 }}
                    className="absolute w-full aspect-square h-auto bottom-0 left-1/2 -translate-x-1/2 border-gray-300    rounded-lg   object-contain mx-auto"
                    src={startImage.image}
                  />
                </div>
              )}
            </div>
            <div className="hidden sm:block relative w-full h-full bg-gray/10">
              <div className="h-full w-full flex flex-col justify-center items-center text-lg font-text  px-10  row-span-2 overflow-hidden">
                <div className=" w-full mx-auto   overflow-auto   ">
                  <div className="w-full   gap-5 grid grid-cols-2  ">
                    {fusedMetadata &&
                      Object.entries(fusedMetadata).map(([key, val], i) => (
                        <div
                          key={i}
                          style={{ backgroundColor: theme?.color3 }}
                          className="text-center w-full text-white  font-medium text-xl "
                        >
                          <p className="w-full py-1 bg-gray-100 text-center text-white overflow-ellipsis overflow-hidden whitespace-nowrap uppercase mb-1.5">
                            {key}
                          </p>

                          <p className="overflow-ellipsis overflow-hidden text-primary-red">
                            {val}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <div className="w-fit ">
                { assetDictTemp && (
                    <DropDown
                      value={selectedCategory}
                      values={Object.keys(assetDictTemp)}
                      selectValue={setSelectedCategory}
                    />
                )}
              </div>
              {selectedCategory ? (
                <div className="bg-gray-200  p-3 flex-grow w-full overflow-hidden h-auto   rounded-2xl">
                  <div className="w-full h-full overflow-x-auto">
                    <ul className="w-fit grid grid-cols-3 grid-rows-1 gap-1 h-auto  px-3">
                      {renderTraits()}
                    </ul>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className=" w-1/2 h-full flex flex-col items-center justify-center mx-auto">
              <button
                style={{ backgroundColor: theme?.color2, color: theme?.color1 }}
                className="bg-primary-red w-full rounded-full text-white py-3 px-6  text-2xl font-text uppercase font-bold hover:bg-red-light mb-5  hover:opacity-50"
                onClick={() => {
                  fuseNFTsNew();
                }}
              >
                FUSE!
              </button>
            </div>

            {/* <div className="h-full flex flex-col justify-between">
              <button
                style={{ backgroundColor: theme?.color2 }}
                className="bg-yellow-deep text-white py-3 px-6 rounded-md text-2xl font-gilroy-bold mb-5 mt-auto hover:opacity-50"
                onClick={() => {
                  fuseNFTs();
                }}
              >

              </button>
              {hasPaymentRequirement ? (
                <p className="font-title-bold text-dark-gray text-lg mb-3 relative text-left uppercase">
                  PRICE PER FUSION: {paymentRequirements["price"]}{" "}
                  {currencyDict[paymentRequirements["hash"]]}
                </p>
              ) : (
                ""
              )}
              <div className="grid xl:grid-cols-2 w-full xl:min-w-[300px] max-w-[350px] gap-4 mb-24">
                {fusedMetadata &&
                  Object.entries(fusedMetadata).map(([key, val], i) => (
                    <div
                      key={i}
                      style={{ backgroundColor: theme?.color3 }}
                      className="p-3 rounded-md w-full text-white bg-red-light uppercase font-medium"
                    >
                      <p className="overflow-ellipsis overflow-hidden whitespace-nowrap">{key}</p>
                      <p className="overflow-ellipsis overflow-hidden">{val}</p>
                    </div>
                  ))}
              </div>
            </div> */}
          </div>
        ) : (
          <section className="">
            <div className="my-4 flex justify-between items-center">
              <div className="w-fit my-4">
                <DropDown
                  value={builderState}
                  selectValue={switchBuilderState}
                  values={Object.keys(assetDict)}
                />
              </div>
              {
                popupNFTs ?
                ""
                :
                <div className="hidden sm:flex sm:gap-5 sm:items-center">
                  <div className="w-10 h-10 rounded-full shadow-thick">
                    <BurnIcon />
                  </div>
                  <p className="text-gray text-right font-text font-bold">
                    indicates the NFTs that <br /> will be burned on fusion
                  </p>
                </div>
              }
            </div>
            {
              popupNFTs ?
              renderNFTsNew()
              :
              <>
                <div className={`hidden sm:grid ${nftsSelected.length === 3 ? "sm:grid-cols-3" : "sm:grid-cols-2"} sm:gap-3 lg:gap-12 2xl:gap-14`}>
                  {nftsSelected.map(({ nft, isSelected, noSelectBG }, i) => {
                    // console.log(nftsSelected)
                    const beBurned = isSelected && i > 0;
                    const imgSrc = isSelected
                      ? Object.keys(upgradingProjectNFTs).includes(nft.mint)
                        ? `${upgradingProjectNFTs[nft.mint].image}?${new Date().getTime()}`
                        : nft.image
                      : NoImage;

                    return (
                      <div
                        key={i}
                        className={`justify-self-center w-full h-full transition ease-in-out group cursor-pointer relative  duration-300  `}
                      >
                        <div
                          onClick={
                            isSelected
                              ? () => deselectNFT(nft.mint, userNFTDict[builderState][nft.mint], 1)
                              : () => setNFTs(i + 1)
                          }
                          className={`shadow-border overflow-hidden rounded-lg  w-full pb-[100%] ${
                            !isSelected ? noSelectBG : ""
                          } relative`}
                        >
                          {isSelected ? (
                            <img
                              disabled={!isSelected && !filteredProjectNFTs}
                              className={`absolute top-0 left-0 w-full aspect-square h-auto object-contain  ${
                                !isSelected ? "opacity-0" : ""
                              } `}
                              src={imgSrc}
                              alt="picked nft"
                            />
                          ) : (
                            <div className="absolute w-1/4 h-1/4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-dark-gray flex items-center justify-center shadow-2xl transition-transform  group-hover:-translate-y-[calc(50%+8px)] pointer-events-none group-hover:bg-gray">
                              <img className="w-1/3 h-auto relative " src={pointer} alt="pick nft" />
                            </div>
                          )}
                        </div>
                        {beBurned ? (
                          <div className="absolute w-12 h-12 bottom-4 right-0 translate-x-1/2 -translate-y-1/2 filter shadow-thick rounded-full">
                            <BurnIcon />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="grid-cols-2 sm:hidden">
                    {
                      nft1Selected && nft2Selected && nft3Selected ?
                      ""
                      :
                      <div onClick={() => {
                              if (!nftsSelected[0].isSelected) {
                                  setNFTs(1);
                              } else if (!nftsSelected[1].isSelected) {
                                  setNFTs(2);
                              } else if (!nftsSelected[2].isSelected) {
                                  setNFTs(3);
                              }}} className='shadow-border overflow-hidden rounded-lg  w-full pb-[100%] bg-primary-yellow relative'>
                        <div className="absolute w-1/4 h-1/4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-dark-gray flex items-center justify-center shadow-2xl transition-transform  group-hover:-translate-y-[calc(50%+8px)] pointer-events-none group-hover:bg-gray">
                          <img className="w-1/3 h-auto relative " src={pointer} alt="pick nft" />
                        </div>
                      </div>
                    }
                    <div className="flex gap-5 items-center mt-8">
                      <div className="w-10 h-10 rounded-full shadow-thick">
                        <BurnIcon />
                      </div>
                      <p className="text-gray text-left font-text font-bold">
                        Indicates the NFTs that <br /> will be burned on fusion.
                      </p>
                    </div>
                    <div className="text-left mt-8">
                      <p className="text-lg md:text-4xl font-text font-bold text-bold text-dark-gray">SELECTED NFTs</p>
                    </div>
                    <div className="grid grid-cols-2 gap-5 max-h-[77vh]">
                      {nftsSelected.map(({ nft, isSelected, noSelectBG }, i) => {
                      const beBurned = isSelected && i > 0;
                      const imgSrc = isSelected
                        ? Object.keys(upgradingProjectNFTs).includes(nft.mint)
                          ? `${upgradingProjectNFTs[nft.mint].image}?${new Date().getTime()}`
                          : nft.image
                        : NoImage;

                      return (
                        <div
                          key={i}
                          className={`justify-self-center w-full h-full transition ease-in-out group cursor-pointer relative  duration-300  `}
                        >
                        {
                          isSelected ?
                          <div>
                            <div
                              onClick={() => deselectNFT(nft.mint, userNFTDict[builderState][nft.mint], 1)}
                              className="shadow-border overflow-hidden rounded-lg  w-full pb-[100%] relative">
                              <img
                                disabled={!isSelected && !filteredProjectNFTs}
                                className="absolute top-0 left-0 w-full aspect-square h-auto object-contain"
                                src={imgSrc}
                                alt="picked nft"
                              />
                            </div>
                            {beBurned ? (
                              <div className="absolute w-12 h-12 bottom-4 right-0 translate-x-1/2 -translate-y-1/2 filter shadow-thick rounded-full">
                                <BurnIcon />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          :
                          ""
                        }
                        </div>
                      );
                    })}
                    </div>
                </div>
                <div className="text-center mt-14">
                  <button
                    // style={{ backgroundColor: theme?.color2 }}
                    className="bg-primary-red text-white py-4 px-14 rounded-full text-2xl font-text font-bold hover:bg-red-light disabled:opacity-50 hover:opacity-50"
                    onClick={() => {
                      startFusions();
                    }}
                    disabled={!(nft1Selected && nft2Selected) && !(nft1Selected && nft3Selected)}
                  >
                    BEGIN
                  </button>
                </div>
              </>
            }
          </section>
        )}{" "}
      </div>
    </>
  );
};

export default Fusions;
