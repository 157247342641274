import { Link } from "react-router-dom";
import Logo from "../img/main-logo.png";
import { FiMenu } from "react-icons/fi";
import SideBarNew from "../components/SideBarNew";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useState, useEffect } from "react";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import * as web3 from "@solana/web3.js";
import { FaRegImage } from "react-icons/fa";
import axios from "axios";
import {
  MINT_SIZE,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createInitializeMintInstruction,
  getMinimumBalanceForRentExemptMint,
  getAssociatedTokenAddress,
  getOrCreateAssociatedTokenAccount,
  createAssociatedTokenAccountInstruction,
  createMintToInstruction,
  getAccount,
  createTransferCheckedInstruction,
  createTransferInstruction,
} from "@solana/spl-token";
import {
  Connection,
  clusterApiUrl,
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import * as bs58 from "bs58";
import { otakuHash } from "../components/otakuHash";
import { traitHash } from "../components/traitHash";
import { collectionTraitData } from "../components/metadata";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import Logo2 from "../img/Logo-contorno-hombre.png";
import Template from "../img/initial_image.png";
import NotAllowed from "../img/not_allowed.png";
import RightBg from "../img/bg-image.png";
import NoImage from "../img/no-image.png";
import Backdrop from "../components/UI/Backdrop";
import Reload from "../img/reload.png";
import { P, SelectNFTBtn, UpgradeNowBtn } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import TraitsHeader from "../components/TraitsHeader";
import { uploadFile } from "react-s3";
import S3 from "react-aws-s3";

const Customize = (props) => {
  const theme = useTheme();
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const projectHashURL = props.projectHashURL;

  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("default");

  // Mutation Details
  const [mutationID, setMutationID] = useState();
  const [mutationName, setMutationName] = useState();
  const [conditionsDict, setConditionsDict] = useState({});
  const [nftRequirements, setNFTRequirements] = useState([]);
  const [paymentRequirements, setPaymentRequirements] = useState([]);
  const [metadataRequirements, setMetadataRequirements] = useState([]);

  // Project Variables
  const [projectHashArray, setProjectHashArray] = useState([]);

  // Boolean Variables
  const [hasMutation, setHasMutation] = useState(0);
  const [hasNFTRequirement, setHasNFTRequirement] = useState(0);
  const [hasPaymentRequirement, setHasPaymentRequirement] = useState(0);
  const [hasMetadataRequirement, setHasMetadataRequirement] = useState(0);
  const [nftRequirementsMet, setNFTRequirementsMet] = useState(false);
  const [nftRequirementPopup, setNFTRequirementPopup] = useState(false);
  const [readyToUpgrade, setReadyToUpgrade] = useState(false);
  const [upgradeError, setUpgradeError] = useState(false);
  const [fetchedWalletNFTs, setFetchedWalletNFTs] = useState(false);
  const [filteredProjectNFTs, setFilteredProjectNFTs] = useState(false);
  const [loadNFTs, setLoadNFTs] = useState(false);
  const [popupNFTs, setPopupNFTs] = useState(false);

  // User Variables
  const [walletNFTs, setWalletNFTs] = useState([]);
  const [ownedNFTDict, setOwnedNFTDict] = useState({});
  const [selectedNFT, setSelectedNFT] = useState();
  const [imageArray, setImageArray] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [newMetadataID, setNewMetadataID] = useState({});
  const [ownedNFTRequirements, setOwnedNFTRequirements] = useState([]);
  const [requirementSelectionArray, setRequirementSelectionArray] = useState([]);
  const [metadataLink, setMetadataLink] = useState();
  const [userProjectNFTs, setUserProjectNFTs] = useState({});
  const [sortedData, setSortedData] = useState();
  const [currentlyUpgrading, setCurrentlyUpgrading] = useState({});

  // grab all Mutation Details
  useEffect(() => {
    var data = JSON.stringify({
      projectID: props.projectID,
      action: "getMutationDetails",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.hasLiveMutation) {
          setHasMutation(1);
          setMutationID(response.data.mutationDetails.mutationID);
          setMutationName(response.data.mutationDetails.mutationName);
          setConditionsDict(response.data.mutationDetails.conditionsDict);
        } else {
          setHasMutation(0);
        }
      })
      .catch(function (error) {
        // // // console.log(error);
      });
  }, []);

  // Grabbing the wallet NFTs - will only grab it once
  // TODO: reload NFTs on a wallet change (new connection, disconnect, and change wallet)
  useEffect(() => {
    if (publicKey && !fetchedWalletNFTs) {
      const getNFTs = async () => {
        let myNfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        let walletDictTemp = {};
        myNfts.forEach((nft) => {
          walletDictTemp[nft.mint] = nft.data.uri;
        });
        setFetchedWalletNFTs(true);
        setWalletNFTs(myNfts);
      };
      getNFTs();
    }
  }, [publicKey, fetchedWalletNFTs]);

  // grabbing the hashlist from a given project
  useEffect(() => {
    if (projectHashURL && !projectHashArray.length) {
      var config = {
        method: "get",
        url: projectHashURL,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          setProjectHashArray(response.data);
        })
        .catch(function (error) {
          // // console.log(error);
        });
    }
  }, [projectHashURL]);

  useEffect(() => {
    if (conditionsDict) {
      const getNFTRequirementHashes = async () => {
        let nftRequirementsTemp = [];
        conditionsDict.nftRequirement.map((link) => {
          var config = {
            method: "get",
            url: link,
            headers: {
              "Content-Type": "application/json",
            },
          };

          axios(config).then((hashlist) => {
            nftRequirementsTemp.push(hashlist.data);
            if (nftRequirementsTemp.length === conditionsDict.nftRequirement.length) {
              setNFTRequirements(nftRequirementsTemp);
            }
          });
        });
      };

      let conditionsTemp = Object.keys(conditionsDict);

      if (conditionsTemp.includes("nftRequirement")) {
        setHasNFTRequirement(1);
        getNFTRequirementHashes();
      }

      if (conditionsTemp.includes("payment")) {
        setHasPaymentRequirement(1);
        setPaymentRequirements(conditionsDict.payment);
      }

      if (conditionsTemp.includes("metadataRestrictions")) {
        setHasMetadataRequirement(1);
        console.log(conditionsDict.metadataRestrictions);
        setMetadataRequirements(conditionsDict.metadataRestrictions);
      }
    }
  }, [conditionsDict]);

  useEffect(() => {
    if (requirementSelectionArray.length) {
      // // // // console.log(requirementSelectionArray)
      let test = true;
      requirementSelectionArray.map((value) => {
        // // // // console.log(value)
        if (!value) {
          test = false;
        }
      });

      if (test) {
        // // // // console.log("here too")
        setNFTRequirementsMet(true);
        setNFTRequirementPopup(false);
      }
    }
  }, [requirementSelectionArray]);

  useEffect(() => {
    if (nftRequirements.length) {
      let requirementSelectionArrayTemp = Array(nftRequirements.length).fill(0);
      setRequirementSelectionArray(requirementSelectionArrayTemp);
      const getListArray = async () => {
        let nftListArray = [];
        let finalArray = await Promise.all(
          nftRequirements.map(async (hashList) => {
            let miniNFTList = [];
            let tempArray = [];
            walletNFTs.map((nft) => {
              if (hashList.includes(nft.mint)) {
                tempArray.push(nft);
              }
            });
            let thing = Promise.all(
              tempArray.map((nft) => {
                if (hashList.includes(nft.mint)) {
                  var config = {
                    method: "get",
                    url: nft.data.uri,
                    headers: {
                      "Content-Type": "application/json",
                    },
                  };
                  return axios(config).then((results) => {
                    let tempDict = results.data;
                    tempDict.hash = nft.mint;
                    return tempDict;
                  });
                }
              })
            ).then((results) => {
              return results;
            });
            return thing;
          })
        ).then((finalResults) => {
          return finalResults;
        });
        setOwnedNFTRequirements(finalArray);
      };

      getListArray();
    }
  }, [nftRequirements]);

  // grabbing all eligible projectNFTs
  useEffect(() => {
    // console.log(conditionsDict)
    if (walletNFTs.length && projectHashArray.length && !filteredProjectNFTs) {
      const filterUserNFTs = async () => {
        let ownedNFTDictTemp = { ...ownedNFTDict };
        let currentlyMutating = [];

        var data = JSON.stringify({
          projectID: props.projectID,
          projectHashArray: projectHashArray,
          action: "currentlyMutating",
        });

        var config = {
          method: "post",
          url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations",
          headers: {
            "x-api-key": process.env.GATEWAY_KEY,
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config).then(async (response) => {
          let currentlyUpgrading = {};
          await walletNFTs.map(async (nft) => {
            if (response.data.upgradingNFTs.includes(nft.mint)) {
              currentlyUpgrading[nft.mint] = response.data.currentlyUpgrading[nft.mint];
            }
          });
          setCurrentlyUpgrading(currentlyUpgrading);
          currentlyMutating = response.data.upgradingNFTs;
        });

        await walletNFTs.map(async (nft) => {
          if (projectHashArray.includes(nft.mint)) {
            console.log(metadataRequirements);
            var config = {
              method: "get",
              url: nft.data.uri + "?nocache=" + new Date().getTime(),
              headers: {
                "Content-Type": "application/json",
              },
            };
            let nftTemp = await axios(config).then((results) => {
              return results.data;
            });
            if (Object.keys(metadataRequirements).length) {
              // console.log(metadataRequirements)
              let toAdd = false;
              nftTemp.attributes.forEach((attribute) => {
                if (Object.keys(metadataRequirements).includes(attribute.trait_type)) {
                  if (metadataRequirements[attribute.trait_type].includes(attribute.value)) {
                    console.log(metadataRequirements, nft.mint);
                    toAdd = true;
                  }
                }
              });

              if (toAdd && !currentlyMutating.includes(nft.mint)) {
                ownedNFTDictTemp[nft.mint] = { ...nftTemp, metadataLink: nft.data.uri };
              }
            } else {
              if (!currentlyMutating.includes(nft.mint)) {
                ownedNFTDictTemp[nft.mint] = { ...nftTemp, metadataLink: nft.data.uri };
              }
            }
          }
        });
        setOwnedNFTDict(ownedNFTDictTemp);
        setUserProjectNFTs(ownedNFTDictTemp);
        setFilteredProjectNFTs(true);
      };
      filterUserNFTs();
    }
  }, [walletNFTs, projectHashArray, filteredProjectNFTs, metadataRequirements]);

  const setUpgradeNFT = (selectedNFTDict) => {
    setSelectedNFT(selectedNFTDict.hash);
    var data = JSON.stringify({
      projectID: props.projectID,
      hash: selectedNFTDict.hash,
      mutationName: mutationName,
      action: "getMutationImage",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setReadyToUpgrade(true);
        setImageArray(response.data.imageArray);
        setNewMetadataID(response.data.newMetadataID);
        setMetadataLink(response.data.metadaLink);
        setSortedData(response.data.sortedData);
        let metadataTemp = response.data.metadata;
        if (props.projectID == 31) {
          metadataTemp["Beta"] = "Yes";
        } else if (props.projectID == 41) {
          console.log("got here lizard");
          metadataTemp["Lizard Type"] = mutationName;
        } else if (props.projectID == 44) {
          metadataTemp[mutationName] = "Yes";
        } else {
          // console.log("got here player")
          metadataTemp["Version"] = mutationName;
        }
        console.log(metadataTemp);
        setMetadata(metadataTemp);
      })
      .catch(function (error) {
        // // // console.log(error);
      });
  };

  const beginMutation = () => {
    if (!hasNFTRequirement || nftRequirementsMet) {
      mutationTransaction();
    } else {
      setNFTRequirementPopup(true);
    }
  };

  const renderRequirementPopup = () => {
    return (
      <Backdrop
        show
        closeModal={() => {
          setNFTRequirementPopup(false);
        }}
      >
        <div
          style={{ maxHeight: "calc(100vh - 80px)" }}
          className=" bg-white-off px-10 py-5  w-fit  text-center rounded-lg  grid grid-rows-[max-content,1fr] gap-6  max-h-full"
        >
          <P className="font-title-bold text-primary-red text-[18px] text-left mb-5 relative">
            Please select the NFTs you would like to send for this mutation to be completed.
          </P>
          <div className=" flex-grow overflow-y-auto">
            <div className="w-full flex flex-wrap gap-5">
              {ownedNFTRequirements?.map((nftList, index) => (
                <div>
                  <P className="font-title-bold text-primary-red text-[14px] text-left mb-5 relative">
                    Please select an NFT for this requirement
                  </P>
                  {nftList?.map((hash) => (
                    <div class=" max-w-[300px] shadow-md rounded-md px-2 py-2 transition ease-in-out hover:-translate-y-2 duration-300 dark:shadow-white/60 dark:hover:shadow-xl dark:hover:shadow-white/70 dark:bg-[#333333]">
                      <img
                        src={hash.image}
                        className="w-full cursor-pointer p-1"
                        style={{ marginTop: 10, borderRadius: 10 }}
                      />
                      <p className="text-center py-2 text-gray-deep uppercase font-gilroy-bold text-[12px]">
                        {hash.name}
                      </p>
                      <SelectNFTBtn
                        className={`font-title-regular px-5 py-1 lg:text-[11px] border-2 border-primary-red rounded-lg uppercase shadow-lg hover:bg-primary-red hover:text-gray-light hover:border-2 hover:border-primary-red ${
                          requirementSelectionArray.includes(hash.hash)
                            ? "bg-primary-red text-black"
                            : "bg-gray-light text-primary-red"
                        }`}
                        onClick={() => {
                          selectNFTRequirement(hash, index);
                        }}
                      >
                        SELECT NFT
                      </SelectNFTBtn>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Backdrop>
    );
  };

  const selectNFTRequirement = (nft, index) => {
    // // // console.log(nft, index, requirementSelectionArray)
    let tempSelectionArray = [...requirementSelectionArray];
    tempSelectionArray[index] = nft.hash;
    setRequirementSelectionArray(tempSelectionArray);
  };

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const mutationTransaction = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    let identifyingHash = randomHash(50);
    setPopup(true);

    const feeWallet = await new web3.PublicKey("DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix");

    var transaction = new web3.Transaction().add(
      web3.SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: feeWallet,
        lamports: web3.LAMPORTS_PER_SOL * 0.03,
      })
    );

    const latestBlockhash = await connection.getLatestBlockhash();

    if (hasPaymentRequirement > 0) {
      await Promise.all(
        paymentRequirements.map(async (payment) => {
          // // // // console.log(props.projectCreator)
          const creatorDestination = await new web3.PublicKey(props.projectCreator);
          if (payment.type == "SOL") {
            transaction.add(
              web3.SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: creatorDestination,
                lamports: web3.LAMPORTS_PER_SOL * payment.price,
              })
            );
          }
          if (payment.type == "SPL") {
            const tokenPublicKey = await new web3.PublicKey(payment.hash);

            const destinationWalletCoinAccount = await getAssociatedTokenAddress(
              tokenPublicKey,
              creatorDestination
            );

            let coin_account;

            try {
              coin_account = await getAccount(connection, destinationWalletCoinAccount);
            } catch (error) {
              try {
                const coinTransaction = new web3.Transaction().add(
                  createAssociatedTokenAccountInstruction(
                    publicKey,
                    destinationWalletCoinAccount,
                    creatorDestination,
                    tokenPublicKey,
                    TOKEN_PROGRAM_ID,
                    ASSOCIATED_TOKEN_PROGRAM_ID
                  )
                );

                const signature = await sendTransaction(coinTransaction, connection);

                await connection.confirmTransaction(signature, "processed");
              } catch (error) {
                throw error;
              }
            }

            const sourceWalletCoinAccount = await getAssociatedTokenAddress(
              tokenPublicKey,
              publicKey
            );

            let tokenInfo = await connection.getTokenSupply(tokenPublicKey);
            let finalDecimal = web3.LAMPORTS_PER_SOL;
            let decimal = tokenInfo.value.decimals;
            if (decimal == 0) {
              finalDecimal = 1;
            } else if (decimal == 1) {
              finalDecimal = 10;
            } else if (decimal == 2) {
              finalDecimal = 100;
            } else if (decimal == 3) {
              finalDecimal = 1000;
            } else if (decimal == 4) {
              finalDecimal = 10000;
            } else if (decimal == 5) {
              finalDecimal = 100000;
            } else if (decimal == 6) {
              finalDecimal = 1000000;
            } else if (decimal == 7) {
              finalDecimal = 10000000;
            } else if (decimal == 8) {
              finalDecimal = 100000000;
            }

            transaction.add(
              createTransferInstruction(
                sourceWalletCoinAccount,
                destinationWalletCoinAccount,
                publicKey,
                payment.price * finalDecimal,
                [],
                TOKEN_PROGRAM_ID
              )
            );
          }
        })
      );
    }

    if (hasNFTRequirement > 0) {
      await Promise.all(
        requirementSelectionArray.map(async (nftHash) => {
          const nftPublicKey = await new web3.PublicKey(nftHash);
          const creatorDestination = await new web3.PublicKey(props.projectCreator);
          const destinationWalletNFTAccount = await getAssociatedTokenAddress(
            nftPublicKey,
            creatorDestination
          );
          let nft_account;
          try {
            nft_account = await getAccount(connection, destinationWalletNFTAccount);
          } catch (error) {
            try {
              const nftTransaction = new web3.Transaction().add(
                createAssociatedTokenAccountInstruction(
                  publicKey,
                  destinationWalletNFTAccount,
                  creatorDestination,
                  nftPublicKey,
                  TOKEN_PROGRAM_ID,
                  ASSOCIATED_TOKEN_PROGRAM_ID
                )
              );
              const signature = await sendTransaction(nftTransaction, connection);
              await connection.confirmTransaction(signature, "processed");
            } catch (error) {
              setPopup(true);
              setPopupState("transactionError");
              throw error;
            }
          }

          const sourceWalletNFTAccount = await getAssociatedTokenAddress(nftPublicKey, publicKey);

          transaction.add(
            createTransferCheckedInstruction(
              sourceWalletNFTAccount,
              nftPublicKey,
              destinationWalletNFTAccount,
              publicKey,
              1,
              0
            )
          );
        })
      );
    }

    var data = JSON.stringify({
      action: "transactionTemp",
      nftHash: selectedNFT,
      metadata: metadata,
      attributeIDDict: newMetadataID,
      projectID: props.projectID,
      metadataLink: metadataLink,
      identifyingHash: identifyingHash,
      traitHash: "none",
      imageArray: imageArray,
      upgradeType: "mutation",
      secondaryHash: requirementSelectionArray,
      collectionName: mutationName,
      sortedData: sortedData,
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };
    //
    axios(config)
      .then(async (response) => {
        // // // console.log(response.data)
        const preApproved = response.data.preApproved;
        try {
          const signature = await sendTransaction(transaction, connection);
          await confirmMutation(selectedNFT, publicKey, signature);
          var data = JSON.stringify({
            action: "transactionConfirm",
            identifyingHash: identifyingHash,
            userWallet: publicKey.toBase58(),
            transactionID: signature,
          });
          var config = {
            method: "post",
            url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
            headers: {
              "x-api-key": process.env.GATEWAY_KEY,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              // // // console.log(response, 'got here')
              connection
                .confirmTransaction({
                  blockhash: latestBlockhash.blockhash,
                  lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                  signature: signature,
                })
                .then((sentData) => {
                  setPopup(true);
                  setPopupState("successfulPurchase");
                });
            })
            .catch(function (error) {
              setPopup(true);
              setPopupState("transactionError");
              // // // console.log(error);
            });
        } catch (error) {
          setPopup(true);
          setPopupState("transactionError");
          throw error;
        }
      })
      .catch(function (error) {
        setPopup(true);
        setPopupState("transactionError");
        // // // console.log(error);
      });
  }, [
    publicKey,
    connection,
    selectedNFT,
    newMetadataID,
    metadata,
    requirementSelectionArray,
    paymentRequirements,
    sortedData,
  ]);

  const confirmMutation = async (nftHash, publicKey, signature) => {
    // // console.log(selectedTrait)
    var data = JSON.stringify({
      action: "confirmTransformation",
      projectID: props.projectID,
      purchasingWallet: publicKey,
      transactionID: signature,
      hash: nftHash,
      transactionType: "fusion",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        console.log(response, "confirming mutation");
        return true;
      })
      .catch(function (error) {
        // // console.log(error);
        return false;
      });

    return returnResponse;
  };

  const renderPopup = () => {
    if (popupState === "default") {
      return (
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <div
            style={{ backgroundColor: `${theme?.color3}` }}
            className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
          >
            <div className="w-24 mx-auto mb-5">
              <img className="max-w-[25px] mx-auto" src={Loader} alt="loading..." />
            </div>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Upgrade in progress - please follow the prompt on your wallet.
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Once transaction is approved please remain on the page for confirmation (transactions
              may take a couple of minutes to confirm).
            </p>
            <br></br>
          </div>
        </div>
      );
    } else if (popupState === "awaitingSwap") {
      return (
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <div
            style={{ backgroundColor: `${theme?.color3}` }}
            className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
          >
            <div className="w-24 mx-auto mb-5">
              <img className="max-w-[25px] mx-auto" src={Loader} alt="loading..." />
            </div>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Your Trait has been burned and your NFT has been registered to upgrade. We are now in
              the process of sending your swapped out trait.
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Once transaction is approved please remain on the page for confirmation (transactions
              may take a couple of minutes to confirm).
            </p>
            <br></br>
          </div>
        </div>
      );
    } else if (popupState === "transactionError") {
      // // // console.log("got here error")
      return (
        <div
          style={{ backgroundColor: `${theme?.color3}` }}
          className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
        >
          <div className="w-24 mx-auto mb-5">
            <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
          </div>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            There was an issue in confirming the transaction. Please reload the page. If your NFT
            has been upgraded or appears in the currently upgrading section of our site you can
            ignore this message. If not you either cancelled the transaction or the transaction was
            never processed. Please try again!
          </p>
          <button
            onClick={() => {
              resetPopup("cancel");
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    } else if (popupState === "successfulPurchase") {
      return (
        <div
          style={{ backgroundColor: `${theme?.color3}` }}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
          }
        >
          <div className="w-24 mx-auto mb-5">
            <img className="w-full" src={props.projectLogo} alt="Logo" />
          </div>
          <p className="font-text font-bold text-white my-5 text-xl">
            Congrats, your upgrade was sent successfully!
          </p>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            You should see your metadata and image change soon (as soon as the project owner
            approves your upgrade)!
          </p>

          <button
            onClick={() => {
              resetPopup();
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    } else if (popupState === "successfulCreation") {
      return (
        <div
          style={{ backgroundColor: `${theme?.color3}` }}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
          }
        >
          <div className="w-24 mx-auto mb-5">
            <img className="w-full" src={props.projectLogo} alt="Logo" />
          </div>
          <p className="font-text font-bold text-white my-5 text-xl">
            Congrats, your SFT Creation was successful!
          </p>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            Your new trait SFT should now appear under your Traits!
          </p>

          <button
            onClick={() => {
              resetPopup();
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{ backgroundColor: `${theme?.color3}` }}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
          }
        >
          <div className="w-24 mx-auto mb-5">
            <img className="w-full" src={props.projectLogo} alt="Logo2" />
          </div>
          <p className="font-text font-bold text-white my-5 text-xl">
            Congrats, your trait was sent successfully!
          </p>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            You should see your metadata and image change soon (as soon as the project owner
            approves your upgrade)!
          </p>

          <button
            onClick={() => {
              resetPopup("success");
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
  };

  const resetPopup = async (reason) => {
    // // // // console.log(reason, popupState)
    if (reason === "cancel") {
      setPopup(false);
      setPopupState("default");
    } else {
      setLoadNFTs(false);
      setReadyToUpgrade(false);
      setFetchedWalletNFTs(false);
      setFilteredProjectNFTs(false);
      setSelectedNFT();
      setOwnedNFTDict({});
      setUserProjectNFTs({});
      setPopup(false);
      setPopupState("default");
    }
  };

  const reloadData = () => {
    setReadyToUpgrade(false);
    setFetchedWalletNFTs(false);
    setFilteredProjectNFTs(false);
    setSelectedNFT();
    setPopup(false);
    setPopupState("default");
  };

  const renderNFTs = () => {
    return (
      <>
        {Object.keys(userProjectNFTs)?.length ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
            {Object.keys(userProjectNFTs).map((nft) => {
              return (
                <div>
                  <img
                    src={userProjectNFTs[nft].image}
                    onClick={() => setUpgradeNFT({ ...userProjectNFTs[nft], hash: nft })}
                    className={`w-full cursor-pointer border-2 ${
                      selectedNFT === nft ? "border-primary-red" : "border-black"
                    } p-1`}
                    style={{ marginTop: 10, borderRadius: 10 }}
                  />
                  <p
                    className={
                      "text-center pt-2 text-gray-deep uppercase font-gilroy-bold text-[12px]"
                    }
                  >
                    {userProjectNFTs[nft].name}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-center pb-5">
            You don't have any NFTs eligible for mutation in your wallet
          </p>
        )}
      </>
    );
  };

  return (
    <div className="h-full flex-grow flex-col flex px-10 py-5 bg-[#8C838A] bg-opacity-10">
      {popup ? renderPopup() : ""}
      {nftRequirementPopup ? renderRequirementPopup() : ""}
      <div className="">
        <div className="SelectedNFT lg:min-w-[234px] 2xl:min-w-[364px]">
          {readyToUpgrade && imageArray?.length ? (
            <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full">
              <div
                style={{ borderColor: theme?.color1 }}
                className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
              >
                {imageArray.map((link) => (
                  <img
                    className="absolute  top-0 left-0  w-full h-full object-contain"
                    src={link}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full ">
              <div
                style={{ borderColor: `${theme?.color1}` }}
                className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
              >
                <img
                  className="absolute bg-white top-0 left-0 d w-full h-full object-contain"
                  src={NoImage}
                />
              </div>
            </div>
          )}
          <button
            disabled={!readyToUpgrade}
            onClick={() => beginMutation()}
            className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50"
          >
            UPGRADE NOW
          </button>
        </div>
        <div className=" bg-white p-5 rounded-md min-h-[600px]">
          <div className="grid grid-cols-2">
            {loadNFTs ? (
              <P className="font-title-bold text-primary-red text-[24px] text-left mb-5 relative">
                READY TO UPGRADE
              </P>
            ) : (
              <button
                disabled={!publicKey}
                onClick={() => setLoadNFTs(true)}
                className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50"
              >
                LOAD YOUR NFTs
              </button>
            )}
            <img
              className="font-title-bold text-primary-red text-[26px] col-start-3 mb-2 relative max-w-[30px]"
              src={Reload}
              alt={"reload"}
              onClick={() => reloadData()}
            />
          </div>
          {loadNFTs ? <> {renderNFTs()} </> : ""}
        </div>
        <div className="bg-white p-5  rounded-md  xl:max-h-full h-full xl:max-w-[250px] xl:order-3 -order-2 col-span-2 xl:col-span-1 flex flex-col  ">
          <P className="font-title-bold text-primary-red text-sm text-center mb-3 relative">
            CURRENTLY UPGRADING
          </P>
          {Object.keys(currentlyUpgrading)?.length > 0 ? (
            <div className="w-full h-3/4 xl:h-full overflow-auto flex-grow max-h-[600px]">
              <div className="flex flex-nowrap xl:flex-col  w-max h-full  gap-2.5 xl:w-full  ">
                {Object.entries(currentlyUpgrading).map(([key, value], i) => (
                  <div
                    key={i}
                    className="shadow-md p-1 xl:p-2 rounded-md transition ease-in-out hover:-translate-y-2 duration-300  w-full h-full flex flex-col max-w-[96px] xl:max-w-full "
                  >
                    <div
                      style={{ borderColor: theme?.color1 }}
                      className="w-full relative border-2 rounded-md"
                    >
                      <img
                        src={`${currentlyUpgrading[key].image}?${new Date().getTime()}`}
                        className=" w-full h-full"
                        style={{ borderRadius: 3 }}
                      />{" "}
                    </div>
                    <p
                      className={
                        "text-center pt-2 text-gray-deep uppercase font-gilroy-bold text-[12px]"
                      }
                    >
                      {currentlyUpgrading[key].name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-center pb-5">You don't have upgrading NFTs</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customize;
