import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import builderimg from "../img/builderimg.png";
import lock from "../img/lock.png";

import axios from "axios";

import BuilderArray from "../components/BuilderArray";
import CustomBtn from "../components/CustomBtn";
import DropDown from "../components/UI/DropDown/DropDown";
import styled, { useTheme } from "styled-components";
const ScrollableDiv = styled.div`
  background-color: ${({ theme }) => theme?.color2};
  &::-webkit-slider-thumb {
    background: ${({ theme }) => theme?.color1} !important;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }
`;

const Builder = (props) => {
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();

  const [selectedCategory, setSelectedCategory] = useState();

  const [assetDict, setAssetDict] = useState();

  const [metadata, setMetadata] = useState();
  const [idMetadata, setIDMetadata] = useState()

  const [imageArray, setImageArray] = useState([]);
  const [categoryMappings, setCategoryMappings] = useState({});
  const [secondaryCategories, setSecondaryCategories] = useState([]);

  const [blockedTraits, setBlockedTraits] = useState();

  const [builderState, setBuilderState] = useState("Base")
  const [builderStates, setBuilderStates] = useState([])
  const [hasUpgrade, setHasUpgrade] = useState(false)

  const [wrapperSize, setWrapperSize] = useState();
  const imgRef = useRef();

  // setting size of screen for responsiveness
  useEffect(() => {
    const section = document.getElementById("nav-section");
    let rtime;
    let timeout = false;
    const delta = 200;
    const handleResize = () => {
      rtime = new Date();
      if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
      }
    };
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      const wrapperWidth = imgRef?.current?.clientWidth;
      const wrapperHeight = imgRef?.current?.clientHeight;
      setWrapperSize(wrapperHeight > wrapperWidth ? wrapperWidth : wrapperHeight);
    }, 500);
    function resizeend() {
      if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
      } else {
        timeout = false;
        const currentWidth = imgRef?.current?.clientWidth;
        const currentHeight = imgRef?.current?.clientHeight;
        setWrapperSize(currentHeight > currentWidth ? currentWidth : currentHeight);
      }
    }
    section.style.height = "100vh";
    return () => {
      window.removeEventListener("resize", handleResize);
      section.style.height = "auto";
    };
  }, []);

  // setting the traits and the variables on load of page
  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": builderState,
      "action": "switchBuilderState"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.blockedTraits)

        response.data.hasUpgrade ? setBuilderStates(Object.keys(response.data.assetDict)) : setBuilderStates(["Base"])

        setHasUpgrade(response.data.hasUpgrade)
        setImageArray(response.data.imageArray)

        setAssetDict(response.data.assetDict[builderState])
        setSelectedCategory(Object.keys(response.data.assetDict[builderState])[0])

        setCategoryMappings(response.data.categoryMappings);
        setSecondaryCategories(response.data.secondaryCategories);

        setMetadata(response.data.sampleMetadata);
        setIDMetadata(response.data.sampleIDMetdata)

        setBlockedTraits(response.data.blockedTraits);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const renderTraits = () => {
    const traitArray = [];
    const blockedTraitArray = [];

    blockedTraits.map((blockedTrait) => {
      if (metadata[blockedTrait.traitCategory] === blockedTrait.traitName) {
        blockedTrait.blockedTraits.map((tempBlockedTraits) => {
          blockedTraitArray.push(tempBlockedTraits.id);
        });
      }
    });

    assetDict[selectedCategory].map((trait) => {
      if (blockedTraitArray.includes(trait.id)) {
        // console.log(trait);
        traitArray.push(
          <div className="w-20 h-20 relative rounded-lg cursor-pointer p-1">
            <img className="w-full h-full object-cover grayscale border rounded-lg" src={trait.image} alt={trait.traitName}/>
          </div>
        );
      } else {
        if (trait.traitName === metadata[trait.category]) {
          traitArray.push(
            <div>
              <li className="font-bold text-red-light">{trait.traitName}</li>
            </div>
          );
        } else {
          if (props.projectID == '29'){
            traitArray.push(
              <div>
                <li
                  onClick={() => renderImage(trait)}
                  className="font-bold text-dark-gray hover:opacity-50 cursor-pointer"
                >
                  {trait.traitName}
                </li>
              </div>
            );
          } else {
            traitArray.push(

                <li
                  onClick={() => renderImage(trait)}
                  className="w-20 h-20 relative rounded-lg cursor-pointer p-1"
                >
                  <img className="w-full h-full object-cover border rounded-lg" src={trait.image} alt={trait.traitName}/>
                </li>

            );
          }
        }
      }
    });
    return traitArray;
  };
  //
  const renderImage = async (trait) => {
    console.log(trait)
    let metadataDict = await generateMetadata(trait)
    // console.log(metadataDict)

    let newMetadata = metadataDict.newMetadata
    let newIDMetadata = metadataDict.newIDMetadata
    console.log(newMetadata, newIDMetadata)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "metadata": newMetadata,
      "idMetadata": newIDMetadata,
      "builderState": builderState,
      "action": "renderImage"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setImageArray(response.data.imageArray)
        setMetadata(newMetadata)
        setIDMetadata(newIDMetadata)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const generateMetadata = (trait) => {
    // console.log(trait);
    let newMetadata = {...metadata}
    let newIDMetadata = {...idMetadata}
    // need to redo this
    // if (Object.keys(categoryMappings).includes(trait.category)) {
    //   let matchedSecondary = categoryMappings[trait.category];
    //   newMetadata[trait.category] = trait.traitName;
    //   newMetadata[matchedSecondary] = trait.traitName;
    // } else {
    //   newMetadata[trait.category] = trait.traitName;
    // }
    newMetadata[selectedCategory] = trait.traitName
    newIDMetadata[selectedCategory] = trait.id
    return {'newMetadata': newMetadata, 'newIDMetadata': newIDMetadata}
  };
  //
  const renderRandom = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": builderState,
      "action": "randomize"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setImageArray(response.data.imageArray)
      setMetadata(response.data.metadata)
      setIDMetadata(response.data.idMetadata)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const loadNewBuilderState = (newState) => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": newState,
      "action": "switchBuilderState"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data.blockedTraits)
      setBuilderState(newState)
      setSelectedCategory(response.data.categoryOrderArray[0])

      setImageArray(response.data.imageArray)

      setCategoryMappings(response.data.categoryMappings)
      setSecondaryCategories(response.data.secondaryCategories)
      setMetadata(response.data.sampleMetadata)
      setIDMetadata(response.data.sampleIDMetdata)
      setBlockedTraits(response.data.blockedTraits)
      setAssetDict(response.data.assetDict[newState])
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  return (

      <section className="h-full flex-grow flex-col flex px-10 py-5 bg-[#8C838A] bg-opacity-10">
        <div className="flex justify-between w-full">
            <div className="flex flex-col gap-5">

              <p className="text-lg md:text-4xl font-text font-bold text-bold text-dark-gray">BUILDER</p>

            </div>

          </div>
        <div className="my-4">
          {/* <DropDown /> */}
        </div>
        <div className="w-full h-full grid grid-cols-2 gap-3 lg:gap-6 ">

          <div
            ref={imgRef}
            className="w-full pb-[100%] overflow-hidden relative     mx-auto  "
          >
            {wrapperSize && imageArray.length ? (
              imageArray.map((link, index) => {
                return (
                  <img
                    key={index}
                    // style={{ width: wrapperSize, height: wrapperSize }}
                    className="absolute w-full aspect-square h-auto top-0 left-1/2 -translate-x-1/2 border-gray-300    rounded-lg   object-contain mx-auto"
                    src={link}
                  />
                );
              })
            ) : (
              <div></div>
            )}
          </div>
        <div className="w-full h-full relative row-span-2">
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center text-lg font-text  px-2 xl:px-10  bg-gray/10 row-span-2 overflow-hidden">
          <div className="py-10 xl:w-1/2">
          <button
              style={{ backgroundColor: theme?.color2, color: theme?.color1 }}
              className="bg-primary-red w-full rounded-full text-white py-3 px-6  text-xl sm:text-2xl font-text uppercase font-bold hover:bg-red-light hover:opacity-50"
              onClick={() => {
                renderRandom();
              }}
            >
              Randomize
            </button>
          </div>
            <div className="h-full w-full mx-auto flex-grow  overflow-auto ">
               <div className="xl:w-1/2 mx-auto gap-5 flex flex-col justify-between h-full">
               {metadata &&
                Object.entries(metadata).map(([key, val], i) => (
                  <div
                    key={i}
                    style={{ backgroundColor: theme?.color3 }}
                    className="text-center w-full text-white  font-medium text-xl "
                  >
                    <p className="w-full py-1 bg-gray-100 text-center text-white overflow-ellipsis overflow-hidden whitespace-nowrap uppercase mb-1.5">{key}</p>

                    <p className="overflow-ellipsis overflow-hidden text-primary-red">{val}</p>
                  </div>
                ))}
               </div>
            </div>

          </div>
        </div>

          <div className="w-full gap-3 flex-col flex  justify-end self-end  overflow-hidden ">
            {
                builderStates.length > 1 ?
                  <div>
                    <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={builderState} onChange={(e) => loadNewBuilderState(e.target.value)}>
                        {builderStates.map((collectionName) => (
                            <option value={collectionName} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{collectionName}</option>
                        ))}
                    </select>
                  </div>
                  :
                  <div>
                  </div>
            }
           <div className="w-fit">
              {assetDict && (
                <DropDown
                  value={selectedCategory}
                  values={Object.keys(assetDict)}
                  selectValue={setSelectedCategory}
                />
              )}
            </div>
            {selectedCategory ? (
              <ScrollableDiv className="bg-gray-200  p-3 flex-grow w-full overflow-hidden h-auto   rounded-2xl">
                <div className="w-full h-full overflow-x-auto"><ul className="grid grid-flow-col grid-rows-2 gap-1 h-auto  px-3 py-2">{renderTraits()}</ul></div>
              </ScrollableDiv>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>

  );
};

export default Builder;
