import React from "react";
import { NavItem } from "./UI/StyledComponents";

const SideBarPoly = (props) => {

  let tabs = [
    { label: "shop", tabName: "traitShop", bg: "bg-primary-red" },
    { label: "customizer", tabName: "customize", bg: "bg-primary-red-tint" },
    { label: "builder", tabName: "builder", bg: "bg-primary-yellow" },
  ];

  // rektGang and rokoro
  if (props.projectID === 1 || props.projectID === 14){
    tabs = [{ label: "meme generator", tabName: "memeGenerator", bg: "bg-primary-yellow" }];
  }

  // brozo and taco tribe
  else if (props.projectID === 9 || props.projectID === 15){
    tabs = [{ label: "meme generator", tabName: "memeGen2", bg: "bg-primary-yellow" }];
  }

  // maxin
  else if (props.projectID === 16){
    tabs = [{ label: "trait claims", tabName: "traitClaims", bg: "bg-primary-yellow"}]
  }

  return (
    <nav className="h-full flex flex-col items-center justify-center">
      <ul className="flex flex-col items-center justify-center h-full flex-grow gap-5">
        {tabs.map((tab) => (
          <NavItem
            onClick={() => {
              props.setPage(tab.tabName);
              props?.closeMenu();
            }}
            key={`${tab.tabName}_${tab.label}`}
            className={`px-6 sm:px-8 text-xs sm:text-base cursor-pointer sm:text-black lowercase font-lekton text-white ${
              tab.tabName === props?.page ? "current" : ""
            }`}
          >
            {tab.label}
          </NavItem>
        ))}
      </ul>
    </nav>
  );
};
export default SideBarPoly;
