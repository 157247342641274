import Logo from "../img/main-logo.png";
import { FiMenu } from "react-icons/fi";
import SideBarNew from "../components/SideBarNew";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useState, useEffect } from "react";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import * as web3 from "@solana/web3.js";
import { FaRegImage } from "react-icons/fa";
import axios from "axios";
import DropDown from "../components/UI/DropDown/DropDown";
import {
  MINT_SIZE,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createInitializeMintInstruction,
  getMinimumBalanceForRentExemptMint,
  getAssociatedTokenAddress,
  getOrCreateAssociatedTokenAccount,
  createAssociatedTokenAccountInstruction,
  createMintToInstruction,
  getAccount,
  createTransferCheckedInstruction,
  createTransferInstruction,
} from "@solana/spl-token";
import {
  Connection,
  clusterApiUrl,
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  DataV2,
  createCreateMetadataAccountV2Instruction,
  createVerifyCollectionInstruction,
} from "@metaplex-foundation/mpl-token-metadata";
import {
  bundlrStorage,
  findMetadataPda,
  Metaplex,
  UploadMetadataInput,
  walletAdapterIdentity,
  keypairIdentity,
  findMasterEditionV2Pda,
} from "@metaplex-foundation/js";
import * as bs58 from "bs58";
import { otakuHash } from "../components/otakuHash";
import { traitHash } from "../components/traitHash";
import { collectionTraitData } from "../components/metadata";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import Logo2 from "../img/Logo-contorno-hombre.png";
import Template from "../img/initial_image.png";
import NotAllowed from "../img/not_allowed.png";
import RightBg from "../img/bg-image.png";
import NoImage from "../img/no-image.png";
import Backdrop from "../components/UI/Backdrop";
import SlotIMG from "../img/slot-img.png";
import { P, SelectNFTBtn, UpgradeNowBtn } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import TraitsHeader from "../components/TraitsHeader";
import { uploadFile } from "react-s3";
import S3 from "react-aws-s3";

const SlotMachineTwo = (props) => {
  const theme = useTheme();
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction } = useWallet();

  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("default");
  const [loaded, setLoaded] = useState(false);

  const [selectedTrait, setSelectedTrait] = useState();
  const [selectedTraitCategory, setSelectedTraitCategory] = useState("All Traits");
  const [traitCategories, setTraitCategories] = useState([]);
  const [digitalTraits, setDigitalTraits] = useState([]);
  const [latestSpins, setLatestSpins] = useState([]);
  const [assetNames, setAssetNames] = useState({});
  const [totalSupply, setTotalSupply] = useState();
  const [slotMachineRules, setSlotMachineRules] = useState({});

  // Pulls all traits available in the slot machine as well the slot machine rules.
  useEffect(() => {
    if (!loaded) {
      var data = JSON.stringify({
        projectID: props.projectID,
        action: "setSlotMachineTwo",
      });

      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getlivetraits",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          let traitCategories = response.data.typeDict
          traitCategories.push("All Traits")
          setDigitalTraits(response.data.digitalTraits);
          setTraitCategories(traitCategories);
          setSlotMachineRules(response.data.slotMachineRules[0]);
          setAssetNames(response.data.assetNames)
          setLoaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [loaded]);

  // Selects the trait that will be sent to the user on slot roll.
  useEffect(() => {
    let totalSupplies = 0;
    var traitsRolling = digitalTraits.filter((item) =>
      selectedTraitCategory !== "All Traits" ? item.type === selectedTraitCategory : true
    );
    traitsRolling.map((trait) => {
      totalSupplies += trait.supply - trait.amountPurchased;
    });

    let weights = [];
    traitsRolling.map((trait) => {
      weights.push((trait.supply - trait.amountPurchased) / totalSupplies);
    });

    let selectTrait = weighted_random(traitsRolling, weights);
    console.log(selectTrait);
    setTotalSupply(totalSupplies);
    setSelectedTrait(selectTrait);
  }, [selectedTraitCategory, digitalTraits]);

  // pull latest spins
  useEffect(() => {
    if (!loaded) {
      var data = JSON.stringify({
        projectID: props.projectID,
        userWallet: publicKey,
        action: "latestSpins",
      });

      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getlivetraits",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          setLatestSpins(response.data.latestSpins);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [publicKey, loaded]);

  function timeSince(dateString) {
    const datePurchased = new Date(dateString);
    const now = new Date();
    const timeInGMT = now.toGMTString();
    const timeInGMTDate = new Date(timeInGMT);
    var seconds = Math.floor((timeInGMTDate - datePurchased) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  // formula for selecting trait based on probability
  function weighted_random(items, weights) {
    var i;

    for (i = 1; i < weights.length; i++) weights[i] += weights[i - 1];
    // // // // //////console.log((weights)
    var random = Math.random() * weights[weights.length - 1];
    // // // // //////console.log((weights)
    for (i = 0; i < weights.length; i++) if (weights[i] > random) break;

    return items[i];
  }

  // checking if the trait is sold out
  const checkQuantity = async (trait) => {
    var data = JSON.stringify({
      traitID: trait.id,
      action: "getTraitToPurchase",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let soldOut = axios(config)
      .then(function (response) {
        if (response.data.soldOut) {
          setPopupState("soldOutTrait");
          setPopup(true);
          return response.data.soldOut;
        } else {
          return response.data.soldOut;
        }
      })
      .catch(function (error) {
        setPopup(true);
        setPopupState("fetchingError");
        return { soldOut: 1 };
      });

    return soldOut;
  };

  const rollTrait = useCallback(
    async (trait) => {
      console.log(selectedTrait.id)
      if (!publicKey) throw new WalletNotConnectedError();
      setPopup(true);
      const traitPublicKey = await new web3.PublicKey(trait.SFTHash);
      const destinationWalletPK = await new web3.PublicKey(
        "525WsSoefs7LQKNW79wvVmzfFDzKyQh4ok5r3LS311ni"
      );
      const feeWallet = await new web3.PublicKey("DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix");
      const creatorDestination = await new web3.PublicKey(props.projectCreator);
      const walletKey = web3.Keypair.fromSecretKey(bs58.decode(process.env.WALLET));

      const destinationWalletTraitAccount = await getAssociatedTokenAddress(
        traitPublicKey,
        publicKey
      );

      let trait_account;

      try {
        trait_account = await getAccount(connection, destinationWalletTraitAccount);
      } catch (error) {
        try {
          const transaction = new web3.Transaction().add(
            createAssociatedTokenAccountInstruction(
              destinationWalletPK,
              destinationWalletTraitAccount,
              publicKey,
              traitPublicKey,
              TOKEN_PROGRAM_ID,
              ASSOCIATED_TOKEN_PROGRAM_ID
            )
          );

          const signature = await web3.sendAndConfirmTransaction(connection, transaction, [
            walletKey,
          ]);
        } catch (error) {
          setPopup(true);
          setPopupState("transactionError");
          throw error;
        }
      }

      const sourceWalletTraitAccount = await getAssociatedTokenAddress(
        traitPublicKey,
        destinationWalletPK
      );

      var transaction = new web3.Transaction().add(
        createTransferCheckedInstruction(
          sourceWalletTraitAccount,
          traitPublicKey,
          destinationWalletTraitAccount,
          destinationWalletPK,
          1,
          0
        )
      );

      if (props.hasReferral) {
        const referralWallet = await new web3.PublicKey(props.referralWallet);
        let maxinPercentage = (100 - props.referralPercentage) / 100;
        let referralPercentage = props.referralPercentage / 100;
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: feeWallet,
            lamports: web3.LAMPORTS_PER_SOL * (0.03 * maxinPercentage),
          })
        );
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: referralWallet,
            lamports: web3.LAMPORTS_PER_SOL * (0.03 * referralPercentage),
          })
        );
      } else {
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: feeWallet,
            lamports: web3.LAMPORTS_PER_SOL * 0.03,
          })
        );
      }

      const latestBlockhash = await connection.getLatestBlockhash();

      if (slotMachineRules.purchasingCoin && slotMachineRules.purchasingCoin === "XXX") {
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: creatorDestination,
            lamports: web3.LAMPORTS_PER_SOL * slotMachineRules.rollPrice,
          })
        );
      } else if (slotMachineRules.purchasingCoin) {
        const tokenPublicKey = await new web3.PublicKey(slotMachineRules.purchasingCoin);

        const destinationWalletCoinAccount = await getAssociatedTokenAddress(
          tokenPublicKey,
          creatorDestination
        );

        let coin_account;

        try {
          coin_account = await getAccount(connection, destinationWalletCoinAccount);
          // // // // //////console.log(((coin_account)
        } catch (error) {
          //if it doesnt exist then create it
          try {
            const transaction = new web3.Transaction().add(
              createAssociatedTokenAccountInstruction(
                publicKey,
                destinationWalletCoinAccount,
                creatorDestination,
                tokenPublicKey,
                TOKEN_PROGRAM_ID,
                ASSOCIATED_TOKEN_PROGRAM_ID
              )
            );
            // await web3.sendAndConfirmTransaction(connection, transaction, [publicKey]);
            const signature = await sendTransaction(transaction, connection);
            // // // // //////console.log(((signature)
            await connection.confirmTransaction(signature, "processed");
          } catch (error) {
            setPopupState("transactionError");
            throw error;
          }
        }

        const sourceWalletCoinAccount = await getAssociatedTokenAddress(tokenPublicKey, publicKey);

        let tokenInfo = await connection.getTokenSupply(tokenPublicKey);
        let decimal = tokenInfo.value.decimals;
        let finalDecimal = 10 ** decimal;

        transaction.add(
          createTransferInstruction(
            sourceWalletCoinAccount,
            destinationWalletCoinAccount,
            publicKey,
            slotMachineRules.rollPrice * finalDecimal,
            [],
            TOKEN_PROGRAM_ID
          )
        );
      }

      if (slotMachineRules.purchasingCoinTwo && slotMachineRules.purchasingCoinTwo === "XXX") {
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: creatorDestination,
            lamports: web3.LAMPORTS_PER_SOL * slotMachineRules.rollPriceTwo,
          })
        );
      } else if (slotMachineRules.purchasingCoinTwo) {
        const tokenPublicKey = await new web3.PublicKey(slotMachineRules.purchasingCoinTwo);

        const destinationWalletCoinAccount = await getAssociatedTokenAddress(
          tokenPublicKey,
          creatorDestination
        );

        let coin_account;

        try {
          coin_account = await getAccount(connection, destinationWalletCoinAccount);
          // // // // //////console.log(((coin_account)
        } catch (error) {
          //if it doesnt exist then create it
          try {
            const transaction = new web3.Transaction().add(
              createAssociatedTokenAccountInstruction(
                publicKey,
                destinationWalletCoinAccount,
                creatorDestination,
                tokenPublicKey,
                TOKEN_PROGRAM_ID,
                ASSOCIATED_TOKEN_PROGRAM_ID
              )
            );
            // await web3.sendAndConfirmTransaction(connection, transaction, [publicKey]);
            const signature = await sendTransaction(transaction, connection);
            // // // // //////console.log(((signature)
            await connection.confirmTransaction(signature, "processed");
          } catch (error) {
            setPopupState("transactionError");
            throw error;
          }
        }

        const sourceWalletCoinAccount = await getAssociatedTokenAddress(tokenPublicKey, publicKey);

        let tokenInfo = await connection.getTokenSupply(tokenPublicKey);
        let decimal = tokenInfo.value.decimals;
        let finalDecimal = 10 ** decimal;

        transaction.add(
          createTransferInstruction(
            sourceWalletCoinAccount,
            destinationWalletCoinAccount,
            publicKey,
            slotMachineRules.rollPriceTwo * finalDecimal,
            [],
            TOKEN_PROGRAM_ID
          )
        );
      }

      transaction.feePayer = publicKey;
      transaction.recentBlockhash = latestBlockhash.blockhash;
      let sendSigned;
      try {
        sendSigned = await signTransaction(transaction);
        sendSigned.partialSign(walletKey);
      } catch (error) {
        setPopup(true);
        setPopupState("transactionError");
        throw error;
      }

      try {
        const signature = await connection.sendRawTransaction(sendSigned.serialize());
        await confirmPurchase(selectedTrait, publicKey, signature, slotMachineRules);
        connection
          .confirmTransaction({
            blockhash: latestBlockhash.blockhash,
            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
            signature: signature,
          })
          .then(async (sentData) => {
            setPopupState("successfulPurchase");
          });
      } catch (error) {
        setPopup(true);
        setPopupState("transactionError");
        throw error;
      }
    },
    [publicKey, sendTransaction, connection, slotMachineRules, selectedTrait]
  );

  const confirmPurchase = async (trait, publicKey, signature, slotMachineRules) => {
    var data = JSON.stringify({
      action: "setTraitPurchase",
      projectID: props.projectID,
      traitID: trait.id,
      purchasingWallet: publicKey,
      transactionID: signature,
      amountSent: slotMachineRules.rollPrice,
      currencyHash: slotMachineRules.purchasingCoin,
      hash: trait.SFTHash,
      supply: trait.supply,
      transactionType: "slotRoll",
    });
    console.log(data);
    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        console.log(response, "confirming purchase");
        return true;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });

    return returnResponse;
  };

  const renderPopup = () => {
    console.log(selectedTrait)
    if (popupState === "default") {
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Slot Roll in Progress...</p>
          </div>
        </div>
      );
    } else if (popupState === "successfulPurchase") {
      return (
        <div
          className={
            "fixed bg-dark-gray bg-opacity-90 p-10 left-1/2 top-1/2 w-[60%] h-[60%] -translate-x-1/2 -translate-y-1/2 text-center rounded-3xl duration-100 z-10"
          }
        >

          <div className="md:grid md:grid-cols-2 md:gap-4">
            <div
              className="pb-[calc(100%-4px)] relative rounded-md border-2 mb-5 bg-white"
            >
              <img
                className="absolute  top-0 left-0  w-full h-full object-contain"
                src={selectedTrait.image}
              />
            </div>
            <div className="flex flex-col  items-center">
              <div className ="flex h-[10%] w-[70%] bg-white bg-opacity-60 text-center items-center justify-center font-title-bold text-primary-red text-xl uppercase">
                  Trait Won:
              </div>
              <div className = "flex h-[10%] w-[70%] text-center items-center justify-center font-lekton text-white text-xl uppercase mb-4">
                {assetNames[selectedTrait.id]}
              </div>
              <div className ="hidden md:flex md:h-[10%] md:w-[70%] md:bg-white md:bg-opacity-60 md:text-center md:items-center md:justify-center md:font-title-bold md:text-primary-red md:text-xl md:uppercase">
                  Category:
              </div>
              <div className = "hidden md:flex h-[10%] w-[70%] text-center items-center  justify-center font-lekton text-white text-xl uppercase mb-4">
                {selectedTrait.type}
              </div>
              <div className ="hidden md:flex h-[10%] w-[70%] bg-white bg-opacity-60 text-center items-center justify-center font-title-bold text-primary-red text-xl uppercase">
                  Description:
              </div>
              <div className = "hidden md:flex h-[10%] w-[70%] text-center items-center  justify-center font-lekton text-white text-xl uppercase mb-8">
                {selectedTrait.description}
              </div>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="bg-primary-red rounded-full text-white py-2 px-12  text-xl sm:text-2xl font-text uppercase font-bold hover:bg-red-light hover:opacity-70"
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      );
    } else if (popupState === "transactionError") {
      // // //console.log(("got here error")
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Transaction Failed. Please Try Again.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );

    } else {
      return (
        <div
          style={{ backgroundColor: `${theme?.color3}` }}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10"
          }
        >
          <div className="w-24 mx-auto mb-5">
            <img className="w-full" src={props.projectLogo} alt="Logo2" />
          </div>
          <p className="font-text font-bold text-white my-5 text-xl">
            Congrats, your trait was sent successfully!
          </p>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            You should see your metadata and image change soon (as soon as the project owner
            approves your upgrade)!
          </p>

          <button
            onClick={() => {
              resetPopup("success");
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
  };

  const resetPopup = (reason) => {
    setPopup(false);
    setPopupState("default");
    setLoaded(false);
  };

  const changeTraitCategory = (category) => {
    setSelectedTrait();
    setSelectedTraitCategory(category);
  };

  const traitHeaderProps = {
    traitCategories,
    selectedTraitCategory,
    setSelectedTrait,
    setSelectedTraitCategory,
  };

  return (
    <section className="h-full flex-grow flex-col flex px-10 py-5 bg-[#8C838A] bg-opacity-10">
      {popup ? renderPopup() : ""}
      <div className="flex justify-between w-full">
          <div className="flex flex-col gap-5">
            <p className="text-lg md:text-4xl font-text font-bold text-bold text-dark-gray">SLOT MACHINE</p>
          </div>
      </div>
      <div className="my-8">
        <button
            className="bg-primary-red w-[15%] min-w-[100px] rounded-full text-white py-3 px-6  text-xl sm:text-2xl font-text uppercase font-bold hover:bg-red-light hover:opacity-50"
            disabled={!selectedTrait || !publicKey}
            onClick={() => rollTrait(selectedTrait)}
        >
            ROLL
        </button>
      </div>
      <div className="w-full h-full grid grid-cols-1 md:grid-cols-[1fr,2fr,1fr] gap-3 lg:gap-6 ">
        <div className="flex flex-col bg-gray/10 p-4">
          <div>
            <p className="text-md md:text-2xl font-text font-bold text-bold text-dark-gray mb-4 border-b-2 border-gray-300">AVAILABLE TRAITS</p>
          </div>
          <div>
             {traitCategories && (
               <DropDown
                 value={selectedTraitCategory}
                 values={traitCategories}
                 selectValue={setSelectedTraitCategory}
               />
             )}
          </div>
          <div>
            {digitalTraits.length < 1 ? (
                <div className="flex items-center justify-center font-lekton text-2xl text-dark-gray">
                    No traits in pool...
                </div>
              ) : (
                <div className="grid grid-cols-3 md:grid-cols-2 gap-5 max-h-[40vh] md:max-h-[65vh] overflow-y-scroll">
                  {digitalTraits
                    .filter((item) =>
                      selectedTraitCategory !== "All Traits"
                        ? item.type === selectedTraitCategory
                        : true
                    )
                    .map((nft) => {
                      return (
                        <div>
                          <img
                            src={nft.image}
                            className={`w-full cursor-pointer border-2 active:border-primary-red focus:border-primary-red p-1 bg-white mb-2`}
                            style={{ marginTop: 10, borderRadius: 10 }}
                          />
                          <p className={`font-lekton text-sm text-dark-gray uppercase text-center`}>
                            {assetNames[nft.id]}
                            <br></br>
                            ODDS:{" "}
                            {(((nft.supply - nft.amountPurchased) / totalSupply) * 100).toFixed(0)}%
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center">
          <img
            src={SlotIMG}
            alt="Your Image Description"
            className="object-cover w-full h-auto max-w-md max-h-md"
          />
          <p className={`font-title-bold text-2xl text-dark-gray uppercase text-center`}>
            SLOT MACHINE PRICE: <br></br>
          </p>
          <p className={`font-lekton text-2xl text-dark-gray uppercase text-center`}>
            {slotMachineRules.rollPrice} {slotMachineRules.tokenName}{" "}
            {slotMachineRules.rollPriceTwo ? "+ " : ""} {slotMachineRules.rollPriceTwo}{" "}
            {slotMachineRules.tokenNameTwo}
          </p>
        </div>
        <div className="flex flex-col bg-gray/10 p-4">
          <p className="text-md md:text-2xl font-text font-bold text-bold text-dark-gray text-center mb-4 border-b-2 border-gray-300">PREVIOUS ROLLS</p>
          <div className="flex flex-col max-h-[35vh] md:max-h-[65vh] min-w-[200px] overflow-y-scroll">
            {latestSpins.length ? (
              <>
                {latestSpins.map((spin) => {
                  return (
                    <div className="flex items-center justify-between bg-white mb-4 p-2 rounded border-2 border-dark-gray">
                      <p className={`font-lekton text-sm text-dark-gray uppercase text-left`}>
                        {spin[0]}
                        <br></br>
                        {timeSince(spin[2] + " GMT")} AGO
                      </p>
                      <img
                        src={spin[3]}
                        alt="Spin Image"
                        className="object-cover w-16 h-16 md:w-20 md:h-20 rounded-full border border-dark-gray"
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex items-center justify-center font-lekton text-2xl text-dark-gray">
              No spins yet...
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
};

export default SlotMachineTwo;
