// // // // //////console.log((import { Link } from "react-router-dom";
import Logo from "../img/main-logo.png";
import { FiMenu } from "react-icons/fi";
import SideBarNew from "../components/SideBarNew";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useState, useEffect } from "react";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import * as web3 from "@solana/web3.js";
import { FaRegImage } from "react-icons/fa";
import axios from "axios";
import { MINT_SIZE, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, createInitializeMintInstruction, getMinimumBalanceForRentExemptMint, getAssociatedTokenAddress, getOrCreateAssociatedTokenAccount, createAssociatedTokenAccountInstruction, createMintToInstruction, getAccount, createTransferCheckedInstruction, createTransferInstruction} from '@solana/spl-token';
import { Connection, clusterApiUrl, Keypair, PublicKey, SystemProgram, Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { DataV2, createCreateMetadataAccountV2Instruction, createVerifyCollectionInstruction } from '@metaplex-foundation/mpl-token-metadata';
import { bundlrStorage, findMetadataPda, Metaplex, UploadMetadataInput, walletAdapterIdentity, keypairIdentity, findMasterEditionV2Pda } from '@metaplex-foundation/js';
import * as bs58 from "bs58";
import { otakuHash } from "../components/otakuHash";
import { traitHash } from "../components/traitHash";
import { collectionTraitData } from "../components/metadata";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import Logo2 from "../img/Logo-contorno-hombre.png";
import Template from "../img/initial_image.png";
import NotAllowed from "../img/not_allowed.png";
import RightBg from "../img/bg-image.png";
import NoImage from "../img/no-image.png";
import Backdrop from "../components/UI/Backdrop";
import pointer from "../img/pointer.svg";
import DropDown from "../components/UI/DropDown/DropDown";
import SlotIMG from "../img/slot-img.png";
import { P, SelectNFTBtn, UpgradeNowBtn } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import TraitsHeader from "../components/TraitsHeader";
import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';

const SlotMachine = (props) => {
  const theme = useTheme();
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("default");
  const [swapTraits, setSwapTraits] = useState(props.swapTraits);

  const [walletNFTs, setWalletNFTs] = useState([]);
  const [projectHashURL, setProjectHashURL] = useState(props.projectHashURL);
  const [ownedNFTDict, setOwnedNFTDict] = useState({})
  const [userNameDict, setUserNameDict] = useState({})

  const [projectHashArray, setProjectHashArray] = useState();
  const [userHashArray, setUserHashArray] = useState();
  const [projectNFTs, setProjectNFTs] = useState([]);
  const [finalNFTs, setFinalNFTs] = useState([]);
  const [metadataDict, setMetadataDict] = useState();

  const [selectedNFT, setSelectedNFT] = useState();
  const [selectedTrait, setSelectedTrait] = useState();
  const [selectedDigitalTrait, setSelectedDigitalTrait] = useState();
  const [selectedTraitCategory, setSelectedTraitCategory] = useState("All Traits");
  const [traitCategories, setTraitCategories] = useState([]);
  const [viewTraits, setViewTraits] = useState(true)

  const [allNFTs, setAllNFTs] = useState([]);
  const [traitNFTs, setTraitNFTs] = useState([]);
  const [traitNFTDict, setTraitNFTDict] = useState({});
  const [nftDict, setNFTDict] = useState({});
  const [digitalTraits, setDigitalTraits] = useState([]);
  const [userDigitalTraits, setUserDigitalTraits] = useState([]);
  const [nftCollectionName, setNFTCollectionName] = useState();

  const [userTraitNFTs, setUserTraitNFTs] = useState([]);
  const [allUserTraitNFTs, setAllUserTraitNFTs] = useState([])
  const [traitsLoaded, setTraitsLoaded] = useState(false)

  const [imageArray, setImageArray] = useState([]);
  const [metadataLink, setMetadataLink] = useState();
  const [readyToUpgrade, setReadyToUpgrade] = useState(false);
  const [upgradeMetadata, setUpgradeMetadata] = useState();
  const [upgradeAttributeDict, setUpgradeAttributeDict] = useState();
  const [upgradeError, setUpgradeError] = useState(false);

  const [sentStatus, setSentStatus] = useState("default");
  const [currentlyUpdating, setCurrentlyUpdating] = useState([]);
  const path = window.location.pathname.replace("/", "").replace("-", " ");
  const height = window.innerHeight;

  const [hasUpgrade, setHasUpgrade] = useState()
  const [upgradeCategory, setUpgradeCategory] = useState()
  const [upgradeNameDict, setUpgradeNameDict] = useState({})
  const [upgradeDict, setUpgradeDict] = useState({})

  const [nav, setNav] = useState(false);
  const menuClick = () => setNav(!nav);
  const [popupNFTs, setPopupNFTs] = useState(false);
  const [blur, setBlur] = useState(false);
  const [upgraded, setUpgraded] = useState(0);
  const [totalSupply, setTotalSupply] = useState()
  const [slotMachineRules, setSlotMachineRules] = useState({})
  const [eligibleNFTs, setEligibleNFTs] = useState([])
  const [currentlyReRolling, setCurrentlyReRolling] = useState(false)
  const [hasReRollAttempts, setHasReRollAttempts] = useState(0)
  const [initialFailed, setInitialFailed] = useState(false)
  const [currentPopup, setCurrentPopup] = useState("");

  useEffect(() => {
    setSelectedNFT()
    setReadyToUpgrade(false)
    setEligibleNFTs([])
  }, [upgraded])

  useEffect(() => {
    //console.log(('got here')
    var data = JSON.stringify({
      projectID: props.projectID,
      action: "setSlotMachine",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getlivetraits",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log((response.data.upgradeNameDict, response.data.upgradeDict, response.data.hasUpgrade)
        setDigitalTraits(response.data.digitalTraits)
        let traitCategories = response.data.typeDict
        traitCategories.push("All Traits")
        setTraitCategories(traitCategories)
        setHasUpgrade(response.data.hasUpgrade)
        setUpgradeCategory(response.data.upgradeCategory)
        setUpgradeNameDict(response.data.upgradeNameDict)
        setUpgradeDict(response.data.upgradeDict)
        setSlotMachineRules(response.data.slotMachineRules[0])
        setHasReRollAttempts(response.data.slotMachineRules[0]['reRollAttempts'])
      })
      .catch(function (error) {
        // // // // // // //////console.log(((error);
      });

    if (publicKey) {
      const getNFTs = async () => {

        // // // // // //////console.log((('got here')
        let nfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        //console.log(((nfts))
        setWalletNFTs(nfts);
      };
      getNFTs();
    }
  }, []);

  useEffect(() => {
    let totalSupplies = 0
    digitalTraits
    .filter((item) =>
      upgraded === item.isUpgrade
    )
    .filter((item) =>
      selectedTraitCategory !== "All Traits" ? item.traitCategory === selectedTraitCategory : true
    )
    .map((trait) => {
        totalSupplies += (trait.supply - trait.amountPurchased)
        // // // // //////console.log(((trait))
      })

    setTotalSupply(totalSupplies)

  }, [selectedTraitCategory, digitalTraits, upgraded]);

  useEffect(() => {
    if (projectHashURL) {
      // ////console.log((projectHashURL)
      var config = {
        method: "get",
        url: projectHashURL,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          // ////console.log((response.data);
          setProjectHashArray(response.data);
        })
        .catch(function (error) {
          // // // // // // //////console.log(((error);
        });
    }
  }, [projectHashURL]);

  useEffect(() => {
    if (walletNFTs && projectHashArray) {
      let projectNFTsTemp = [];
      let userHashArray = [];
      let userOwnedDictTemp = {}
      let userNameDictTemp = {}
      walletNFTs.map((nft) => {
        if (projectHashArray.includes(nft.mint)) {
          var data = JSON.stringify({
            projectID: props.projectID,
            nftHash: nft.mint,
            action: "setSlotMachineNFT",
            upgraded: upgraded,
          });
          var config = {
            method: "post",
            url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
            headers: {
              "x-api-key": process.env.GATEWAY_KEY,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config)
          .then(function (response) {
            // ////console.log((response)
            let metadataLink = nft.data.uri
            if (response.data.updatedMetadata){
              metadataLink = response.data.updatedMetadata
            }
            if (response.data.currentlyUpdating){
              setCurrentlyUpdating(prevArray => [...prevArray, response.data.currentlyUpdating])
            }

            var config = {
              method: "get",
              url: metadataLink + '?nocache='  + (new Date()).getTime(),
              headers: {
                "Content-Type": "application/json"
              }
            };
            axios(config)
            .then((results) => {
              // // // //////console.log((results.data)
              setOwnedNFTDict(prevValue => {
                return {...prevValue, [nft.mint]: results.data}
              })
            })
            if (response.data.eligibleNFT){
              setEligibleNFTs(prevArray => [...prevArray, response.data.eligibleNFT])
            }
          })
          .catch(function (error) {
              // // //////console.log(((error));
          });
        }
      })
    }
  }, [walletNFTs, projectHashArray, upgraded]);

  useEffect(() => {
    //////console.log((selectedNFT)
    if (selectedNFT && selectedTraitCategory){
        setImageArray()
        setReadyToUpgrade(false)
        setUpgradeMetadata()
        setUpgradeAttributeDict()
        setUpgradeError(false)

        let removeTraits = []

        var data = JSON.stringify({
          "projectID": props.projectID,
          "nftHash": selectedNFT['hash'],
          "slotTraits": digitalTraits,
          "action": "removeBlockedSlotTraits"
        });

        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };
        //
        axios(config)
        .then(function (response) {
          removeTraits = response.data.removeTraits

          ////console.log((removeTraits)
          let totalSupplies = 0
          digitalTraits
          .filter((item) =>
            upgraded === item.isUpgrade
          )
          .filter((item) =>
            selectedTraitCategory !== "All Traits" ? item.traitCategory === selectedTraitCategory : true
          )
          .filter((item) =>
            (!removeTraits.includes(item.assetID))
          )
          .map((trait) => {
              totalSupplies += (trait.supply - trait.amountPurchased)
            })

          ////console.log((totalSupplies)

          let weights = []
          let traits = []
          digitalTraits
          .filter((item) =>
            upgraded === item.isUpgrade
          )
          .filter((item) =>
            selectedTraitCategory !== "All Traits" ? item.traitCategory === selectedTraitCategory : true
          )
          .filter((item) =>
            (!removeTraits.includes(item.assetID))
          )
          .map((trait) => {
            traits.push([trait.id, trait.assetID])
            weights.push((trait.supply - trait.amountPurchased) /
              totalSupplies)
          })

          // //////console.log(((traits, weights))
          let selectedTrait = weighted_random(traits,weights)
          ////console.log((digitalTraits, selectedTrait)

          if (selectedTrait){
            var data = JSON.stringify({
              "projectID": props.projectID,
              "selectedNFT": selectedNFT,
              "traitID": selectedTrait[0],
              "assetID": selectedTrait[1],
              "action": "setSlotMachineFinalUpgrade"
            });

            var config = {
              method: 'post',
              url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade',
              headers: {
                'x-api-key': process.env.GATEWAY_KEY,
                'Content-Type': 'application/json'
              },
              data: data
            };
            //
            axios(config)
            .then(function (response) {
              ////console.log((response.data)
              if (!response.data.error && !("errorMessage" in response.data)) {
                // setSelectedTrait(selectedTrait)
                digitalTraits.map((trait) => {
                  if (trait.id === selectedTrait[0]){
                    setSelectedTrait(trait)
                  }
                })
                setImageArray(response.data.imageArray);
                if (selectedTrait.cosmetic){
                  let unchanged_metadata = {}
                  selectedNFT['attributes'].map((trait)=> {
                    unchanged_metadata[trait.trait_type] = trait.value
                  })
                  setUpgradeMetadata(unchanged_metadata)
                }
                else{
                  setUpgradeMetadata(response.data.metadata);
                }
                // setUpgradeMetadata(response.data.metadata);
                setUpgradeAttributeDict(response.data.attributeIDDict);
                setMetadataLink(response.data.metadataLink);
              } else {
                //////console.log((('got here instead'))
                setReadyToUpgrade(false);
                setUpgradeError(true);
              }
            })
            .catch(function (error) {
              // // // // // // //////console.log(((error);
            });
          }
          else{
            setPopupState("blockedTraits")
            setPopup(true)
          }

        })
        .catch(function (error) {
          //////console.log((error)
        })
    }
  }, [selectedNFT, selectedTraitCategory, upgraded]);

  function weighted_random(items, weights) {
    var i;

    for (i = 1; i < weights.length; i++)
        weights[i] += weights[i - 1];
    // // // // //////console.log((weights)
    var random = Math.random() * weights[weights.length - 1];
    // // // // //////console.log((weights)
    for (i = 0; i < weights.length; i++)
        if (weights[i] > random)
            break;

    return items[i];
}

  useEffect(() => {
    if (upgradeMetadata && upgradeAttributeDict) {
      setReadyToUpgrade(true);
    }
  }, [upgradeMetadata, upgradeAttributeDict]);

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const rollSlotMachine = useCallback(async () => {
    ////console.log((selectedTrait, upgradeMetadata, upgradeAttributeDict, selectedTraitCategory)
    let identifyingHash = randomHash(50);
    setPopup(true);
    if (!publicKey) throw new WalletNotConnectedError();

    const feeWallet = await new web3.PublicKey("DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix");
    const creatorDestination = await new web3.PublicKey(props.projectCreator);

    var transaction = new web3.Transaction().add(
      web3.SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: feeWallet,
        lamports: web3.LAMPORTS_PER_SOL * 0.03,
      })
    )

    const latestBlockhash = await connection.getLatestBlockhash();

    if (slotMachineRules.purchasingCoin && slotMachineRules.purchasingCoin === 'XXX'){
      transaction.add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: creatorDestination,
          lamports: web3.LAMPORTS_PER_SOL * slotMachineRules.rollPrice,
        })
      );
    }
    else if (slotMachineRules.purchasingCoin) {
      const tokenPublicKey = await new web3.PublicKey(slotMachineRules.purchasingCoin);

      const destinationWalletCoinAccount = await getAssociatedTokenAddress(
        tokenPublicKey,
        creatorDestination
      );

      let coin_account;

      try {
        coin_account = await getAccount(connection, destinationWalletCoinAccount);
        // // // // //////console.log(((coin_account)
      } catch (error) {
        //if it doesnt exist then create it
        try {
          const transaction = new web3.Transaction().add(
            createAssociatedTokenAccountInstruction(
              publicKey,
              destinationWalletCoinAccount,
              creatorDestination,
              tokenPublicKey,
              TOKEN_PROGRAM_ID,
              ASSOCIATED_TOKEN_PROGRAM_ID
            )
          );
          // await web3.sendAndConfirmTransaction(connection, transaction, [publicKey]);
          const signature = await sendTransaction(transaction, connection);
          // // // // //////console.log(((signature)
          await connection.confirmTransaction(signature, "processed");
        } catch (error) {
          setSentStatus("unsuccessful");
          throw error;
        }
      }

      const sourceWalletCoinAccount = await getAssociatedTokenAddress(
        tokenPublicKey,
        publicKey
      );

      let tokenInfo = await connection.getTokenSupply(tokenPublicKey)
      let finalDecimal = web3.LAMPORTS_PER_SOL
      let decimal = tokenInfo.value.decimals
      if (decimal == 0){
        finalDecimal = 1
      }
      else if (decimal == 1){
        finalDecimal = 10
      }
      else if (decimal == 2){
        finalDecimal = 100
      }
      else if (decimal == 3){
        finalDecimal = 1000
      }
      else if (decimal == 4){
        finalDecimal = 10000
      }
      else if (decimal == 5){
        finalDecimal = 100000
      }
      else if (decimal == 6){
        finalDecimal = 1000000
      }
      else if (decimal == 7){
        finalDecimal = 10000000
      }
      else if (decimal == 8){
        finalDecimal = 100000000
      }

      transaction.add(
        createTransferInstruction(
          sourceWalletCoinAccount,
          destinationWalletCoinAccount,
          publicKey,
          slotMachineRules.rollPrice * finalDecimal,
          [],
          TOKEN_PROGRAM_ID
        )
      );

    }

    var data = JSON.stringify({
      action: "transactionTemp",
      nftHash: selectedNFT['hash'],
      metadata: upgradeMetadata,
      attributeIDDict: upgradeAttributeDict,
      projectID: props.projectID,
      metadataLink: metadataLink,
      identifyingHash: identifyingHash,
      traitHash: 'none',
      imageArray: imageArray,
      upgradeType: "slotMachine",
      secondaryHash: [],
      collectionName: selectedTrait.collectionName,
      sortedData: 'NULL'
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
    .then(async (response) => {
      console.log((response))
      try {
          const signature = await sendTransaction(transaction, connection);
          await confirmPurchase(selectedTrait, publicKey, signature, slotMachineRules)
          var data = JSON.stringify({
            action: "transactionConfirmSlotRoll",
            identifyingHash: identifyingHash,
            userWallet: publicKey.toBase58(),
            transactionID: signature,
            projectID: props.projectID,
            nftHash: selectedNFT['hash']
          });
          var config = {
            method: "post",
            url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
            headers: {
              "x-api-key": process.env.GATEWAY_KEY,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
          .then(function (response) {
            // // //////console.log((response, 'got here')
            connection
              .confirmTransaction({
                blockhash: latestBlockhash.blockhash,
                lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                signature: signature,
              })
              .then((sentData) => {
                setPopup(true);
                setCurrentlyReRolling(false);
                setPopupState('successfulPurchase')
              });
          })
          .catch(function (error) {
            setPopup(true)
            setPopupState("transactionError");
            // // // // // //////console.log((error);
          });

      } catch (error) {
          setPopup(true)
          setPopupState("transactionError");
          throw error;
        }
    })
    .catch(function (error) {
      setPopup(true)
      setPopupState("transactionError");
      // // // // // // //////console.log(((error);
    });

  }, [publicKey, connection, digitalTraits, upgraded, selectedTraitCategory, slotMachineRules, selectedNFT, imageArray, upgradeMetadata, upgradeAttributeDict, selectedTrait])

  const reRollSlotMachine = async () => {
    if (selectedNFT && selectedTraitCategory){
        await setCurrentlyReRolling(true)
        // ////console.log((selectedTrait, upgradeMetadata, upgradeAttributeDict, selectedTraitCategory)
        let identifyingHash = randomHash(50);
        setPopup(true);
        if (!publicKey) throw new WalletNotConnectedError();

        const feeWallet = await new web3.PublicKey("DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix");
        const creatorDestination = await new web3.PublicKey(props.projectCreator);

        var transaction = new web3.Transaction().add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: feeWallet,
            lamports: web3.LAMPORTS_PER_SOL * 0.015,
          })
        )

        const latestBlockhash = await connection.getLatestBlockhash();

        if (slotMachineRules.purchasingCoin && slotMachineRules.purchasingCoin === 'XXX'){
          transaction.add(
            web3.SystemProgram.transfer({
              fromPubkey: publicKey,
              toPubkey: creatorDestination,
              lamports: web3.LAMPORTS_PER_SOL * slotMachineRules.rollPrice,
            })
          );
        }
        else if (slotMachineRules.purchasingCoin) {
          const tokenPublicKey = await new web3.PublicKey(slotMachineRules.purchasingCoin);

          const destinationWalletCoinAccount = await getAssociatedTokenAddress(
            tokenPublicKey,
            creatorDestination
          );

          let coin_account;

          try {
            coin_account = await getAccount(connection, destinationWalletCoinAccount);
            // // // // //////console.log(((coin_account)
          } catch (error) {
            //if it doesnt exist then create it
            try {
              const transaction = new web3.Transaction().add(
                createAssociatedTokenAccountInstruction(
                  publicKey,
                  destinationWalletCoinAccount,
                  creatorDestination,
                  tokenPublicKey,
                  TOKEN_PROGRAM_ID,
                  ASSOCIATED_TOKEN_PROGRAM_ID
                )
              );
              // await web3.sendAndConfirmTransaction(connection, transaction, [publicKey]);
              const signature = await sendTransaction(transaction, connection);
              // // // // //////console.log(((signature)
              await connection.confirmTransaction(signature, "processed");
            } catch (error) {
              setSentStatus("unsuccessful");
              throw error;
            }
          }

          const sourceWalletCoinAccount = await getAssociatedTokenAddress(
            tokenPublicKey,
            publicKey
          );

          let tokenInfo = await connection.getTokenSupply(tokenPublicKey)
          let finalDecimal = web3.LAMPORTS_PER_SOL
          let decimal = tokenInfo.value.decimals
          if (decimal == 0){
            finalDecimal = 1
          }
          else if (decimal == 1){
            finalDecimal = 10
          }
          else if (decimal == 2){
            finalDecimal = 100
          }
          else if (decimal == 3){
            finalDecimal = 1000
          }
          else if (decimal == 4){
            finalDecimal = 10000
          }
          else if (decimal == 5){
            finalDecimal = 100000
          }
          else if (decimal == 6){
            finalDecimal = 1000000
          }
          else if (decimal == 7){
            finalDecimal = 10000000
          }
          else if (decimal == 8){
            finalDecimal = 100000000
          }

          transaction.add(
            createTransferInstruction(
              sourceWalletCoinAccount,
              destinationWalletCoinAccount,
              publicKey,
              slotMachineRules.rollPrice * finalDecimal,
              [],
              TOKEN_PROGRAM_ID
            )
          );

        }

        let removeTraits = []

        var data = JSON.stringify({
          "projectID": props.projectID,
          "nftHash": selectedNFT['hash'],
          "slotTraits": digitalTraits,
          "action": "removeBlockedSlotTraits"
        });

        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };
        //
        axios(config)
        .then(function (response) {
          removeTraits = response.data.removeTraits

          //console.log((response)
          let totalSupplies = 0
          digitalTraits
          .filter((item) =>
            upgraded === item.isUpgrade
          )
          .filter((item) =>
            selectedTraitCategory !== "All Traits" ? item.traitCategory === selectedTraitCategory : true
          )
          .filter((item) =>
            (!removeTraits.includes(item.assetID))
          )
          .map((trait) => {
              totalSupplies += (trait.supply - trait.amountPurchased)
            })

          ////console.log((totalSupplies)

          let weights = []
          let traits = []
          digitalTraits
          .filter((item) =>
            upgraded === item.isUpgrade
          )
          .filter((item) =>
            selectedTraitCategory !== "All Traits" ? item.traitCategory === selectedTraitCategory : true
          )
          .filter((item) =>
            (!removeTraits.includes(item.assetID))
          )
          .map((trait) => {
            traits.push([trait.id, trait.assetID])
            weights.push((trait.supply - trait.amountPurchased) /
              totalSupplies)
          })

          //console.log((traits, weights)
          let selectedTraitID = weighted_random(traits,weights)
          //console.log((selectedTraitID)

          if (selectedTraitID){
            var data = JSON.stringify({
              "projectID": props.projectID,
              "selectedNFT": selectedNFT,
              "traitID": selectedTraitID[0],
              "assetID": selectedTraitID[1],
              "action": "setSlotMachineFinalUpgrade"
            });

            var config = {
              method: 'post',
              url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade',
              headers: {
                'x-api-key': process.env.GATEWAY_KEY,
                'Content-Type': 'application/json'
              },
              data: data
            };
            //
            axios(config)
            .then(async (response) => {
              ////console.log((response.data)
              if (!response.data.error && !("errorMessage" in response.data)) {
                let tempSelectedTrait = selectedTraitID
                digitalTraits.map((trait) => {
                  if (trait.id === selectedTraitID[0]){
                   tempSelectedTrait = trait
                   setSelectedTrait(trait)
                  }
                })

                let tempImageArray = response.data.imageArray
                setImageArray(response.data.imageArray)
                let tempUpgradeMetadata = response.data.metadata
                if (selectedTrait.cosmetic){
                  let unchanged_metadata = {}
                  selectedNFT['attributes'].map((trait)=> {
                    unchanged_metadata[trait.trait_type] = trait.value
                  })
                  tempUpgradeMetadata = unchanged_metadata
                }

                // setUpgradeMetadata(response.data.metadata);
                let tempUpgradeAttributeDict = response.data.attributeIDDict
                let tempMetadataLink = response.data.metadataLink

                ////console.log((tempImageArray, tempUpgradeMetadata, tempMetadataLink, tempUpgradeAttributeDict, tempSelectedTrait)
                var data = JSON.stringify({
                  action: "transactionTemp",
                  nftHash: selectedNFT['hash'],
                  metadata: tempUpgradeMetadata,
                  attributeIDDict: tempUpgradeAttributeDict,
                  projectID: props.projectID,
                  metadataLink: tempMetadataLink,
                  identifyingHash: identifyingHash,
                  traitHash: 'none',
                  imageArray: tempImageArray,
                  upgradeType: "slotMachine",
                  secondaryHash: [],
                  collectionName: tempSelectedTrait.collectionName,
                  sortedData: 'NULL'
                });

                var config = {
                  method: "post",
                  url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
                  headers: {
                    "x-api-key": process.env.GATEWAY_KEY,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios(config)
                .then(async (response) => {
                  console.log((response))
                  try {
                      const signature = await sendTransaction(transaction, connection);
                      await confirmPurchase(tempSelectedTrait, publicKey, signature, slotMachineRules)
                      var data = JSON.stringify({
                        action: "transactionConfirmSlotRoll",
                        identifyingHash: identifyingHash,
                        userWallet: publicKey.toBase58(),
                        transactionID: signature,
                        projectID: props.projectID,
                        nftHash: selectedNFT['hash']
                      });
                      var config = {
                        method: "post",
                        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
                        headers: {
                          "x-api-key": process.env.GATEWAY_KEY,
                          "Content-Type": "application/json",
                        },
                        data: data,
                      };

                      axios(config)
                      .then(function (response) {
                        ////console.log((response, 'got here')
                        connection
                          .confirmTransaction({
                            blockhash: latestBlockhash.blockhash,
                            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                            signature: signature,
                          })
                          .then((sentData) => {
                            setPopup(true);
                            setCurrentlyReRolling(false);
                            setPopupState('successfulPurchase')
                          });
                      })
                      .catch(function (error) {
                        ////console.log(('error')
                      });

                  } catch (error) {
                      setInitialFailed(true)
                      ////console.log(('error')
                      // throw error;
                    }
                })
                .catch(function (error) {
                  setCurrentlyReRolling(false)
                  // setPopup(true)
                  // setPopupState("transactionError");
                  ////console.log((error);
                });
              } else {
                //////console.log((('got here instead'))
                setReadyToUpgrade(false);
                setUpgradeError(true);
              }
            })
            .catch(function (error) {
              setInitialFailed(true)
              throw error;
            });
          }
          else{
            setInitialFailed(true)
          }
        })
        .catch(function (error) {
          setInitialFailed(true)
        })
    }
  }

  const confirmPurchase = async (trait, publicKey, signature, slotMachineRules) => {
    //console.log((trait)
    var data = JSON.stringify({
      action: "setSlotTraitPurchase",
      projectID: props.projectID,
      traitID: trait.id,
      purchasingWallet: publicKey,
      transactionID: signature,
      amountSent: slotMachineRules.rollPrice,
      currencyHash: slotMachineRules.purchasingCoin,
      supply: trait.supply,
      transactionType: 'slotRoll'
    });

    // // // //////console.log((data)

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        //console.log((response, "confirming purchase");
        return true;
      })
      .catch(function (error) {
        // // // // //////console.log((error);
        return false;
      });

    return returnResponse;
  };

  const renderImage = () => {
    if (selectedNFT) {
      if (upgradeError) {
        return(
          <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full">
            <div
              style={{ borderColor: theme?.color1 }}
              className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
            >
              <img className="absolute  top-0 left-0  w-full h-full object-contain" src={NotAllowed} />
            </div>
            <SelectNFTBtn
              onClick={() => publicKey && setCurrentPopup('nft')}
              className="absolute top-5 self-center text-sm rounded-md text-primary-red py-1 px-2 border-2 shadow-lg hover:bg-primary-red hover:text-white "
            >
              SELECT NFT
            </SelectNFTBtn>
          </div>
        )
      } else {
        // <button disabled={!readyToUpgrade} onClick={()=>transferTrait()} className='absolute bottom-10 self-center text-sm rounded-md text-primary-red py-1 px-2 border-2 shadow-lg hover:bg-primary-red hover:text-white hover:border-primary-red'>SELECT NFT</button>
        return (
          <div className="flex justify-center mx-auto mb-5 relative rounded-md ">
            <div
              style={{ borderColor: `${theme?.color1}` }}
              className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
            >
              {" "}
              <img className="absolute top-0 left-0 " src={`${selectedNFT.image}?${new Date().getTime()}`}/>
            </div>

            <SelectNFTBtn
              onClick={() => publicKey && setCurrentPopup('nft')}
              className="absolute top-5 self-center text-sm rounded-md text-primary-red py-1 px-2 border-2 shadow-lg hover:bg-primary-red hover:text-white "
            >
              SELECT NFT
            </SelectNFTBtn>
          </div>
        );
      }
    } else {
      return (
        <div className="flex justify-center mx-auto mb-5 relative rounded-md ">
          <div
            style={{ borderColor: `${theme?.color1}` }}
            className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
          >
            {" "}
            <img className="absolute top-0 left-0 bg-white" src={NoImage}/>
          </div>

          <SelectNFTBtn
            onClick={() => publicKey && setCurrentPopup('nft')}
            className="absolute top-5 self-center text-sm rounded-md text-primary-red py-1 px-2 border-2 shadow-lg hover:bg-primary-red hover:text-white "
          >
            SELECT NFT
          </SelectNFTBtn>
        </div>
      );
    }
  };

  const renderPopup = () => {
    if (popupState === "default") {
      return (
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <div style={{ backgroundColor: `${theme?.color3}`}} className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-50">
            <div className="w-24 mx-auto mb-5">
              <img className="max-w-[45px] mx-auto" src={Loader} alt="loading..." />
            </div>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Upgrade in progress - please follow the prompt on your wallet.
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Once transaction is approved please remain on the page for confirmation (transactions
              may take a couple of minutes to confirm).
            </p>
            <br></br>
          </div>
        </div>
      );
    }
    else if(popupState === "blockedTraits"){
      // // // // // // //////console.log((("got here error")
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}} className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-50">
          <div className="w-24 mx-auto mb-5">
            <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
          </div>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            All traits available in this category cannot be upgraded with this NFT. Please try a different category or select a different NFT if you want to try upgrading with these traits.
          </p>
          <button
            onClick={() => {
              resetPopup('cancel');
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
    else if (popupState === "successfulPurchase") {
      let tempImageArray = [];
      imageArray.map((link) => {
        tempImageArray.push(
          <img className="absolute  top-0 left-0  w-full h-full object-contain" src={link} />
        );
      });
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-sm duration-100 z-50"
          }
        >
          <div className="w-24 mx-auto mb-5">
            <img className="w-full" src={props.projectLogo} alt="Logo" />
          </div>
          <div
            style={{ borderColor: theme?.color1 }}
            className="pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red"
          >
          {
            !(currentlyReRolling) ?
            <>{tempImageArray}</>
            :
            <img
              src={Loader}
              className="absolute  top-0 left-0  w-full h-full object-contain"
              style={{ marginTop: 10, borderRadius: 10 }}
            />
          }
          </div>
          {
            !(currentlyReRolling) ?
            <>
            <p className="font-text font-bold text-white my-5 text-xl max-w-[300px]">
              Congrats, you've won the {selectedTrait.traitName} trait. Your upgrade was sent successfully!
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white mb-3">
              {" "}
              If you were aiming for a different trait within this category you can now re-roll at 50% discounted fees by clicking 'RE-ROLL' or exit out to continue playing and lose your re-roll attempt!
            </p>
            {
              hasReRollAttempts ?
              <>
                <button
                  disabled={!readyToUpgrade}
                  onClick={() => reRollSlotMachine()}
                  className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50 mb-2"
                >
                  RE-ROLL
                </button>
                <button
                  onClick={() => {
                    resetPopup();
                  }}
                  className="text-2xl text-white"
                >
                  &#10761;
                </button>
              </>
              :
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-2xl text-white"
              >
                &#10761;
              </button>
            }
            </>
            :
            <>
            <p className="font-text font-bold text-white my-5 text-xl max-w-[300px]">
              RE-ROLLING...
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white mb-3">
              {" "}
              You are currently re-rolling for a chance at a brand new trait. Good Luck!
            </p>
            {
              initialFailed ?
              <>
                <button
                  disabled={!readyToUpgrade}
                  onClick={() => reRollSlotMachine()}
                  className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50 mb-2"
                >
                  RE-ROLL
                </button>
                <button
                  onClick={() => {
                    resetPopup();
                  }}
                  className="text-2xl text-white"
                >
                  &#10761;
                </button>
              </>
              :
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-2xl text-white"
              >
                &#10761;
              </button>
            }
            </>

          }
        </div>
      );
    }
    else if(popupState === "transactionError"){
      // // //console.log(("got here error")
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}} className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-50">
          <div className="w-24 mx-auto mb-5">
            <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
          </div>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            There was an issue in confirming the transaction. Please reload the page. If your NFT has been upgraded or appears in the currently upgrading section of our site you can ignore this message.
            If not you either  cancelled the transaction or the
            transaction was never processed. Please try again!
          </p>
          <button
            onClick={() => {
              resetPopup();
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
    else {
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-50"
          }
        >
          <div className="w-24 mx-auto mb-5">
            <img className="w-full" src={props.projectLogo} alt="Logo2" />
          </div>
          <p className="font-text font-bold text-white my-5 text-xl">
            Congrats, your trait was sent successfully!
          </p>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            You should see your metadata and image change soon (as soon as the project owner
            approves your upgrade)!
          </p>

          <button
            onClick={() => {
              resetPopup("success");
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
  };

  const resetPopup = (reason) => {
    // // // // // // // //////console.log(((reason, popupState)
    if (reason === "cancel") {
      setPopup(false);
      setPopupState("default");
    } else {
      // // // // // // // //////console.log((('got here')
      setPopup(false);
      setPopupState("default");
      resetSlotMachine()
      // resetTraits();
    }
  };

  const changeTraitCategory = (category) => {
    setSelectedTrait(0);
    setSelectedTraitCategory(category);
  };

  const chooseNFT = (nftHash, nft) => {
    let tempNFTDict = nft
    tempNFTDict.hash = nftHash
    // // //////console.log((nft, tempNFTDict)
    setSelectedNFT(tempNFTDict)

    setBlur(true);
    setPopupNFTs(false);
  };

  const renderNFTs = () => (
      <div className="mt-4 flex-grow overflow-y-auto">
        {Object.keys(ownedNFTDict).length > 0 ? (
          <div className="w-full grid grid-cols-2 xl:grid-cols-3 gap-5 xl:gap-12">
            {Object.keys(ownedNFTDict).map((nftHash) => {
              if (eligibleNFTs.includes(nftHash)) {
                if (currentlyUpdating.includes(nftHash)){
                  return (
                    <div class="">
                      <img
                        src={Loader}
                        className="w-full cursor-pointer border-2 border-dark-gray"
                        style={{ marginTop: 10, borderRadius: 10 }}
                      />
                      <p className="text-center py-2 text-gray-deep  font-text font-bold text-[12px] xl:text-2xl">
                        {ownedNFTDict[nftHash].name}
                      </p>
                    </div>
                  );
                }
                else{
                  return (
                    <div class="">
                      <img
                        onClick={() => {
                          chooseNFT(nftHash, ownedNFTDict[nftHash]);
                          setCurrentPopup("");
                        }}
                        src={`${ownedNFTDict[nftHash].image}?${new Date().getTime()}`}
                        className="w-full cursor-pointer border-2 border-dark-gray"
                        style={{ marginTop: 10, borderRadius: 10 }}
                      />
                      <p className="text-center py-2 text-gray-deep  font-text font-bold text-[12px] xl:text-2xl">
                        {ownedNFTDict[nftHash].name}
                      </p>
                    </div>
                  );
                }
              }
            })}
          </div>
        ) : (
          <P className="font-title-bold text-primary-red text-[24px] text-center mb-5 relative">
            There is no NFT in your wallet from this particular collection. <br></br>Please connect
            a new wallet and try again or switch the collection.
          </P>
        )}
      </div>
  );
  const traitHeaderProps = {
    traitCategories,
    selectedTraitCategory,
    setSelectedTrait,
    setSelectedTraitCategory,
    setReadyToUpgrade
  };

  const resetSlotMachine = () => {
    setSelectedNFT()
    setReadyToUpgrade(false)
    setDigitalTraits([])
    var data = JSON.stringify({
      projectID: props.projectID,
      action: "setSlotMachine",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getlivetraits",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // // //////console.log(((response.data))
        setDigitalTraits(response.data.digitalTraits)
        setTraitCategories(response.data.typeDict)
        setHasUpgrade(response.data.hasUpgrade)
        setUpgradeCategory(response.data.upgradeCategory)
        setUpgradeNameDict(response.data.upgradeNameDict)
        setUpgradeDict(response.data.upgradeDict)
        setSlotMachineRules(response.data.slotMachineRules[0])
        setHasReRollAttempts(response.data.slotMachineRules[0]['reRollAttempts'])
      })
      .catch(function (error) {
        // // // // // // //////console.log(((error);
      });

    if (publicKey) {
      const getNFTs = async () => {

        // // // // // //////console.log((('got here')
        let nfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        // // // // // //////console.log(((nfts)
        setWalletNFTs(nfts);
      };
      getNFTs();
    }
  }

  return (
    <section className="h-full flex-grow flex-col flex px-10 py-5 bg-[#8C838A] bg-opacity-10">
      <div className="flex justify-between w-full mb-4">
          <div className="flex flex-col gap-5">
            <p className="text-lg md:text-4xl font-text font-bold text-bold text-dark-gray">SLOT MACHINE</p>
          </div>
      </div>
      {
        currentPopup ?
        (
        <div>
          <div className="flex items-center justify-between">
            <p className="font-bold text-dark-gray text-lg xl:text-3xl font-text  relative">
              MY NFTs
            </p>
            <button
              className="font-title-regular  top-8 z-10 text-lg text-dark-gray uppercase"
              onClick={() => setCurrentPopup("")}
            >
              &#9664; Back
            </button>
          </div>
          { currentPopup === "nft" ?
              renderNFTs() :
              ""
          }
        </div>
      )
        :
        <>
          <div className="w-full h-full grid grid-col-1 md:grid-cols-[1fr,2fr,1fr] gap-3 lg:gap-6 ">
            {popup ? renderPopup() : ""}
            <div>
              {renderImage()}
              <button
                disabled={!readyToUpgrade}
                onClick={() => rollSlotMachine()}
                className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50 mb-5"
              >
                UPGRADE NOW
              </button>
            </div>
            <div className="relative flex flex-col items-center justify-center">
              <img
                src={SlotIMG}
                alt="Your Image Description"
                className="object-cover w-full h-auto max-w-md max-h-md"
              />
              <p className={`font-title-bold text-2xl text-dark-gray uppercase text-center`}>
                TRAIT ROLL PRICE: <br></br>
              </p>
              <p className={`font-lekton text-2xl text-dark-gray uppercase text-center`}>
                {slotMachineRules.rollPrice} {slotMachineRules.tokenName}{" "}
                {slotMachineRules.rollPriceTwo ? "+ " : ""} {slotMachineRules.rollPriceTwo}{" "}
                {slotMachineRules.tokenNameTwo}
              </p>
            </div>
            <div className="flex flex-col bg-gray/10 p-4">
              <div>
                <p className="text-md md:text-2xl font-text font-bold text-bold text-dark-gray mb-4 border-b-2 border-gray-300">AVAILABLE TRAITS</p>
              </div>
              <div>
                 {traitCategories && (
                   <DropDown
                     value={selectedTraitCategory}
                     values={traitCategories}
                     selectValue={setSelectedTraitCategory}
                   />
                 )}
              </div>
              <div>
                {digitalTraits.length < 1 ? (
                    <div className="max-w-[150px] mx-auto my-32">
                      <img className="max-w-[150px] mx-auto my-32" src={Loader} alt="loading..." />
                    </div>
                  ) : (
                    <div className="grid grid-cols-3 md:grid-cols-2 gap-5 max-h-[40vh] md:max-h-[65vh] overflow-y-scroll">
                      {
                      digitalTraits
                      .filter((item) =>
                        upgraded === item.isUpgrade
                      )
                      .filter((item) =>
                        selectedTraitCategory !== "All Traits" ? item.traitCategory === selectedTraitCategory : true
                      )
                      .map((nft) => {
                        return(
                          <div>
                            <img
                              src={nft.imageLink}
                              className={`w-full cursor-pointer border-2 active:border-primary-red focus:border-primary-red p-1 bg-white mb-2`}
                              style={{ marginTop: 10, borderRadius: 10}}
                            />
                            <p className={`font-lekton text-sm text-dark-gray uppercase text-center`}>
                              {nft.traitName}
                              <br></br>
                              ODDS:{" "}
                              {(((nft.supply - nft.amountPurchased) / totalSupply) * 100).toFixed(0)}%
                            </p>
                          </div>
                        )
                      })
                    }
                    </div>
                  )}
              </div>
            </div>
          </div>
        </>
      }
    </section>
  );
};


export default SlotMachine;
