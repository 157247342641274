import { Link } from "react-router-dom";
import Logo from "../img/main-logo.png";
import { FiMenu } from "react-icons/fi";
import SideBarNew from "../components/SideBarNew";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useState, useEffect } from "react";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import * as web3 from "@solana/web3.js";
import { FaRegImage } from "react-icons/fa";
import axios from "axios";
import { MINT_SIZE, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, createInitializeMintInstruction, getMinimumBalanceForRentExemptMint, getAssociatedTokenAddress, getOrCreateAssociatedTokenAccount, createAssociatedTokenAccountInstruction, createMintToInstruction, getAccount, createTransferCheckedInstruction, createTransferInstruction} from '@solana/spl-token';
import { Connection, clusterApiUrl, Keypair, PublicKey, SystemProgram, Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as bs58 from "bs58";
import { otakuHash } from "../components/otakuHash";
import { traitHash } from "../components/traitHash";
import { collectionTraitData } from "../components/metadata";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import Logo2 from "../img/Logo-contorno-hombre.png";
import Template from "../img/initial_image.png";
import NotAllowed from "../img/not_allowed.png";
import RightBg from "../img/bg-image.png";
import NoImage from "../img/no-image.png";
import MysteryUpgrade from "../img/mysteryUpgrade.png";
import Backdrop from "../components/UI/Backdrop";
import Reload from '../img/reload.png'
import { P, SelectNFTBtn, UpgradeNowBtn } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import TraitsHeader from "../components/TraitsHeader";
import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';

const MutationTails = (props) => {
  // // // console.log(props.setPage)
  const [page, setPage] = useState();
  const theme = useTheme();
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [walletNFTs, setWalletNFTs] = useState([])
  const [ownedNFTDict, setOwnedNFTDict] = useState({})

  const [popupState, setPopupState] = useState("default");

  // booleans
  const [fetchedWalletNFTs, setFetchedWalletNFTs] = useState(false);
  const [filteredProjectNFTs, setFilteredProjectNFTs] = useState(false);
  const [loadNFTs, setLoadNFTs] = useState(false)
  const [popup, setPopup] = useState(false);

  // project variables
  const [userProjectNFTs, setUserProjectNFTs] = useState({})
  const [projectHashArray, setProjectHashArray] = useState([
    "12wdD1FJAoPVTqDM4FKF24GshfGaK9YCRoEsp5Z6GMcJ",
    "137tpAYmHdR3iWmSSwckHDC8VnVrzEvttW4rFGevr7ap",
    "14TooUiReWF5pqrR65fVdY3tE2ixi3YaBQsQwEv5e3bk",
    "1Ch7zBEsqecjLdUyJTongS8LgKCyXn9gJ9eGeKcyYoK",
    "1iqp6pZe5YnFEx7CkzLU8trZkxtVehRPWRaA2oNQ6AF",
    "24aBt8QFoeKpSPwfAzcVV88qyQhQ2qU72ryRDMzcPsgW",
    "26bWHwiimeYQP8pcPiV7KKKMFo9VxdFLQc2bsErDahuj",
    "26igreFrvaq9UftkoqvAffJ6YWjybbYngQX2YyjkoigH",
    "28VHHvc2UV2MSSqWaTcBGw97iAusHVeNS1ghL5LBoWKo",
    "28yQD36jtkcerKr4VbfDymQqUab5HtkyB6PrERUmaGAx",
    "294tYPyfiLzMdkCQh8keRCzvBiahM96iKWP14Gj86UV8",
    "2APXMCtL9bj9oaYk8TR5zdgUU6Ze1ghdb7VNeT9cS8A2",
    "2B3m7bHWwi7o1Q3ELSuvAwTEPdBNaaCwExDnY8cgfHxh",
    "2BmAfc7GUmA5ssZhatRmMF2181Q3Tc1vwswUgAAxv31m",
    "2CiZo4H4GKxpAdUaQTpYG4dMRVXt7oEvsyGYzBautkEJ",
    "2Db7nXTqA3LsJ8Jd1nVQkBBtVxjR1nbvM8Lnr3GGFBbJ",
    "2HBkY6dikqccNjxK7EfA1vjqccekcZ2zzABo4JdQPnyT",
    "2KFNMrDgnAPFWMUob3xCa3gWT31ABwJuvmsB4rRmbzEf",
    "2LZTkUm78XPKVBeDSSbaWBQakfwNeJGpAeyeDZnLQgJ4",
    "2MovPq8BT6XY59D3qCjVHYnTRDvUJLrvKmH9sn6rozpt",
    "2NgrU34oEQKfF1iJXicehi4x6NjqxcPWThb86zdadk2T",
    "2PpVS2VMB1fjbAmyk8UZZS5aYCrzjMHJZ1WZUQr2zVyg",
    "2QXPTwBFZ5aVdYqiwkDZ1hNcDhkfRv337vdkeTsHkaCX",
    "2RXfUGowLsQ3MocVmVemPMMc8ELvz7skedtvvt2BsiBF",
    "2STpRoDkeKTkWmTEHLwMm8CudbP9QKKaVPSykwQqCTuX",
    "2SbVGzeZfNr7rf3iCyXTV6LCNALskT71Kwq8JT3UtV4u",
    "2TD9BrL5T8dGJZh6VYynfN2kWEjsefPdrf46kv4hCm5F",
    "2Wg2MV3T55an39zPVjKCpFEHAaHzFsqXUu2kWaayzx7d",
    "2XPFHn4Q8v4TUjHfX5jkZ1qy2B3rqbUPsM2nwCK24eXt",
    "2Y1fL4u8RN3ANGKMZAzMH5EVMGCVqMaxFqYCzH9yUgnb",
    "2YgG8JiL5vxbSSsPBAVFZLynABA3RMqeNS9KReqLKUgb",
    "2ZgjyQ2jCPkFRaTCzJ1dPYmdC5whsyaKKoB2kPpCvZR1",
    "2ZxAQoa6VsPxLVhP7CJHPxVxVHZtJZReNjEjcViK2MzK",
    "2bTDoGmNYaajWkhZiKQCAxviQZp3EYJ8Jk5xcod2VjwU",
    "2bhbALWQN8sehJAJw3D9XYTX1yMrVBVKz35CW9tU2AyR",
    "2do5TKWPNdYGRkXXHLVaren3GbFb1wBDGQKfk3KLDw6u",
    "2fPNyoJysCgvJMtrEKsEfBfL4jZ3juJLekELguAwYs3F",
    "2h11BW6MBoeimzazaccGsSE8CtLyPqvCZRLZA3xdP1Xo",
    "2hDnqZ5bSdrkiWfSqvrsEBhYeun4Zq8yaGoKgmMz9t7Z",
    "2hih736BupSQ7LZLdeq4woftyrV8NPgf7z5oQYer6XZL",
    "2i67Vqt3eJqwKqkvmE27svKGRK9fbSna9EKivpgeifv9",
    "2ixK2Wtt6AfDHwy2UT5HfqXQme7Ku8hVptZKBi9t8cRK",
    "2j977BdqJW6iiGcXrKY1SvSR6x6RRdrsigHDQYQ6uKu6",
    "2jC1BsaNNyfEqrV6G8gGJpVqDmsEiTGGscwee5nVjvhL",
    "2kSo9pc4D2k8JzsoEqLa7Ns2jzqVsqzZ56oGkQHwRSTP",
    "2kU4Xwjs8AvN6m7PNzbFa4sH1C6uJxDWDcPC5SWohHpu",
    "2mU4o2CJyNzVY6yWS75wtGv64F1FDAERqiqe1bKC2LBd",
    "2n79T7v6xUrKxE7y6w9f2P3yMDo5VknbuAL9hAKk8CwF",
    "2nTQGyPojA1Pmzc2u5Krag93FWyL9YhGCcwtzuwjgytD",
    "2ntN7SaMHqjtEZTFY8EdLyTvSLugPGBhVCw4HEenwLtg",
    "2pP4ziQgjKxR4V4xKCmUTrU7nNTWoPJUMS1FuB7SE68Q",
    "2pkwKbfAMCX2EV5ThrDgWFFJ1LScYrxsFzuBHZMhRAGj",
    "2qtWNtg2Sfy8ysRuS334MBT6JdKt87vDCSLwBTS5vBEX",
    "2rfBk1h8RmKUyDuWAejmYB3Kai7WWVGWMX7fsicRZs3A",
    "2rhGbFE5BBMejjyS8YePppdd8qfWEHnp8oAFZoxfdFHX",
    "2s1JmdpjmVYMQW19qDYpXMRcvmEqgzHCFNZuPzyvotWN",
    "2sPT66o8KkzKoqUXR3qsAPFVYEXYkYR39XdoT5SAigNS",
    "2sZDb9Hrb7HJgxscn2513wFVUNthXq6wT3xQxUgG6g9y",
    "2t8iULaTphtV3XagHatMgq6scBB99PkcEcukq8bmH9Jp",
    "2tHveQ5hnvMWHfuBwJ2ZZsKjdbo3SssBqjVbtY5KUu5Y",
    "2vniQuhpsJ2puCV7rmbQv1S3gJcoZDZMoqCae4a1C8Bk",
    "2w8TPXCgpgdSUgCM4oxVvbmzrhKge1sV55uQ8LGeFfxo",
    "2wS2MHDatNWidb6yHZb4as1mJxo7Tj7fje7MPEYeKetm",
    "2x2apYCpo3iBMCqsq7ktaKaP3FsnXgebN5eE5Hoz1VRx",
    "2x9vGVLw9zn55K4ddRK2JwioJK7PWdbQgUbWh78dS8it",
    "2xsEN6GaxQDsjjnpL7dXJpdYsNeNsZhcBgGVxXKAyTYx",
    "2ytfsLVf9WdyDHS7KRp2DD8XRk984FpeL42434Cbd2Bq",
    "31mCgT2TC535gmCF17cXLcN9oLuSSGVsyAPojDkQoWVx",
    "322zuQzkBgJAgMTBXzMkJVn2a4q6JE4S2brVnPd2Vo7L",
    "33U8cdy6NcfkTWvPwb9SMie47taJpKp9EXsA2sjZxbL9",
    "33dpW9a6r3uvTFUqPwiuGU2hhxjzCiAcT3erM9WcjzQh",
    "342BW5axc13F2LkB7uGPBkyKr2KZqWjFFNs1hchu2M1k",
    "34pYZwB1YBw7tJpu4zg2fiakTN5DXtUACAuhRqRvsVP5",
    "36Scn9ZR9YoCegb9n2uQNr8yyJP1hstvoenXK1Z42Nwg",
    "36kzVyDj74d1CrSgw8MNw98qiuvwNMPZMmhkZYZBZynb",
    "37g4AccGfZaMM7jSJdSgAYkEfMNPSCnwjV8eXvrtss9u",
    "389tUuHQfuZXVNXwXXQxPQs7KNPyk9H5xY5q18P5tth7",
    "3ApF2tLKK86tLGTj3LGiBKBYTvMqAUbVF5nZ5FJxsUF3",
    "3Axf2qXseP8Z8aap12iowkVA44s9vA2ZZdFsg3SwFCHo",
    "3CqfkRQnMZMFC7kprfadTvjRER9BB4FUCYH6xTh1NLJ1",
    "3EqBoRUqeP68xUheT4TfFRAP8N6s2BwCCBjuMtLyVbeg",
    "3F8ReJxJvomF9QQ2JNg3U5sToQNNjaiAVAcDyMrXy6Ft",
    "3FYuJcF2YfSmiGUKkVy36CaqNsNCG9ioLtKNV7ja61y3",
    "3FabTiRWD5uc3DqfKeiAhjnsHXq7X7TrLF4LzsTyscaj",
    "3FcXnFw3FYuUkzgGyMrMF5g8RiseoEQfr8VL6u9y3ERx",
    "3G4cjFGXCRumh9ybu6ysUSxr9gxaJgzp74ngRLn9xcjK",
    "3G7CJGLqKzVcmANnvaFcwXahgLDLRh5BxSt4GC52jzMq",
    "3GhyXoSqovBbghfGa1hcSpfpg423A8cwS1t8Sujs9DVW",
    "3GwYWdYfWtJBAJRF6kguhneKCX5rKXHanDmeYVqu6Dax",
    "3H2hjGewSBhsUzymVbsnQ4MJMYwAC2JzLXCZVsF2wxrc",
    "3HzkVymwQUuD8iyQALTRM5oVZn2SekV8iDHSUeAVVnJ4",
    "3KAg4x81zPKYiv5BU8hJYTy4AgmrF1p1UBZjTfca9Dav",
    "3KNYrfZUaxbEjiaspXFnPz61oErJNnqGc5GEZDjUFaff",
    "3L644Z4qzE3Gw2pWtuUZ1Kk6je97LeLxCD5CQnaPNPuX",
    "3MJXVj1DXPZLB6QUVTAJY6q58u9DRa3DQ6iV1hn7JdNq",
    "3NLj7Jsj5PwkMQkwSbRVM4ngPjYRoZqQNkgbCQcQj5c9",
    "3NamP1KPDezp3jTZvH7N8t1Grkj6YEZ5Vam2A66UCeEY",
    "3NfWskgWWzBbJchvCExTqt4tZGfmd1eUz6yXMrWZwuPn",
    "3PV2BoRFJdQYEx8m9KzkdRJCshZi2CmaEhVYHNx9f1Jb",
    "3RP6j7M9RAxyAcvqSpEGBHGeqWaWpoy6867A2iNvxg4F",
    "3SsKVTUELazx4PvyP2HfM8kSaZLwAEYSZ35pQXsDhvUt",
    "3VEnF6EBEAFGTArPgHafd2F4oQwY38EvsDJ3bv7UCL1e",
    "3VsCvwwJPi4vKnN1QUJdj4kfaURHGnPCCTTcdUNHpKTd",
    "3XFAyNM8ChCLanvvW4k3qphywCAkao5skFmhrMDUhTKj",
    "3acWVAaeT6Po4fGdCjodDXnevYayWbTJSUudSv5Vk6fK",
    "3bmGdn5pzXLZmrW2WPBCWVvveHXnzP5YjYiHSta2Mn9H",
    "3cNqGYammThK7yTuk8XVHzfKdiXWcM5Yoohe6WDDZMem",
    "3cUMRqy5H23PF14ZzZihzHEnV3bvoRTtSkt2doRqrXCL",
    "3dC5Ywug813742SPb8FAt7iPidpF7aYKx8mZvzbBQZ8v",
    "3dDJ7ugEiWhXPoycdsUgrW6rSHhKm3ff5x1r6kvz1ABW",
    "3e1iZCJUaJguV1QPLoVJXwWgwAdpXAxSRE8XstC4Mm56",
    "3fRw17JLyD6cuFTiYzK61ovKFkqqgw4Uz874wPUmU8Y5",
    "3fd8vqHXuoFmw6avq8rizr6qWSqtSC7ZPqKSD9Wu3pdS",
    "3grAkw7jfq6Ahw1UaNMTbpYPDtPTCdPae3h2FaFbgRFm",
    "3jEcUiuJuF4oPGMx8rgUoRinynxadnyy8zBXomyrfkxo",
    "3oNuNazhsCj7R1hTgBuFAHDLx5Dy4pjxD2QNbXpTJy56",
    "3onXoiwebipjHUZ5yvmi24284GH72hbd5wnb82uwsKCN",
    "3oxNAy3x38UtHfaqxaQQ1zf8fSaXY4eGCpSoTzm99uVe",
    "3oz6ifi2zConeBJJJvCKwiaKVpEGfXzUQjeaFF178pPW",
    "3pN7eJJhLEEatHQhX2bCaKdUWGsKufT4njyPbaXeCVER",
    "3q2GEwyVe4Xgq7g6uLQxbFf7Rz6VU2Bkw6789bQ7cger",
    "3rDFvDKbzttio3MUDPx5EG49fKTz6zrEyKo1P5ThPkCY",
    "3rDhpthssvs4kPbF5TLtKB7ThbLbQSSACGbMdezFaenW",
    "3v8Zjqy8k3KNkFFyGgsgrAFkg6gcboc5mpQBDKBTL1ae",
    "3vGPP7dD94hX8ZdqaH3bqDNdjuBV4v3tVogVPeiMLD4q",
    "3vi2qDLxq793fmNkfcWQf4LwiffE7cUkniJDDqjtUnwX",
    "3vk2YsZeQW6M6MzZjmacFthEGYoajrEhZAQWGHkA6ujt",
    "3wD8GEyNEvwEzo1q576BL6pAKzdHCSxbWt6urbfRTorb",
    "3yGJg8X6JW1cgtoeGGpw8KZLpBnzWR3sZv2VGWqe1std",
    "3yLFukzavLYqESmtnWJi7BApJr3nMnqxT5RJJqQcTua1",
    "3yf7jn727BRB6nA3xvyacQeXdAwCKK3Rh68sjTsnH2jY",
    "3yqEn8LinWJDQnEQwAGDzQDHVK2ZkJot6n493b2FThdd",
    "3zkqRvCvNFKLHt74R1p3VM9pi6K3UbxH5GDZd68het3y",
    "41NtaDH5JnFxmB8XjDxdusycUmBxjpKdCmaBqBHtyk2g",
    "42wAkrwHxS2hcLvXqGmtAuT1CyFWcQuwzVBxJzcVH7KL",
    "43BVKxz4UAdaYrCpTuqBvBTGSLekjMUZSMyxaCJEw61z",
    "43j3qT6s2PwMKY8XYSMtkbPe88AEaiCNJ2BUDbn2sGu2",
    "443KgbBnwqhMY3h89ahhhJgY9pmWZ6iDMe388GsnkfuV",
    "44k6sjBRxvjrFAg2H1Ye7rS58SwprZzaXwSc5aAR4cJ4",
    "44w3MXNp2wDYyfwfLWZS3FNTZDdG1rysRxYFKZgadTT9",
    "45vo9UA1WkpFsswZkaS9XbQ9CBRHajxe1JiQnhA759Fj",
    "46zDwAS4NPkW9VYz6CDbbLWuLmAD7jDhMDbjgvFyhSWn",
    "47YcV7iVFP2waDAS6RFtgPpgUmyM3pHv39cB8Jf64VqW",
    "48ENp7F1eanB8tThXQZQT8zih1nrLRG6jxbAvLVxk8Wr",
    "494J4nGKYAgzPvXU8DPwpw26bXwT3hMqkeMsRcqRTG41",
    "49dvHAqfNpjtZj82R53chiYcD1QD4HBJiYRpQRJsrgsW",
    "49nLfkHyDqnGQmTjxWY969qECNzowNQdMWUdHbtMHvHq",
    "4A6rjF8PZgjsBBS9wRfa8UNUB5xUdpcBLhELxD6EDanV",
    "4AgjrdogrPZV3zaMsvksVifXcjCaYL8d71fDFhUE6XBW",
    "4BmiZ1azMkmc5cb9SzYKt9nxvcc6Aqai1RUxufUeUWBN",
    "4C18sz3Nrf1FiRwE1ccWCUzVW7LVZwvimE7wXqp9zZiu",
    "4CDfv9Kkq42oTkP4PrECSPB4nbHM7MC8pb8neF1CXD5J",
    "4CZ4x6QRkxTJRRXKyjAHD1NGjVgNos7i4kSLGDeBMf7H",
    "4EDAAvqLzJFqeFYz1N4QBAz2jRegWhC6VZPMGhcEhNkm",
    "4GR3j1Nzmsom6mcxEP935JYoVKZUEMewfwUXDwpu6w3k",
    "4HKzPaGmHjKsacmMBEGGJSbtVVxcpGjE3dvk7hkgEonG",
    "4JqKrSbe3peULfFPk5Xwk1F8CZNsyC1wJu32sqQGWSCE",
    "4K2bgbz56BrSomaPegYRCj5SrkBhfTvsaNaeQs1rYB1V",
    "4KNy6Wtqe4aVKEvCBdK16cEizU477KqtxQaK8wWdYCCm",
    "4MvdogopWfLV7ew8Y2U1z5yW91ekV4Qt6JtwXPxcCCuu",
    "4Ng9WPYGHijUmRjxsWHRUXKPLYJyW5dxBrzYwJoyvYbP",
    "4PXaAZQvGTAKWeqGfivevYVfanTsxTBXdKzDQcdTqhJP",
    "4QXRuSjUpp161UA1mjHw7eqF2P74KRK5bCJ1NPDc1E6Y",
    "4Su1YgWQGhXsaazPjZL2FyTzMcRHHFrVh8TyUMY52Dt8",
    "4V6VojNTUBQasHsZiSwm48AxM6nyiu4sqhKnhJGPmfzD",
    "4Vt88fbTDZ7XCUB8s1o1UmLiL8457dwazLwJLJfuua8r",
    "4WSfjrET1JN6cNLbRLRiD3WZ4vtdpgSQrvAKbXxTJ4cC",
    "4Xh18uHV3XnsvArgjo88WKhrjpfLvf3nvtYWAdBs3kmR",
    "4ZrKYT4cipBfq2PygaQwfqEVkq3zMAkJx4n3aZVLkmW5",
    "4ZvLtJn3sdrVSRjYyXKZP3m1sSoeMH89to512TpcvK5h",
    "4aDTAFEW3hiBZi5cAMNaAhyN7gWW2516kULYe1L5tSjG",
    "4aNxahQPZpGc2b1ozKjdtzUxxuY8HcDEnbx7KpCacF6v",
    "4dNbDYQ6U9gCA94WQ865jCQXKjdmtLn3RB7xoqitWP1L",
    "4dnQkMt5y3i6ZM3Yot6wwxj1mmSTSgcFv9UzrmeCoNJw",
    "4eBJxouxShRxaR2WhLmbzjgz7n53D89ByMZS3EfYohsw",
    "4f8z4xajuGMQtNEfM8LjC9jmTieHaVcuhuoJyiKwJ1gp",
    "4g8sWaKA5hu1WrjF1CEExmtAY6j2a3NmD3tLdjYgWUuD",
    "4kYK2hCL4nHVBNHLLFGoRNT2PvVzK1PQkmgzeizAVyCK",
    "4ktQEXnZyG1JGjNwaoxQFHb3eNx9a7Cfu1ybfQBfk12g",
    "4pNb3UxdCbiVvxZVXttcYAVGzYt6NuyoeQYt2b3LcjPL",
    "4q8zuGiwptWam4ogjuByPLhhhn5KWNjVeyHn1Re4FaPZ",
    "4ri78oWWutK7cduaFTUKhZgMBPiS5n4SzjcE99D3kgaX",
    "4sw3zoXSLqPpUFggi6PrM7sJQPjtrQG1kUb2KtCybeYZ",
    "4tazSkxheb6TD9FGxbYEvDKXqRR7TwGSHs7JQ6V4NHJd",
    "4txKegbdR4waq8djFvfvm4rAATqxHgpV5yAiXgpyuv9K",
    "4vNTubvkZQXgdhKJXgXVgMczavDSGxTvaiJtdUpbrnoi",
    "4vQ8phw54cDWWbfcuJ4Tm7sYuKRqGkbLgYWxNeKJHZz1",
    "4w3uAF9NKBrnHxHZy84MQP6rwmpjUW14DLYRovWLSFd4",
    "4w4XPZByKKWo6j4ZJRnPpHiK9tAtSLFdbtaf6bNMdWm4",
    "4wQaaUY378bC5QhxxVH9bwoGkqyRw5ntGmaLoCD9vNdR",
    "4xj7hGNVmB7xpwFs69ANEtV6McEAYYv4h1JEjn1ntg1e",
    "4xmq3QWEVJpefqhxMzyC2vcZjg5WbRgB8Z4wNzZXKgg4",
    "4yjgBK78YsccUxeQwcKiG8adPG5kwt4JtdsofmSBTQCh",
    "4zKswKQBq1udngTQqH7AyZW4gG9ALAPHPjRZR23txjMQ",
    "51DgPz9E1VXTGvdDACU5EaT3fuH12BEhmgA4eeUQF8Rt",
    "51YcFGTZvsmFBJxmAEwbgMP7XpyJ2scFar9VALJotnFZ",
    "53VyosSp3jV9azw6azUx2tRWaEfsTkjMGZHPc6ic4SLN",
    "54JdxpdVPUggqr9MGnazanVuzij3JyiL7hBkWWxrQ3HD",
    "54PRrEdRMtjeTagXL3xKwWPqfHK5f3AMXB11wQi2fLDP",
    "55MAcG2G92gK7gTZ8uJgoRqoBUd3VKJ4aEB5hTxC1eUJ",
    "55ue7T3ojWWCFMHDFYHztPLmH3QRsp8H5LrbwQLuaqr8",
    "56F5coVwXh7cnao98nGLG22FhJQrM6v3kobW6cQw1Vke",
    "56WgGfLHKAoZmpDdyHA651CrZGjFBfCHguJE1aARBzZP",
    "57j7TUWDS9dhEmxfvHtNRSYn5C3gbX9Cfjk5bRx5hBvU",
    "59KFp5KER5G8kVCXqSrQ5xeCHkFDHZh8sCMQRs52VYij",
    "5AvytKH3R52rogpwdaqGZZDm4aimnZvaiYeC14xDv2g2",
    "5BVnrjPcJ264KG11ZuxrhxHbtu5WiRHbERq7K2VkQtdT",
    "5CNsFEHGUUqfpnLpMyFPVnWYWhxFQ3J5gHfQSFuDwsku",
    "5CY8dainJRQthvtCMEUJujuRjhmnqTG7XqK5pQ7GYoWi",
    "5GNSBMxKfgkvzKEXNrKmFAPrneqDKuKD3BXPA54ESr7Y",
    "5JafECFvr6YRboGrQgmbMwZ9QsC7YUMmJ4kHNtXgDZuR",
    "5LVT4W8VjjMRAuVCythzDyenFBnjyKUs9cwS5HAkiAmF",
    "5NHc5LaR4RMrkPTDut2ntgGUM8GbYvmko2drFQuRMnt",
    "5NPWSjsiqkkKizemim64zMABCU5DMLAdeW3dLr1drLmS",
    "5PZHBn4RjHJVqLKGixRpJem6P9hYR46y4D4XszJXPC4o",
    "5Px1yYXhSPJ6KYmACq3tdbqESPRMrCXwzkvjjnRkW7Pz",
    "5RNTmW6s8hUyDy5ccnMjakm5ZZxhZpUKaSqbTG5pKr1p",
    "5RnasQXqgeYHGyp4uMvsPnxu6mMWgZJzc8xkijHpuDMD",
    "5S8wrioxtLJbrqES6yGhKrM3QEtaqg23jkCCUdRg8dWN",
    "5SqRoGvsMPuCC6ywkv6czkvcTdwe4BZcdSnw5g64m36i",
    "5TEvsiirEmiiA6iZJt423SjDH6dcA6upzYqoXHvq5AxR",
    "5ZXNpagzVezYKmcNEpEB7fskRoeRe6af8XarBbkGemSF",
    "5a145ybkmpk7wTSyHza6o7Uq2yd5ewvc29kRUD5ww8t9",
    "5cQUtt8mRwKnui7SLyPfiQMZA4JLtYSoEuZft5gd3xD6",
    "5chKeWvvwHadiDck6pXz7UsdEZxVs5vUY4CusL9GB3Xm",
    "5deto2tv5pvnLn29G7KFWzuKAi4dyxC3VWQNjvyJThwq",
    "5eNS6KHAAJu562YhqnbCEECSfCGLrDQ119aDL3pzSGuV",
    "5eQryN2KDvmzyyokRViMSzXLCaMTQh68YYFUfbbcYbfV",
    "5fdrm1JPp1s9qrRurDdNWYqdmkTALLuDizeVUREykTUx",
    "5hJVv9r8QRjSYRAyRx2CqJ53xd7veSaZFNLpwj6s9qZD",
    "5hRiWwt7HeA1dcHcU8uazqutxeKT9QYbfKvUcgEbZ55v",
    "5hkSSE1FEAQ19SGnqEr8c1dquafmq1gaEcfhsu5ocf46",
    "5iaXA4LJovTce5h3ugc2dEWx5j3viVViEgwTBtmxN6AC",
    "5iniR9qfR3Bh3T8fErueyo9ZnjFqmN7rrRXXvmaYjw1Z",
    "5jLFgxBN2zFmomuPyjqBooewsLW9KBKvn3NpxuCtFNT2",
    "5mnLegiLWF3cE1mCHZsDowj94kLkUL5VzKDpBCZZHZtX",
    "5msx5FWjA7H5ZpX69TCzy46fQdqjCT8buSMY7aDM2zFr",
    "5nJjgk9D7D5d5iSV26T419gECRsfDaJAGa4q75f6B9uH",
    "5oToYFbayWpxxDDtzHVhgSDZJMwj3UdWSuscPaa6gcb6",
    "5oyFgbkgWAtSxApYjjCmPzSqJxN4naUU73Zhoeco75ny",
    "5pHwqkC9iD9QLUXnoU2DCf19bftSgmAJpyqpSVccrtEJ",
    "5qFz8vRZrtEsyBujkrfouUp5JwzJecZRUojtp9EpggVK",
    "5qzLJUm4H6GyTJgmURXk9aM6uhPexFVyfepjbxqKCEpP",
    "5t4g4GvKdqz4PFNV1HyaZHVJUAEpdKvW8hAmscHbkXVR",
    "5usT95ZCtncn5NHcpF6CWh24TmLyMaQw6Pt8JXPx7ivM",
    "5vV8nzBrA6TM7FTXp2nv5cjYFk3oK9A2acrF1H982pGC",
    "5w6LnQDvVGiBxE3cYSxo68gmUXVSYUPpndo2PwitdmLP",
    "5wjYzUuBzoCFjeWeXCoyBfeixjMCUxZHxghP1UBuB94Q",
    "5wrzMo3hKV9ANSdfRg5h8SC9v5HzJVXfKLnptXhmcx4a",
    "5yvS5HVEPVv7YFky7c6t3DEaDkaapgCYHRqihWaQR6TG",
    "62PHqfQsJF88B2DXqRyaQWWsRtnGjvxr2ZVCPN5NDFjz",
    "62ZpwxLv2TtA5QEz3kvwr7QvZEKJQPNyjDgYgDvQ35ts",
    "64oXXmYUHZj7PpQh8xvSybU5WeMoWCuMFnfTjcDuqeJV",
    "64rJgDy7fjBuTkZQW4edgBUnxFHhUHEacyjGP13xFvEV",
    "64yAfDnWPERoZXdUSjPWYwD4kdDStd2Co8hvDTT8s8ng",
    "67jKtqCDV34WQtnLpEap3pChi1F63gc4FrGeLxY26w8s",
    "67mS7sf1RNnK6eQqLNenzHujpWNpB6dbkYStbwYhCW6h",
    "67o9UnawrpPfywxtcEdvj1qfDP6nXAur3ZEoner6s81W",
    "67sGJJJuhaLAFDtVQaQaC98VnrAQU2MYvpfph6mc3e1Z",
    "67scbpby7m9Ff1fvFJU4gx942BBNqdA1Ce8ZE5SFvnYM",
    "6ASCMwDDZbEi9pif2VLvemP2TEm5ciNYp4ZYJWUP1XSx",
    "6CzWhqv9pRbofXNnRpU1kdzi2TME68zpghSG2W3CnZpB",
    "6DJJ9vZWxNSzh2i6d8Ke4fhB6eGhCtzyEREEa4mvR6BH",
    "6F1YxF2FWnzcgKPvQ5CxpQKvQau9QFKU6bBVYHzLuAip",
    "6FhcS9L5wea6wQL5djoEDSw2eFzSEg7YqXyq6kuRgUXd",
    "6FmwxKtPVMMZAaDJu7N3uDonEB7BDB9ShHPQuLGvGXLo",
    "6GtjsAx8STQoSBhd2XR3FgEupBTzLFAE2mjy6Mr88yfc",
    "6H138Lch3GkzcXi862HEm3PQ5yR9w7u3bXrGyrpGXnT4",
    "6Hd8nzGEVeosaob5coHhjFdsguS2TmjAPoYCMw3krkL7",
    "6K4fM5XXKKG4H2iSJUoUAZe45ufahxFttXQS2NQDqAdj",
    "6KBqctjhr38VTScCpKiWcZtcL7wkvHBZRaWFr5ZNxN4o",
    "6KMbBL7y3tX9hKwgRxvPfEWZfeKs3QaE2YweNJD9uRXb",
    "6L8h3y7MwZntj8VRmCLcGTmKcqDRtxXecEMtap5XTWex",
    "6LBdJErEK6scFfryixoq8Rug6KWnbzN4KTy54JdN93kf",
    "6Mee47E2qQpKDfjjMeyhmzmkivB4knhjvnZewxXd6W9",
    "6Msoq7Nn9bpYPR3oM4UzMsk6pD5fwrpgzUMHaJPGz83n",
    "6NPfH8RSRw7kboq8sgKexKfrhexqC6VqW2Eu2qU24eC",
    "6Puidq74mNEWfPY4hdczFdmSbcsquFtJxWc3kyAZVXi8",
    "6QCLi9yPjJvELRZmNUmb2amPAfYT3aHJiWcV2gTtpaFM",
    "6R9ppzick7opn5kMxmxGLRpGpkHcyJNgoCPXw3n2Gvb2",
    "6RW8RRYhXzApN1d2UhNFcQRhmqaEv3iJBpWTiuifrgay",
    "6SYwdzUBgYBgnn3y2nAS7odmFNZU8yGXJjinGoEay2xt",
    "6SjDo7Dm3VH3pnJJMyjRUs5SgdFXp9BuZ6DVtZ7yFKm8",
    "6UNk1cmYgxds9CxrspkRa4LnsmsUgKArToV1hopD2DaW",
    "6WTc6UBKsqYQcikr1Nh6GThRD9MUuNpWQCHCYteTtmHE",
    "6WZ9t6FSW1i9uE8FQDZr5djvnHKdMzB56rNU38JmnkJU",
    "6Wc1akU2vNjnpGiunqnBzNYTtcLxDYGuNNaMkg8NRswp",
    "6Wfn69zkkehqgTYAEnTQ1vSTGEq1USoPCybpL9zPXxRp",
    "6WvHwKBkDq9UwzUKD3EgG1syWRBYSEchpuwwrfqbM94x",
    "6X9ZX6waMLGwTPwdk9jmx7R46YaRq7LUWuL9yGFkWbgx",
    "6Y9MGqeHR49i2W8EaQTjJhdaRxhfbPGrKr6398j3DyCa",
    "6Z9Cn3r29VZmQVnrDsWU79T1W7NXShBTGtrMz88cUaMR",
    "6ZLN8peLQ23QH3JB7CG5vGpog6cAs2dZn56SjvQErYVw",
    "6ZeaELzRgbYJiPpHqThn2QvHoDZyMv6G3nBXoa1TKCV6",
    "6Zpxc5ZucUT4hAytTVApBV8jziB3HCBJdGVkpeNUtcQw",
    "6aFRFzj364oqF8X5xCqf2NnbwAFgascQQZjyQGPhgGhc",
    "6cRYKxCBGnzHPbnNY1eUjA2xEaqxPwPsNEiTDByohZqM",
    "6cbCLynMrmJmUuCVigmdxcN59AqqnNGVzWd4p5SCogjX",
    "6f2FQUvW4BrQikJ2ZYdtuxmG12g6h9QoyGW5ZArwgRe6",
    "6fAdmwW1p5Mc1SL38rGNziB2wURCVDxtjGKD1KE9kn4q",
    "6hAFJNRoTSz7hesrnvd9YQzDP9nhmkAMf3SZgR3fzXkS",
    "6iBT1mvmsyRsRGugBPJfiYb7jixY25DBQHE8KzJhbTx2",
    "6iSvhhXHkngwCTy5bDzkVX79GLHDn3Y6zumY35mYrqVU",
    "6kVVTYzvjfe2QPsUMpvLm8hNEjgbFF76qk1zboLXsdEk",
    "6kixbVigjd9mGL6SKZ1ju5oXGNf72H41qF1KVURWEEf7",
    "6kvYDfgD9hKXvcuK8EroBYhzjnbkn9z9XZxKgbqjNYSE",
    "6kx4zoq6oHfFzxaXs3PdsmfE6eE33c8ojJQpxWAaBPrj",
    "6nYtNeVScqmDTtmYd9r4Gjsg44SmaUSf5sMJQsY8NUnJ",
    "6nbj4zTpuh6vYJGedwi1xD5XTWv3572dvVrkkXDLUHhy",
    "6nnjng2dRo5p78xepAC3ehBQMBo7Eta2CJRnsCxS2mbm",
    "6p67ojdgSuWZQAS6pGTe8qvvWiyWvRaihASEzXnRseyq",
    "6rDCjmsxGyiJw5taoXW8GjWQ5MrRsDDG3MeCgzaxjghi",
    "6sL6tUTzUaJnbnuCCDGt8yfuhTqVZsiMD9dSMgxWDpnJ",
    "6uVtuBqtKxN6cb5jgJ68ZYj4ohsi6bk7o7w8VVH85Yoa",
    "6v5XGtYZoT37wELVvHAJCwZFNv3P4oXY7fshC1biCni1",
    "6vEPxNJ2NvfU2nRybziCHmraRHyHPcWGdLX38gGXjH3G",
    "6vR4FHtYBea2qtAH61fe9gCtYg5MHisU38gjGyvD7rzp",
    "6vqR4d2xuuBz3D8DpSdsb7vHodeYBpyo6FXLM1JfdS3D",
    "6wBRmqRZYqvPnsvpUXQ4bkVHVxiDyqesxWkbMtqSt2cm",
    "6wKevegX6Wn8wFhCyuoJbE4wvixcGBfZvttzdxKhaQ3o",
    "6woJwiDEVMVgZBZ6GVokcvGFBhvgoSPX4M25q6sYrm99",
    "6xCpt67PvsHpegYFH54qXnFKaLkey3R31XQo48Wvv29F",
    "6xSkYuuvi32JWDywiwQqTWfZA4HLZZmQsbq4fu2UkGfk",
    "6yfLgLJKfhhNjFTV7LYXvAL8B2BUrrrVMw1UFiFug7kz",
    "71BP6HQYaRDNVEgCcGrk4CNkNS7TTYgzkkJhx3VBKTWV",
    "729M4KkANPFcJoA57VN63wFVxMPiMB4cgpY98qM5QEvs",
    "72FxVQws7MUjGZ5xnuX3fxP5j2rfyYq9VckAmjbZC26N",
    "72jLFBqAhbH7AowdahJ668U7YwjPnc3CHEs1ENXZt9kB",
    "72pPBgBt7ept349wFMkHMnNYMDkhufCFN7Pfed4sE89y",
    "72suYQz1B2Lc49EjpB9kVsBfcSYFNxTJronyDfuwokBA",
    "75fzDUzQraNQMMQsiNjYyQm2ddL7h5cNeMcpeQw5XuxJ",
    "78BWytXeHJAcagA3w4KYdY4biw2DWvxCJAhcwgHvouj4",
    "78JR1aeyFXHJA2fLqntqRqbqcBMs8jZ5vtc1sHxwaYZ5",
    "79J5UgwNbZzD8nro6fdyrgWzLj3WEeaq6WoSCKNhB9rQ",
    "79hmYDdmuE8XJc7eQtuwqfNJxW3mdWHDdFdUNwG38utK",
    "7Av7wsJjmgDVSNDqHx9xFyb8zZoCzgpDPEK5t4evMpkp",
    "7C8KihELQvVhQEN43bpoXYrVdVzKXANVozjndj1HRXKW",
    "7CU3S8Gr3agCdjzVVo1eKdgT6eH5DfohBjWzUBcPqz48",
    "7FFzxPbFpFoHycYAVrLee2Zn8joFsKcdxdNeqz5g5k8K",
    "7FMzvZsGpzK82Xw4c8aA8tqqSdtHcVisb6SaXewLs2De",
    "7FdzanyKbGoiFGTt1TGcNSnnQgYyrgV3TnSwycv57RGG",
    "7FmEGgMzE1UKTqMihFD9NcL4PxR9EffreMvgkv8gyJuE",
    "7JGzfy3Kj7WsTgS5CN3kBFz9igh7jNmnCZLsUmTrGts2",
    "7JdCjd6781QUEz8SJJp1XSPxtS3ZwEX1d8kSQbLQgM6p",
    "7KbkYYBVHgnRjq33FW7VT3CQEh6iy4rEWMpFLRhVTQtb",
    "7LNVJoEDhXCVcyzX5rG58rQ7CeuKvGz3A5BbH5nzNWZD",
    "7MYYB86fEeEeTg7rb6BnT32ZHeSnHgf1847g2VPG5X8x",
    "7MwhB5QST4VB9swA52Dv1bCr3WA9iCCq5b7wti8Xc6ZT",
    "7RBaNhPoeJKwwqBvmDk5qsE7ksiRxSwMu67ZVcqn64xe",
    "7Rep3PLjEt18YYZ6zwmSbNtojg2eBVUZrk5gTBfB7Pfh",
    "7RgpsWYpZzosBWYZYNLwaWCgJzPKyktpGmieGozRNsyG",
    "7S6gTgqNi6ka671fFeSTjyzTFSxvU3mQCQPCNZ6R4WPb",
    "7SuVETUh1KWf82Q8pm17sg4hYAv4Lq9y6De1ZWEMizhj",
    "7TXg616gv9YoJXSyXaR5Tt3m9djLPsNfc1GYsrw93H6Y",
    "7TsnAJWNDMCHLWNQBqu9V2NxNn48nFeTxuXr1gyfLDYn",
    "7UbrJvsdJoJAFVG7WAe8QNogDnaLwAvcrp2ThGj3inEX",
    "7VneyWEPX4yDSktup5ET7e4sZgr3pg3NRheHvhw5eDvh",
    "7W9w7X7gQCNuNz7XxhcamnMnFQKvunwWfGkPGpkvE4S7",
    "7WqQ2XFsTsPFuuJnhP2Pte4HU83RbfPmBeobn3GLwjWm",
    "7a63qFCTrDT1gueHn7arkgAquE9imnCfj182NoaYc3ka",
    "7aTGz9PYnPnVktjQ9cv7BtdTz2We9NHk8Fspq5y4nw62",
    "7bxno3Hc88H9Gh2MqDkVdpSLrJDb4mUwomzxB5AAmop9",
    "7etYqiHaUys2CQNzbVjbLHR2RW4UYvvLwZJajqF8L92F",
    "7frCR2F413uQyvRuvhycivs7MBqgepjcuTUaeHmYAUnA",
    "7gUqZmHMyFuMRkaCZsNqCUbMcBXtNCt85Xo9hpjKv3sN",
    "7inPH6r9jUjWxfrMo5qFnnoy7vQziRrVheLCY2SiuQHV",
    "7ip4MAefvGVs6SPd7LzJKkHESxV5eQ3RyryNrbuFBWKK",
    "7kRAZaUoL9ju9HrvAxCUQCdpYhZjD3NUTF2LFZu74S48",
    "7kr1BzdAuxUyjr1Ki6PLTmyubUbW8Pwf3FvN73NFmXTE",
    "7mGEKD2TaqNhQxZV4xbMoRKYTYuBTdHKUxEm5G612LiD",
    "7mVLcetXyLLc9aZdLJQs4udnc1RrNa7ABUeTJXyczug",
    "7mvigauoA8FiLSzyDqo2mYJHdkwMFgtV4y2HpEmXtduH",
    "7okcQRGaitg33GCYcvCVquJNr2CX1CmzfuSxFzALLiqB",
    "7pL1739LdKayFR7To6vqjNh6De8soRBYf23K7qUwBUWF",
    "7pqrL6dqcP54C7Vq3PCshVLF5jjDL6z2pX3iVHKu9i97",
    "7vV3VCZV848GVxDpV5HRDVjvoaom4xt9XXEEk8xgCPFE",
    "7vpv8A8XPrCx9tDkN7cCVsjB2keXUB2f2iD38EP2fRwB",
    "7z5GDBHUeukC79FVWfpG6KoZE75bvHoKuSKqwC7seKKy",
    "7zGuT8gF4GXU2dKkqoCksVDeoirvfUUJjXCYwV7gwq92",
    "7zNRQmkE4QGzPrAtN7oJ8VehzG3oYzdBNFK6MVBRF2HD",
    "81Ebf1Ztxv3oKVjV7Kz1ZHJ7EY9pUeWZActEWUZ2PW2S",
    "845CfdURtSkZBuFzJXBxLUr4QP5Lh5SUkvVhNmkmzbGe",
    "85TugDgtAj3KEgzvNh255rUwvDC9wKKakExvg6CNZHt",
    "86K55hNoMgtv2NHR1QXDDw3TkuRZuuHCsWJXqhcrQ9C5",
    "87n48ebeebiTyRhdf5WDQhcw9bDePX53disBkTXw9FeN",
    "889Y3VjxhQzU6iNewQ4TSrz4XDBLAC9pa7A8ZqbXxyNz",
    "89NWJvGmARYR23deFjTR9Zy2Vt3dzmrNWzjerkfHiE1X",
    "8A2Vk8Ug3gZ12KYDMFENdv6kLmbTq36qxQ1w8b6S4bYF",
    "8Bufops676J8SRUXJTFaAx15J34DjSsrAWfkzZ1qPEQj",
    "8DsK41qSTHypW1LK3fNT5zjLhXd9hZw5KB8orR2h6kCt",
    "8FooPU886T5rhZxZ3mxuJssh6hyAWRH9mSCTqnhtrRHL",
    "8GraRj4pBGDyG3x91ozQ7dqtKHrKS3BSRcLqythp9omT",
    "8NDxLEfi1imH7GBF56bNvzyRdQ1Fxg8V6WYzyKQ8199W",
    "8NUQvLxuCanEFFra2ibSoSt4YV9ahN2VxdPyxR7wS9pX",
    "8P8779XJurMMHHWjAC2D4aaSz6qahKdGrRX3cpy2vaB6",
    "8SuGhNFKeoPbK2PWPVNMiyNgJQMmhDfnd4B2VLUTukKV",
    "8ULo46a2MCZoTWp9YESss2Dah99EQgVYiYr7mjc8QgZD",
    "8UyDks9ku9BRW27cBwwMU6CXC7FCiWJMitp7SxM87QDy",
    "8Vp4CHSn5T58FVimuipopg5CANLYStPJYRN5DxsdqrRM",
    "8W4RWTQxN9GXrVy7tDYYVzeRtTxSTUfp3pkGfTyXBTrV",
    "8WJ1hxHd6ifhp9WuXQCRK3LegfzMiP7hHGwpY2DDSjh2",
    "8XSgT3E8QRfYUD1KFB2zSFiQTTP4Avbk3aDaqPVA4Mhn",
    "8Y9kuk4Czw9eL6aKER9uwxft3iHi8ZYuBDJF5AV2qAXH",
    "8YH9AH4rHrsz48RjhnHbEoqwcAskXodbGuCYujdkAM1C",
    "8c3rLK2HuLF5pbspka3dQmjKgTuVEPdVmGQyVKrmxvw",
    "8cDjVY8Cf8rCPo8mxR7fvuWqBQUZ6TXKjTZnmFXGG358",
    "8eeMtsDdF7v2bmVvs6zPMqhUYsPDGV1XCZ5pb1yK7nMQ",
    "8fap5hJ8hRe3oh9jUtCCwAo63jzKL3XHALLpxyyZqs68",
    "8gAmcLaKDfCcLNma6fZVbk6rSKAqNghVM2Ej3y3jp7o7",
    "8h1dZHSwjNX4FQyBWwK4EC5o8HEqqQytxBXyfZ8F1kLJ",
    "8h2jNGjxnQYzRsfdSArPkDQ2LjaC8jojvW8gi1g3bh1P",
    "8hYyxrYGbkWnCQ5VE5fYu4vt8fC2kBDMmGSF23KtEtJ",
    "8hmZqWY2zDARUt1e11cGdNrQRuHjkAEGJ2xXVqNUgVWN",
    "8iR48ABZ8r2PaAGUTGD5iWpFbWPEMw17TBURPFtTjhCs",
    "8ij5UAvqo1FQDmLcKkpygnpvmUY2WNaF1woumRw1nchu",
    "8inpgCrmsyLZX1TYXozontHY1wZ9QHf2biixP7qroTF",
    "8jA84cszDmTJqk1RyYycpVnL4PtZ6TvYLvGsrovGRWQC",
    "8jMmv6NAWhDqMXA3qBi4bNzRMarvbh5HqdbWRSonYHe1",
    "8jn7xtZCFSCTKHuqMJMnWcsN8uNPyf5XwbUuFLuko4z3",
    "8nZEnV8KHetgwssTxHqJgLHv441SfPapuVDCbF59QmkH",
    "8poxwC7GpMSho8h81DMTwZwPBr818Qs1U4FYXAgy1Jrs",
    "8tM3T8uWEfcy1NJtzNLNFnqeiJstHAvkeSY3bskfFa79",
    "8tT6nCo1jpPNn81vxbJR1MoyWVpr5ZqoxMAc8t9QBKQ2",
    "8vs4KD4o6nCk7ZyUG4nw11n4sjraxkcpGfCBMbb48asm",
    "8w9mg4jjg4TgwqZnSPgfTwFw9AT8M9KPMejBQVGZ2Mfi",
    "8wKpxMu6qzHphHosfLdJDNvkXFLnXVNtuneJF7hambjr",
    "8wwYJ74yjnSZaDpBHrmDnmK3o4dJJ8RSFDzASNWRRKdL",
    "8xgMYZWSXnu8rGsSBzHfcCzmsp7KwRbghiNgfe8JF1kB",
    "8xxjUswtn77CUvq6enF6QSuFRG17cH2FFTMfDS1kKGV6",
    "8yFBJFx1P5yaqyjsG8XcGGgEYUevgiFawVvptsGnH8eU",
    "8yfQPWp8iPNULzBDqeLLk22b5eFajDR9AqkLKrUzHV8q",
    "912bRb8gTL9qYJ1zosWUdJm6CFBWZ5v1NGCdAKw55hJD",
    "91fMofqudm9gpMdTZB6bWEHVaY5TjfihA6QpxHjGFE1b",
    "925MujgH2nVBN2HbFYuTQMMMf1m5fN1ba7Aj8jHyX6Mc",
    "934eCMnbeFbQ5zkkK1nEA67Z7fdF8LjL1TQXdmWbqaKB",
    "94vXMANzpYQDZkGavwcbqRCU3WrTx36ifUEto6RyvxAY",
    "958q73rkq62gSkfLZ31HPtMg9944ZQoqQbTJCDdpz4Ls",
    "95cs2NfWmocAKFpt6rwAbcTLtmCYouHtyqe7rT62ppp9",
    "95znMo9bbbKpwARz2GXmXf4HCQVRg5WwLQZ329ff86Hx",
    "962cpravTHUHXmNneV2sLb99CRNHhFJUchQYLy7nydDB",
    "96NjfwPVnDftsd1jrSEaCaXNW387VuA4jmTPTkcPeL3z",
    "98FRGRCgyNwp7RiBjwM1p5JcnKAmtSKaDuBnv7wB5eAo",
    "9AZiki7ViVg5UqtfvY411sKiUdiqxYQriDvfEFFLdT9m",
    "9AtyihaBrWKvvrRT5yDcBty6UBr7tQ1erurimtqfmAJH",
    "9B99NJ6QaA1Q39QTfMQ3wSG3Y5eSvg9Z9AMHUwCpbb8x",
    "9BFpFE81cgdyPLYQTCobezdGYyGef7ZCw9ZjwsA5NP3b",
    "9CNARLLktB919JKb43j3BigAFaNuzUoQaTDg9ZoZczCY",
    "9E2DWXEW4f8YeDfB5n6zAx1dtPHEwwdrT9wu8HchR8MN",
    "9E2zbzCVKX26aPwYU4U74xeKx2WVQnMJG4ps9BCvKaED",
    "9EENUQ5R8SNrov95d8sL7sC4WiV8tkWw64onYn3PovK6",
    "9Fm7P3Bd2KgsLBuBwJSdDdqgbFFGZN2zbUDaWUnfDh5D",
    "9Ho9njj65SAyizxgkGmGmgnUPLPLSAVxx9v6cDj3ukUi",
    "9JL7w8JqJs8Gv4nZj7k1wG5SGWxP5eCABDVFhdC2gNhA",
    "9Lxn9Eo3Bf584x98iPmrR1QbkTjJyGdTNkcnZ63ekxBB",
    "9LyirXKF8cmxiSSsn8rLkN4Ek6ZR2ax2pGok8VdjqaBb",
    "9MA9HGiYvPUXPWKjFsJYTDH4qRwSKfQe1e8RPdBWtCgp",
    "9MPavhvAjV88MckYca6TeY2VVd1WHqFRzSNZb5fHVWxL",
    "9N5AfUtzsbgewAyTNSNHgTpgD4FcUKfhnhFx8CFhWY4v",
    "9QDFwMB42LiJs7148BpeFFE5ciAafT6WXjNaa7r1CB2E",
    "9SbEuNEGACGM1HNQypGoQ2V9zyMJrcZfjabB96REvoaQ",
    "9T8wRBb9SX4pQ4GkRR9ej7MVfrdBXUzgyGaJmHF48mfG",
    "9UR8sdZHLJuFM5Tp1EuTwpNuYAB9umf9gLqNCFBJSQr1",
    "9UYkxC3mwPwXvagkpj39ev4czQm7MhQSH1AgMhrQ4Wt3",
    "9XPjyjNa9ajcob4p5DemwT6qW3ocxAW4vZfYjVmtDQfb",
    "9aVt4PhfUkC6dLyU5L7cnAPpcNoZEfhXG3ND2HRnPL6j",
    "9aYSLpFmUcry1j686qnsKReqCXAKXweA4fcaezZzLDQr",
    "9afpTwQDrphi6QiZg62yYC1CCHyPrP42sNJ8Gd6qGew8",
    "9ax11kakFGAt1d8KT3yE8x3JXALJkqMceRtByTMwnmqq",
    "9bJQFPRdT6suYAQy2P2NgaABzJDeQs3gmo5zbewZVz4g",
    "9c3oZsjfiGowrd1fEkzzVbDh6gcFhkSjF2RZuGSsm5uv",
    "9cFsKBMiLyMHhNS9222kUJpPJZvHLBbnw1duaT6Dy5Gn",
    "9cpqfDjc2EH8BUXFgbRVEid66w8U5XDMprMtFAb3gk79",
    "9cz8dk97XiRRV5W7gNeLQKjNqBwCYHgLF2pqvWQvvpgw",
    "9dqnNrLGCD4YGSLiym8o3Y3mEQdeBKmfubAJSrsXRLFV",
    "9f3nDXyJM5VSdR77F8vATbn5ysiRkNEZEAm3YMMWtD9U",
    "9fmL76zKTADo84V5bwHDLcxs4p3NimEpBqZkjiui8dSm",
    "9hKBz6T8i8WRc4NAjVp2W8ym9TDQzmBRRj5btBnXbtkw",
    "9kSvJoPnfQbCmM7h6yeBQvzfyo1rkhxqFAeZHGoLrTsm",
    "9mGEqy92mpPT6v6cM3JJ9hyy1GteRdNZWRHGebVo1MV",
    "9mgNdFmAjvVBKBUGan4K1pSbbcsnn2mtkGLwDGoQpXMT",
    "9oDdobu8wFRTbCKbyS1JYjGZjobmhcUK7drjt3yprThT",
    "9og3DuBgq8A5rSoWiT8vo8G2iKS9hLNkgwXeBRvAtVr2",
    "9oqTmhV9H4fY6MNzKiHGNSCU8eeSRveiYcTbXiGcyqTe",
    "9p3GVDFoT1eBL6otqg3RLmGPWWcJf7UR4B8RpCFF7Uah",
    "9rZN7HUQh1vKwkmUDSepEXXk9XpypsGhZWu5vpiWFcAL",
    "9rznjwB5Vcjk7EL6dbqgnP2D98VhQsdjyusL64Bzy3ck",
    "9rzzASaT2raqk69bvfxNQ6Hh9rpZ3bgpPtQcrYbgh9tG",
    "9sfM1ChAACNGgQWonXiKBHF6PQu6z2UczJ1wCL889cgi",
    "9tdahMXBTTEftQvcAd39c73paps9odUrAfVyshn4UAXo",
    "9vk5ALvGKs7a5ut7GVzkfQ7643VgRjuD5WnxTQn1TNe6",
    "9xiPWpQVxrCtWkryo7rZ1P8nphnx8Z7afThpgE6KSKqh",
    "9xkBw9NQkqLbDz21NkMx7d9UxJ8ykX7zMNg1z5yYfQgX",
    "9yChxmhM6tFaJXswtFGcoG3MLCTEwPeLMGKDz4JMWVam",
    "9yYBtWgX16h5nLxn9VYXj26G29CfViTtjNZhJvjWyCPW",
    "A2zvGZe2no2woRcRSwgikWyh7XKQmB2ANsDHRd3L7jwy",
    "A3oNSSWWtuhhSByFEAttGaVkxmiJiq6GUVxguptELyCq",
    "A4Xogoqi9u7d9cF8ENfHKaJzTW2Cxn3bGXFJ2usYBBZr",
    "A4qbWuNmepmSD4ogjVVBNbewXhzUssVhobMeLE67kege",
    "A7nhL7i4kMgvor9Y6UrXbzxAKcMm5h9MSKTZ47kxkjNg",
    "A8F2YRMPJJMznAG4L4uzQTfMS2coJXJcuM7LxEkg9JUn",
    "AB3fp18auG8XLnJJGaJNYPWrYiduoULcYH2A9nKdqX79",
    "AGP4tpRcABtryDvFhcnKMHvvCxPJqbm4aYDKTG5fc9pF",
    "AGxUbiBZ7ytQ1wxfhTxuBGpY2zns8VyPDHJq263pHXRq",
    "AH8Nmvpgu6MhnDiFikWFQC4bxQGoJQqZNfwqJ51rMFu8",
    "AJCARXY2p39zJ4UnX1SmPqb61PBGVzLksEmzkwboy643",
    "AJSK6mi9fJ9Cmn85pruogJ2KDpqRVFgpSHevhQjU1Dda",
    "AJcWncctaHWsu5ZN5ngZmDNkMP8BVqrWnT7p5UQ7TBGc",
    "AJf89qa8jy2XjNEeTTMHptjhBKsG4x1bwHzBkLkNC3bT",
    "AKbZ1HnVmTX8MqaLyBDwnKXLp751hStqWLV68vw7Ray4",
    "AKusyheDeUoSe83yFkVw9USUygdfHTgnETg1ACBKA3Cg",
    "AQ7HETaEbTBMBWouVD7MY4jX6gKAx83GozdycE14Vpst",
    "AQBQ1SEZM5oNdSn3udivCLQfiygkXhkFRkyJScpMp634",
    "AQZQM6hEnSJQ9XhKa2TesifYRwyqoqvBVQTr83oW7bKv",
    "ARJQk75mTnBARbEBjonyMRqrHRHhsnX8LFCDj2mfXozB",
    "ASUvhXfmTpNe5fhcnWckaDGAzjSTaaPnyTGdpyr3Bm2g",
    "AVASKpTzrBU5bmbpgNZdnLqo3Tf9yhJdS1jexjUUpCA6",
    "AVS81jYF7jYXRHA6RurZaeeJh7rrpoLK4Dcb1E2G9jmB",
    "AXMycie156mzup1b4NW2BJxNFRfDr2T87jFjHpaK2ngB",
    "AXcKXqrWm3RQLDw94eFiH5sKueRZbetKYbBTuokMm8cC",
    "AZgGXBo2hmVbdXEAUY2wLmhE2EuK1aRBw1h8daPVhPV7",
    "Aa7cXncmwGnRnVgHAMQBRrpfpLo8cqDqW9NESaFr7pN3",
    "AbgZ49b226aE2b57mcxWR1kJqwLDNJQwWc1bpoeKTHYA",
    "AcUQaDth2GZYzaaLf5DL5sSAruE4wnZnRst5VwfZ6eRG",
    "Ae2n3QCWE6CdoHZ5T2rkfRHdNKwvhd1ujyCq2vL8wDtq",
    "AfJQMFDFT3d2BFj6PnF9W24CZsCvq2DoyZMkReXrJRJm",
    "AfgoWzQc6Hm1EK6ARyvK8irx2hP6S5npPYxdaUTjB5nj",
    "AiDZKNAmTPRfQgHWn64hhRiXwUPUDSf5UCmcwiThqD3S",
    "AijpqCHEFBniAYCMry6dymSZerC5JWkeiN9pPhWEgvgr",
    "AjfvJgHRk1DKxRpq64JHbNtzF5bnR8Cz7kcLyjmegyaw",
    "AnegodSXGmyxUmQRSMZyHR4uCcBnaE2v8WXEdw1WZZ7F",
    "AotnXgsZYJ6ZxNtdgVVCjitap2Us2Gu8xRztKW9xLUd2",
    "Aq5WSPjGwRjwnYNFtwMcQ4oMffiDPd4HDc3HriXnw3GL",
    "Aqz14qXWfXWXdANvawsUdm12Gu1mJmEiCJJpBKFdijTF",
    "Ar1nVnomjYMretBp6QHcZ9aiQ8ZBCRLTJnW5kxY1QGNC",
    "ArYZb9KU1kmatMbSVQCGxSGq4QBD1ubTzoFmXp97csw2",
    "As59qFZFZAiczTRouE5LxtQMQUhzQwK6KVMD38Fs945Y",
    "AsXhVb8jNrrNv15gRe6DXctZdhuvY6nuZyEw91dENx9j",
    "Asn59qNg7PkkQ9MqdmfWtdrYbemXaiqwows8iQhojW4v",
    "AszkZBZUS8KUbY9srm2qEV86QBhzNBLyiMxxo99vcS3f",
    "Au8XCzrJP4RHEf7aSV22FKfTpQV9z9mgZtgwco6Zaff2",
    "AukB6EbU3ohKu3moXkQE4U1ARpJHbFXq4vWnbqWfQD8c",
    "Av3DMTkWmERDG2kaqQWjnnazEEveNGLZ8RS9btu4ZAPT",
    "AvNMUZZhz2LtoQdvpbmXWdc5rdMsGnXs9p5AQdFoSKvL",
    "AxfsU1HJ3aPSwFdZfcNo3h5rBxojvGmoU5qpA2TW4rX3",
    "AxvDyjzCorLSQyBDhiEF8hWmcaLpNVf3AMiHWfoKGVme",
    "AyQFAPN62fzpX2SRMcK5EMAZpWiY782M9ip3QexxLMTU",
    "AyYgHqoW68Bg2t36jP47zPVzRXncyev1xhvMvdoYh9mo",
    "AzCAptMXSuoaYyz379uUFejN83JTtcYo8pLm6u39WSZU",
    "B2KaRrAwB1rcpUWxtS7n7gVMccjJEFRaK2aNCzZq5c4W",
    "B35mzRvX22c7EjEkCXhfuHRHqsxrRoLhNghBEwCLj9KR",
    "B3KnxZm7QYGBrfuhWcfebc45f8TocauZSBY6E4FGLmSN",
    "B4ptZ8A8Rmd63PPYS8RY9eypiJ7bszPzNk2DYkFdYoAL",
    "B6U5Ea3Nz1f2FEa7Sp5nteCXhe3EDise3vz6o6WFsMz4",
    "B7UR5UQiWyKJtKr5UESZKi2hBkwz1YraiT4x7nrodBk4",
    "B7wZt5dmxBGtBg3UUpWraURVUEk57AnTgSkno7uy8byV",
    "B7xsJBQnaj1C1Q5B3F9Ejgdc866L78jVra7S4pFo7PUM",
    "B7yv3hrgVMtqaAfafnq7egaC16zLsfqur6tX7va6Db1",
    "BAEdLzJVGrC5AK6mGrfXGsFFRQQJNUz73KnQkWSAb6QU",
    "BAcEn7DFa3VuQzSp2XdbxsgYcnZSkdA4PGtm6D2kXTRP",
    "BAcT3V4G1npC4YLQv5dE4ueaNCeg9WqqN6ibzH93g8rY",
    "BBtjzeD8sAJ7QoA9UtwC4YJLd2RGhiGbJzTQtdSyitz",
    "BBwbQoRRyBVGjvzb7tv3MGQt5wNnp1hmGmntvaX4KD4F",
    "BDV3Xcbk2GUVSfEkCYxmXR6G974N39CpLT8dvJE2L8oK",
    "BE6umLfUfDJgesNh1UJPbMF6LVism34znn6j9QrVAzSx",
    "BELGnmZaMRPQmPtic1DkqNq7vrXE4z9jcFusYopistg7",
    "BJDHdvxSGBhEo5v16X5AKtZziHiQiqM1oeBNt6iqwj9L",
    "BJTPAfYakGNEDNdykMMA2TseFw72KHrZfB2UMHmPypxE",
    "BJTTiacDc9V7rCoGVNQd3SKibLN9uvxNrNWZxf1F8UFT",
    "BJZm4pehkx4bn9e9e6v7xvzvymjBP4CBCMBNmnWvDPTu",
    "BKWteZWQYFXtxHaPGq4YZjpHD6bQoMhR8u2msDzqMnbS",
    "BKpA7dr36ipES3gQF7cW3Mx7XwXbm2voa9Dy2At7Lw26",
    "BLXKj1H5wXxwdT2fad5uYCwiQfqhyZ5d76bmuj4Au7Pc",
    "BMChUq5EdZ5eo88KREPD4kyVSJHX1NturQc4GaB5aAvU",
    "BMeU5W7CKMPebBM5HU8hyteuVbcbz48Pr9CNbq7a5xb1",
    "BMy3g9NwUj31LZPS4wWv4Usf4ofbb2JxgUcP7e2SLzks",
    "BN2isLogRAxyWyK7mmS6BmXeZqxeMzJoiqinArazmWdo",
    "BNhjKbnqajV1FUYtFKGZX71DoxBowvzSHm8FMD7pvdNT",
    "BNw45HeqwJfZcaFdEZgBXe22mmUpxPfcwQbU5u59Tf3c",
    "BRABJk7WS8i7aT3gP4v9NExUyt2WABohyZJH58sgktJX",
    "BT13AyTWJfv9NZByVrjTu7J1f1h24xspqEbxsd6kHwpQ",
    "BTyxZUQzzYAZ7yHU1ieH3NuSFA9GEX1KNrYj4cENCRqM",
    "BUWyVd1Yhb2D7Vdx8AkHawvY5cd23pA31AuSKAxmrcEG",
    "BUbHEdWbbUVq7d7doupaob5bFsVRjZkHWs6o3bgicVnf",
    "BVYQp7GR9wfKyRi3BYsTpPmqiTsZ8FAgmpKdgdxN39u7",
    "BXSJHYRopcN5NRYhLw24PLs5PvT51Mh5CjAoTBbzGFxW",
    "BXVf8GHK4BW998QLP85huTGjnE17r1x9zM4qKrf25UUw",
    "BZ1NMjkrNWECEaBVUjqLupD3aEKcTXuVpnHjwJKk9bKx",
    "BapsqTU8ynLZoPEtyJgdPj9dBWEnCBCra1FfUY8ZGgXQ",
    "Bb2uFBfH6mMykY6txW4aGRei4zAcdedm4K4gWLLHFdTN",
    "BcdSAdBAMd6uFmGPKLm42MA1RmLuGve7cGkd2vDXzJoe",
    "BdKxgJWN1wpQokmTqQPCj5FtMRQxa2sKvrf3GSQnKztw",
    "BdncczjNib1kqBPKaJQKiML9QwqPNZcpjVTJiJz9Er4u",
    "BeZ5na8GAX8v9SLxY4ZMnk78dmCgu5PT3Sp7puzEdCoe",
    "Bf51aa98i5RgkLRwm1o1gXYWxGmtfg3wwgtZ4gn7KPmE",
    "BiJQGWDQAXuy38t1xYGzKeTvzDvXoaGJRe84i1dtYco1",
    "BiNgGASNpTV5ea2FLTRtLvkhnKnjpE9D6Jeoi6GKaiMJ",
    "Bm9gn4XvEU7jnE4ioS7DMMKZkEkDNoiLndwzRagtBxAq",
    "BmDXVVFEHod6YCujdC4ZXBVKDUjuBLRM1PEWGAGv5US1",
    "BmPxeMB7uM8Epo8nE2HCaS5Sv6BaSH2XTVtwKc1iKvn8",
    "BmxV6bWXAi471k8SeFz3ssmUpvTfPJcaRUVE6a7EY6J",
    "BoWC5nuGeU51JAX8VxYUTd3F2pC7T8DbfyqvsNXmAJmc",
    "BpAVHJLx8MNnBKvznY9jyXx33LHcsDh4fu3n332JjDYw",
    "BpeUmhBUs2TDDq4u3KjBEvn9RHkkF4qrZzXG1qHavoKY",
    "BqDEAgrJKvBvTenPf57Kf8HP75pn8cRP2nTqFZs7Undp",
    "BqKvL3TBehTBitSJQexQPYABdLQYZFqQQLZRaaNvGtY2",
    "Bs7GK8iqgpGxYPvSYpxpdVX376m3wkh9qgXfCoUm8FnB",
    "BtXiupTEjZSgdLmUVdZR3aFfm3vrnDteDGchAaF4nJme",
    "BtZjUdtTGfURkfLBLg8NJf3BvY1e5qixtSEbwurUJz6n",
    "Bvk3PDGHeHijgtQfDoB9EQdvNyQsArnDxjC5UCw8Av9B",
    "Bw6sLm3KfPgzfsAwxq8f6Nb8DK25BxNSHBxYFJKuvmPn",
    "BwMUsGFe2pxGuT1RUp6hGNaHRgC7ovrtkcZsTQX5K2Ae",
    "Bwy7z8fvya6Zph3dc43ZSXTDJLP6byDfhxJrfX4LLMfy",
    "Bx5GaEVKWUTkmJBCHoW4fUj1waBHCwnzLWUoHixF4GL8",
    "BzRhJhfQUNv4uKT2mBerN4vH8SEaE1mGV3xTJ9X6JKjN",
    "BzhSqgLxT8RzBTmhoqDvebpWencGdXnHaPACkaVkzjUV",
    "C1AjqgjD8c2d6eRyaVMNAmiYSg1kS1VVKkoDyihB8tzL",
    "C2MYTvYvdMw4VT33T3gK6nXkxvy5QPB9DpPji9RxFjAs",
    "C31rraCzyWFf3HjvcVfZ3s45To1hghKaxCfKUPuE1fFt",
    "C3mhNwDWU4RRVruh5Pekp8EVzeAdH3aDPUizyQA2ka17",
    "C4aVmUasAg4AA7fsrDEgVbVSUpFYsUFse7jeQuWiubus",
    "C5KLY8ASAjv2AjZHp3d4uoS3R2zeN3gDv8KhUCM1nzV",
    "C5hftaGs9rDr5hnqrgDX7AQCyBJqRWR9eZ6rLtwB1SFm",
    "C5qyaVDytMQYbevimuAejhKGdcuJyYM7qzH9RdGpwssd",
    "C61AtdimRC544cqP5UcXeaD9PKQXik52tof22qXyju1y",
    "C8veSkdiR3raqbGVoo3qHaptaYPHZgKW7NYZDF7w5hmF",
    "C9XWp2Zz6PbHShHnv8sFdttNeXTCmoqCA6HPsrMJtZTg",
    "C9mZhLFSwVfgAfnf9cNcwgMzecw1xAHGduUBWxbZMpT3",
    "CAVnGC9meiNNts2NKFwXSA4r1bigBFnsq7WceBd3JXNk",
    "CDLxmEX5YA29Bd4HCgC5mXu4biQmzLE549WwVEzvCSjq",
    "CDjxdLhLXbbEFLsVFLcRmeqzY8nDJwxCBDmbXY3yDgBv",
    "CEFaAwZjHuCLprihmhNzMaF9UpDiMQNQWYuVAoBq4gLV",
    "CEzMw2PEu3UX8GNcyve5Xq2ZJgfeoT8xYSydfKh1sW7i",
    "CGVJasFzfZnbpEySsYdoZ6Bpuf6FSP23kf7KpiFuwbkZ",
    "CGuNt95ME2ABndhNShEd58ryBPgAtfPBtLCfGnDRGezj",
    "CMCNh7tHBASp7rXFfyacKGDUfzfyuoiWsmP9xzFsXebf",
    "CPmvpX7VZYdduySTgBJqFVxRjpzinkTTs4gv8fd3L7pM",
    "CQ3w2y5fsXFhwkR5HoXBffMEBhR98mb8uCe4Xt5kTs3Q",
    "CQfy9NCM2i8YGhv8rEUUeGhhcKqFiWS6sz8Lygq9VNKj",
    "CRfvCHaYVx138bY5mLFQoEBSnwW5R6jYnT5dDqMKNJSJ",
    "CSKr2q22tftpC2R2gcGwjbiG555azhmNZTifUz6MW1J2",
    "CTZdoyH8JUz1yq5ReJRLv3pPN6rPbWs8Q5vuodsopuvH",
    "CUcusXqFGrJQzpB6fJ2Eg7KqVtWZde2c8ynsxsvv4kY9",
    "CUvor2GowgE7xWJZfmsqMNEo2rkKmsDtDoDBGWLGJ7wx",
    "CXG8KR8nLXTaniP5M4TnqvtTreApjvq4smeGxXgraVLt",
    "CXUd2Lb2bpvAMJadqgLTc6BCFVx61m75ZhQi3TaM2RMt",
    "CY3y4jVchwdVuNCtmVLZ2W9YfbAV5UhjV95QSuc85Kit",
    "CcsEzM9v35aQXb64J2WbmoCmA3DwyUsfq2etLuiJkgoG",
    "CeYmD46eUavfPxgfQ6D3yLdUGThkXjwb2SFk7ajpQmAw",
    "CfYsowAaNCYQGxjyLV5ZNYtGv6JwiaEQRKyLscyodGAR",
    "CfysBmWoqhmGgYxD5p6jTXhtSrRw495vwknqpNPEPVVS",
    "CgDLRZVdDvgxqA1JtDGHfGb2CyfTHfimgdNV5aXZhsH2",
    "Cin8sWwYLW4WavHGkrnog4sfnEu7oDCFVnj3Rj3TKMVz",
    "CjZ5XZZ24MB1zfrP34LXeTYyBm5Z1DCsM9jQVV65kLjz",
    "CjyXZRPKR7PDtFeeGBZsjwxcwDp8ZZwoM4DsFX7NzYHo",
    "CkUBHhj3XuLsXaZ6YCfLxNbUWYZgLVAY25RkTT45pSz8",
    "Cm2Kc6HdxGwuVwiGZpUwVRsxPmcxW6KBH5PUSQNqDdKy",
    "Cm4puBy2mVE7qaaceMBwMD6VGJzSfDLQx5jNYifPxMTP",
    "CmCEBYj7hLCU3P7oS4EMAxgpYewAKYgGMGy4u3WCSaes",
    "CmDAMfuAEmTpcKp6vemtPHhaACQFvrSvvL36r89U3XT7",
    "Cn8d9vWrYd35WAjqKqFACxAuKRKnH6cmss5k21fUcqNm",
    "CnGm8T2CP7ixwpofRajmqy21BcTXVVxeF8ABiXeExEQQ",
    "CnUjURVg7XwuNQ3gQaE7puzDHhXR3Dq6uNbFynYnKSXn",
    "CnavtqnhTaDFakvpw5iZgTTedBv9jMso5a1JQ6qDvWbw",
    "CnpLdEUzasXQn1yEFT5GiFxZg3jYxiR4BaiGUeLsjPoD",
    "CooZ5u61jBaW9tQAkA62MzYTVhGvvW6hn3ZTZi9p8Shu",
    "CoxcttXhJt5KmyF1gNzBSPgopxih8rJwRgzBntRnsjLu",
    "CpHej7yj9TN9HgbuuDCusu9rJotq27dekEG6WTzkZ8C2",
    "Cr8zAHw43FzX1gMfwgW7DMMJFrtEVMmUJNk5Dkq12fsc",
    "CwYJix6LyK4erHxPQANKqorwSiFkHx1reRbfs5CpVsx7",
    "CxpcXsUed24gwbt7sXC88tCBrcEn8hse8hyW9ZFEd2nP",
    "CyWxnA1ZP424TAtAVzU5ZqG4BnYTsLecwXV4JLYDRSGF",
    "D2bfJhHcyuuk1TT33sXrK6g3kvyvBsKoFM7GtEfXL7up",
    "D3Ae39E7ZZgoAXhwBtwSZeS8kuY2w93Pq8gJJWRPR5MK",
    "D3Q6H8uyjKvyf2jdMpy1TQHpNvN3vko8HJaGuS24mRUR",
    "D5htwqsjqH2PC541PnEJhdAfRZm9Wqrwv9CmWpcEnv5e",
    "D7NDV39EfAF69WdxNz5HHzXt81jXuTHJJ8WiYekjcBV3",
    "DBhUag64uFMc9w7nGcV34ykuy8QB7HN3JXunfbxGaUpM",
    "DCjABVSV3BhwUMJmuj1NgTP5x6NbQyTededb1mnmEDo7",
    "DDuuQ5qXEoNhfwUaZWUKzK5z5eA3kkGHMjGXUXNyDXoZ",
    "DERmZyJpNtG4G8UtHagCoVFNfA6ZeRN8VAFehWY2pv3m",
    "DFkDoQXLC7TSxPieG8xMhGorBBQXmBtbUGeKzFD7oQTD",
    "DFp8kku4bWz5NXmgWSmGbjntNwqRrLFgQC1nWECWTZQh",
    "DFuoCJG4zHeBFby2WneWPqc6uCkjhzbQ8RhrZPWGwzJB",
    "DG8axaR7mnNCdyPJsmGLTBGAfAmZoh2SCaPiW7GmavTu",
    "DGAvZKtPED7avYTL4NmdyFVRKVdAqJQ8Lxif5fvbBht4",
    "DGzmqnvAuWT3QQdTzWkRqaMHqCpb5GbndKL4psBD5ZTj",
    "DJyM1Q1SpMW6F8FPSUaQop5GBKNuT4gWzMAdjtJGFgpo",
    "DKayjb7kpLDZyzRKvLTMJx9oQwhsHWEjQNN4YBaEc7mC",
    "DKcqv4Zeb5pc5KrqhWnL4E94QJax3XTuqB4Hk8kpudZX",
    "DMdTsEYK52nCEMgYQ3ZGbbBx5Nn39AstDw25vfSvNY6u",
    "DNDAd4PH7eoVjR8pVMUtKqabQoHDPwppWMENusfS58vV",
    "DNNyV3bdNdFVJ4xTbq9VMey4by8bpfHSVcEFwofqQQiN",
    "DPXNwgQVKYk5Hd3LSbW2sgyjg578tk8bRsJVXHs52QRN",
    "DPqBjLQrkn5ZTsPd8LGgKWmuRBCunGKKc1BDGWenuph",
    "DPsCaja7QXcqWJCRxcSQorxX4XEUxkdGMupCJaWDKwPj",
    "DQPrTudaGMLjM3MC9FXEC2Zsk9fhqwpFVaRbZAmPpuRM",
    "DUgmtBaPN5eaJi6Uc43n2t3wEayyvfpaRHyhsVRVtYov",
    "DVwfi8e7UmkiQ6fgnoHaPiwM5BHyN6FCadgMPNfwW5sZ",
    "DWiKYD8oJD4JBH4ix4wP9UZrJprdPPaBLoxZYrBgZNhr",
    "DWk1B8ckhKgcJyHCPyWrPNJApqM3zuUJxR2AHX7KCu2a",
    "DXdyaYGCHujBuUzZxHPhGiavPfWpNUhDwnCDBejt7Rx3",
    "DXwfFZy6mP5zp6Y3MifMPQcYymkxQX8qZSLYgcvqPrQy",
    "DZ3tx98U99vgnfea16M6ckYqpmBzbu9Zix1BVV21zSw2",
    "DZJfhWxRaPgTexCsSVx5r5ce4q5DwVHnPuBPyf9bUhgQ",
    "DZWHeRu4nrCKCkZrcpHQwfz9RsZqAAZgYcQwPSfgJwkj",
    "DZu2dNmGyBSEN7QDLYdFzxy9SkZkz9XipSwMLa8CVV6Q",
    "DajvBVkfRpw2WWYzDnBAXYMo86TeqH97wLr7mrNZacHU",
    "DavyCxB9tL9zVsdyvGbtJLpawtYQ8xbdLahHM97fs2Fo",
    "DbXcrDTpR4qH3BQ4W6hCMRd5Pus8jSQu8NUN8c2kF4SN",
    "DcbdwcxJEZ9DapvQ9HMCfsiPv7B5wgMqT6XenZ1heFrV",
    "DdiDe7ACTQGfwMgDgbNhCnCs8Cuycrha9DYfwvDogcV5",
    "DdkPHx1SMR4vewnYVM43Pu6kVXCyHViH5dGeXBjTNWP6",
    "DfTsYCEaDSPfrK1QKa6cfwkxQCCo4oY349CJGGusgUY3",
    "DjFzjtNd1V7jqevQwMNtUYGoUbYdvrdLu7ucpb3JFsvq",
    "DjoEmiX1U4wYy5TMbG6RfnPo2CP23GZzr1Hx77UATnEW",
    "DnPFqrgB9ZBrjdqdMNN3T5fK8Dg1LUcmMJiApb4SWATh",
    "Do7JBTL4t1GM7XUVvgNBregNtRRqy5hzqEifppXpHVSM",
    "DoDsJB9AAhCy3DxnZzFmjB1VnRbmMs2wTY6NeqofgTEV",
    "DrXGVKyaCjUh5bNmnx5ohayKrsqa5KJ1keJPvFMv2Y6y",
    "Drb7XZTMWjfDq9XcUvaumMeWS9VQeoHpqivxUVMaeCnu",
    "Ds3DjtvjrrndoLN4kGRGj9His8z1yNVqn81QSu7ugaSL",
    "DvD9U1BqcMgG4aZow9QbixeLxfPMD1U292B7784Tc9tc",
    "Dvji98jvvag8SEMihEf2MfnEN3AJXKG6q1ds8aquq8LX",
    "DySSUMDasDwW3HxVEVwXAuMdNYeuvabMz2YRyG9bGHLe",
    "DyuqwezvWSLM8KjEXXA25Ygag8NhjXmnRSARDvENpAXT",
    "E2LHf1NFz2XpYh1YDPZgU7cpGENgE7FzpzmC8Vmx7fHS",
    "E3icsFVdnNtWjghaU32gFiVe91RJCigxkxwRYgxMfHkL",
    "E4PQNSCD33CMoCRfAD3pVyeUUVjTQkAewjANSGBf4d9s",
    "E4cXV91nyRy9EfhGU1Jeskvuy19ovBo9LRuaefjERsya",
    "E63cPgcHfo3QYFh9DLUD14jNQjatXLuWPRrA7Mie6UiL",
    "EDDp7ysu1DN7MwNHXadTBNcBKP1u2mXpYWq3qzscdHny",
    "EEiyorfSJvj9Gei6BGrdghiJDbMsVUVsAcZTm875G5jc",
    "EFBVHK9qmsWUFunfDFU78jqj83fuJm9nV7i9SvRkQDUC",
    "EFzDmZiqdNp4ntv7sKUWdae373ev7J6ar5bPoo2UsLP3",
    "EGUB7RTa7DkWfsddCojL3D5pbX1SKGbwp2C5VA2TTGpC",
    "EQ9JQ66ABqtEio29azfQ4ez7FbDDASyJ3SxMi8KbxKEr",
    "EQYyAzNseJQ9RgaBN3a524vPnUkqB3jRiiSDpm9pmtSz",
    "EQkCn2QyUF19CFkaF1LGw99TE2Vtks5P6baXj6nj6hHu",
    "ERcG9etqUuCZJGKFbqTc6mUeyh8Be75dg5UkcQxGUcXD",
    "ESPmguKdMy7GP5cZx9nsrSAhJFhhYtdh4wy8DjXDgDGG",
    "EVe2zvJfkBJgdhZPYiA1K2oqmBLHPry2AJCdkL3RnhRK",
    "EVyzxYgb8wtCPDjfS1Wr6qVo7bhSUBwjncLCofiv6eFR",
    "EW8C4a5izvvyLiKnZbeoT9M1QYKAEF7v5zpPTZWcYG5w",
    "EXnPFMkf4AMf21Pt4nzdyJeodk556HiNo36DKA35QBU7",
    "EYGebDwxGnytS4NNeuVadbcQ7kHUw6vDTiDhZc4Ew5cE",
    "Eah7qYivxgjiA6tWQPGZyRWnJnDtiHMRBVtdmPeKPZLg",
    "EayvUCdDkvfzXFjVCfVnKEV6mkCu1ghr31NxqaK6QfgU",
    "EdBgrU3o3SNyTeTvjMsbnCRM9kKhVzrmKVPVDBEXXnN1",
    "EeNDa7doVfsfMFFnDFUsqCqC1BKSf39ZYQAFyE7Ruevj",
    "EeqY7goC9BxYX7C1UrxEg2Ymm4ixDiEnxoKw9kZv1rvz",
    "EfFq5wLExu1whPAdAMRyhSd48SRGBDCjtajHsLRdRqyb",
    "EfJSWWnSAG9ztrmq7hRgzwLf2umnbiqHAsMjgbimHSZW",
    "EfPWVFy2Uf82AhPMt6itcN3hdnziptHHok1WKkTE3XBy",
    "EgyqW22JD62yAUGRSYDLiA5Z1R9kkYAkGh23Lc6YowWX",
    "Eh7cSuzQFJ8DRihSar4pyJRQa8iCVPESRvNRrM6mGJkx",
    "EjuXTQBxnKDfNJs19vKcJqAL8LjwF3GS1RbqxDWu92oG",
    "EkrgchhdXLgbWX5Y1ZLf1gU7kQK7ih1v6F3Y1owKypLU",
    "EnT9nT4Qb1ieukbosnKVctbDXmoH7DeAzQXbXvwj84Yx",
    "EoES8PbwKqGTkH5CCDRqvB1e3avouFtWct85cbqSY5De",
    "Ep2xLPMHe5o2mF19fh1k8j4AiBgPxzmUGKpQS6dzmrEM",
    "EqThrdzg3bnTpYxUhVdNcGvpnMkcTk4RTtBiKJuVHn3E",
    "Esg2c2ZY9uEjstvVtbhJkZEEkinVptme6iJSxFWCDZzV",
    "EuJWdPNoH525A2oLhD1Dwv8rYauwbw1D1vUmRTyPtMfw",
    "Ev874uVa42HaeukXTQWyVmWttWuNLX6VqweMTC75zq1u",
    "EvQu8ymbirgjHx34J99QgcrfPrVB7WpsZJXaMB2cucLN",
    "Eva6oishgeDRPk3JWQ3e73ihe34jh4kkU4cmVB4JgAv",
    "Evu5X4oh65agxm95WjeJhsAwopw9pYRwSkrSDoR4oF3S",
    "EwMscVonh2qwRtj5T33avLpMA6dfoZhF7NcmQ7pxuECR",
    "Ex34ygKP46vT3eMhMxqAozSzjACUzRHdrJ2PJqA9nHP3",
    "ExXdVjPdC4D3rQerGyqgbiC3sKDf4EdUP8gK5ABzTA8d",
    "Eyhimd2TkaPXSsibf57UTkAUgQn62U2eJayD6wb7V1gG",
    "Ezn27Z9nhmatyCde4g3t1jxsxjfnuiMHAd2CoZnSJxgM",
    "F1FE5RXm1NAkKvoq1bpWmVjjgrtb66MRX9abQ4GhrKfY",
    "F3VYSHpSr7B3LvKkRZYuKMcMoi5APfQH6Uye8SYXVwGT",
    "F3WcP8nLyg97UGXwBugpnPELeEsy5xew1Hr2FTfTigdf",
    "F4jThWHJvoYNbtqVXhFKywiMgX6Ey8jmHZsJrGmCJGCA",
    "F5joWFMt7QNTn3bsRbSzNSuTbZeB8L3PVHnBfVbGFE62",
    "F6aDkFFVmjmvgy2ABF2Umd3xWtuy7nZJNUcGkvn5ADNQ",
    "F6qkaBupzaWmUkZLnyQVfferghsRBirMKc6nPSh8Gid7",
    "F7bRyei66iEhsv6tT43e9U8g6pB6hUSfRoWp8UtoWuq3",
    "F7jEwZvYarNuSk6fychwDbM32mHQwuKuyTJqPhvnsCC6",
    "F9UvYgfdzJnAao7BxmKP9rPUqoDXxT4wBrAZboYDuTcY",
    "FAPk2YgMQTGdUw1hrKiDundVhh8W8zyhuUNtmaK1juWm",
    "FBfnbS75kMUiHDc1iH2MnGkxKRju5VgeuvfVuAkxaPQ3",
    "FBpX4z76AmcBAH94YJq1qxHhjkJ3cf9Zs8uAcAJCfNxm",
    "FDvgXzYgQgkofmRWmCj95g5iTRaJVAqbtktBdjF2tRui",
    "FELe3t36w8u5eThF72eUhV8EzVAyiP3Kfg6PS7CciTt",
    "FGgmzrxAQJLGWXDAEX6APGxg61qgt6JDeNsaUGptLuZ1",
    "FGzEP5zVgefJW3h4GpMQXShSnFw4LjTP7pbThWZJYkcX",
    "FHassrMEtuPti32TvnKV8cYgcMuQgKk4NngZE2i8Dnea",
    "FHgfkn4ucM8wnem4n4ADxUD2uVkjjqmrELFY6JfxPaN4",
    "FHjjiex27FnbtjzGaoKmCcu7FDTaRaTRjx6uXgXPhghW",
    "FJUj4mxS1UEd1ETVRgaC47CHoSnXajhqHNyLtZhXPPGV",
    "FJdGVf1arJr14rtAAiaJxoYSD8zZPmZCt2qsaYGYgpp",
    "FK4zp5xb4vwd1vBbXSw117ZjJt3tGAcN6YAgX3FmrBV5",
    "FKzFp39PeqM7cyff7yDx3zfQBDknxbDQ8a94PoKvtDG5",
    "FLMZcGcTKs2V1toz2UGqFQP32Xw74H4ELoaYAThxq7x3",
    "FM5TUH52WVDqRvZ3XUvhMQP9KiXUZHoq55YMg6bvQFgR",
    "FMHMJDuhWzCTWNWtL2aDA4VraHEmRrfeXC8bDzSs5UJW",
    "FNcvHUpBTW315LafAJSWnuz1DQoCTTiJ5TBTpMMNUX58",
    "FRiQUDbvWZbMBFW2AG3nw9KgX5MTdVuwCbNm8p5jBeHr",
    "FUJnK9vtvddUDop1HHNurWgfiNH2uHGhvjMK23xYoBRg",
    "FVA4NLFj6rTGYuEUaEu7441kHcrLxX4Shv1bniJ4JguK",
    "FWHSdatdvX7B9vgEPiHrUYMBh2WTEnHY8FtGKEf4hHsf",
    "FWNhwUPLEW3HYFdyU3QZySTm2LyTuuWHhUrxwNWHQjzc",
    "FXZ2cBiUeKwRp7Z4cP8dy8nYArsraDAsgQt6xFDxDC5A",
    "FYSXWwzAkBnedBg9SEVo5Rqu6AExTDmB72Uw7QLveD1v",
    "FYc3YQpMDQ1yJe4edntroximqcxYTBzGmfhjwwBqrfHA",
    "FfstQyktC6QSed2MzKZtfHHQgfk6EJCjFJDLhw6v5gV",
    "FgVSpekuj3j6P52RzRvcQT3hgDKBnF733dhvpprkvJN5",
    "FhN8ns912QB8V7zjBCJ4QtRoHFHpGqKyiH1vXWojaPL1",
    "FhQPPpxexPSteJf6jGckm6kBnreTiRzqzNGZ1KW5ny5o",
    "Fi9yjcx55GJLnqNx28aXtgbfjYgGX38jLDRAFvoffb1i",
    "FiLv3rJ8YwDm3zY6wK5PiegF3sdV3gLn1Fq9KcY5dAfS",
    "FkH2LtzkQbwrqidTmLspE7ZBrcA7wZhxThebxpDxH6St",
    "Fnu589ZUjYv28SWKke6gP9BLrfRFo1oPu6HoDhqfgWUy",
    "Fo6PCiQg4drPzwEUwQBznHpL9pquhV9hxcWmFpMSVH5c",
    "FqvnGvjJwHCgCDGMDhNP2BLA6dMa1Ca2pJakrB3tETy4",
    "Fr7ebG7QvSrBx7XSLPXDhuUSzSUZXTfGCDPv25gvwm3j",
    "FrUVQP8F7Fdr51c1vefz86MU5FtM1ff2qaiKurkxB9Q9",
    "FrwA9w3CRV82ivV7DsmqvkbmHHfHdikhaMNShqxbbawC",
    "FsjX5V48bzZ4mhcPDekgrVEG4qLZhQafctqKc8ZDuTyN",
    "FssH6G8Ss4AVbwqzU8y6UgvCYCtQSaskTVmJc8SM4kxb",
    "FueCvfAai2QYLQy3X29SmeFF8r7qY3sKz13FnThRZmJU",
    "FvVvrAJWKHSuMFkYib2kkBQQ6ak7PpY6BewMVYDQMsVz",
    "FvyqSRtUeaoLwxf88Ajri8paf1dr537mFhv2495HLg5q",
    "Fwhoeh3j7hyMTQM1dvTxNTgXHjhPmVyvPBgcNo4iVNmF",
    "Fy95MZHQ7AqD3UqDPmV87ziQNiojzJtLxDNw4oY2QVa2",
    "FyqmghAKaHxUvRfnxXHv7CeF4rkNyExqt6VbXpYn8yNN",
    "FzH4TYV9jDwVehJGjAn4K8KuetLtaAUAmeSMyTZqqxTX",
    "G13QcgnjYoMSgt8qwcQZTv515eVc54w2nGGFTh8mRLxA",
    "G2VZ91GJFYt3MUZbhF5VohUkM5jwAXzLdV1YrUsEBmsq",
    "G2WM1zSKbunjaKqjQgoJaBSugHLZwQxGQN2ti46fEeAU",
    "G2WrQ2J5iV75Me8JEhF42joLqPUkzjrZDuyMXve3xn2q",
    "G4hoCHseiKK1dhAwQiWgV4dGBkoavABd3kNTDiE6rvtP",
    "G4nExTfQ4nVj1NJFeY1283c6tdd1UyPqfy8igZbB2CST",
    "G5U6hDYLCYWnKQGACh9kCS57L7RyRjK56cuQ2yNRKBkX",
    "G65rpTyfwBW9giXPJn1PzdnaA1K5H54gWCK1TN5ucq4s",
    "G6aJ7WG3hLtGs4uhpFaDqmrQvgE7dSbBHgBDPk7PJB38",
    "G6kBMn3RLEdJqkQQJF41ypDJQeiB3BhZXea9LLdJAmzC",
    "GAtA9dew598yVuxoRcJXvmgCYd1kTXkpqfrZWJtu1qcT",
    "GCMXCsmizFQUmNbgYgdW9GXK43extqFW34pGf6DQ4kkh",
    "GCkHBNhcUh6LrAnkp3B6nX8DaZdDcDWRJvRQRSRr5FRa",
    "GFvPNduZx8MckeLYBybki2feiaJQg7ggxsHwdHAFDcMH",
    "GHr1UezuR5SqqqDNgiJSD4jPvdy3CxYUFzGwk5scPbky",
    "GHrCzXvYELGTfiAL1NndnZ3DvmkkmAcr1gQSC2qfuR2t",
    "GKD5jKSB1mmK3r8ZvjD1HsSQAvwcDF41KA265X3tjN9g",
    "GMyNXjwAj3bdeGC4LF6RSSLyys6TYietmrQGFJHCYQsn",
    "GRV75jd4KA3c3KyZyUzgbedZWmEP8s2V4qLFvvY3GtWf",
    "GRq9EGLuRqnHKJ1mMkVSvrbmqyz78J9bpcP3sg13gH1g",
    "GT91j3oEpCP8cboKMHPK8RVpp3jaNkow6Rt7ZVFYraLQ",
    "GTKoT4gkk6YyJz6KSL9zyKtzizqvZr7qSV8mfdqcoMCM",
    "GTW4kDXRJLZQymDfzUgqrrS6ACE952n5RFAbJuppctFC",
    "GVMcEZAn8bDEnYT2ycE9FgycPqgck7eoDxE7ep3TGswT",
    "GVYXhHQg11aC2C5pzMB4qzWWtHaBoBaxtq8UCJNSqfdL",
    "GVnnczRD6e6RBmD3s9irQiotLHJZUvcGTDxXiDZWUg4z",
    "GXrFmgcbcBsrbogCF5Uu1bK5sV9FhpiCDCep1DDB93e2",
    "GXzdvJwdzyFnGBaWdqxDXYG2pmNM2DMTko3e9PSdg2yT",
    "GYLahcntoVHpveVWruzDeq7oMNhEPR9fBNbVyQGbhhgm",
    "GYZhBY6u3LkuciVFx5UFojBNt3nBd9f2gmQaTErFaCfc",
    "GazmZcqEJWvyV9t85emv6Eth8w1y82wHJGAzJFmq8yWk",
    "Gb1qLQcTUKyUeLTgFaiLZaftvunUwUxxSotGXQmyLxEe",
    "GbrbXAchw13FidTfsmk2m34oG7z3VQvvYCUSTeFs1NVV",
    "GcSanp8vGD8NofqyFXZ54qFg7r42erN39aCDeafrRrQ5",
    "Gcd1qLhzA2N2xhjNk54sJRGuGtRvoBzMhXo47zoBMbW9",
    "GcsYt5kgWu8mvaXdAi5ocrqwk4hLu58mzxvxABiosVvB",
    "GeneFnsMUurXE3Yvszs7DaoUyMdAjAqgURorwSwXLydS",
    "GfSkbwFmRcakpcDRe2WoJVVJ4tdmTDy1nqyGUv5XhfUY",
    "GgNww1mCB7TD6oijrKE2c2xjquzZJsVdzeKKvAVWxoNk",
    "GgoeLKp7qrqcoZesRwAbrCw3okwzvu5sdCXJreJbzwhP",
    "GgtjGKfhsrCF8kqeCRBRUxfWWup7XkCwmdrHRgKgyHmH",
    "GhkuHAAW3NC37z3qv7uN1UdPotpC6Zjn1WvkH5hzGcXA",
    "GiZnSBMQWKy7Hrry8BNGMjX3qPu72vzhcftRS4zWn6sT",
    "GjKDkTqZkQ1ntCUfRnHAfiLTb2731Ds1x1xd6iG7HtUU",
    "GjMyLALVozeYXPqECrPdpFoHXi3VULJfpk74hvt2A2na",
    "Gka4eYG9QMuLEztd2ctZQf9DcZQHpxukRpttrS4cEgcm",
    "GkkSvBz43h6EcgKFXoDpZTQGLaDgePUW931jZZeb2Y8T",
    "Gm5RdjC86m6qALtT6ktW2ybib3pcDoW2bhKFjdZMeP1D",
    "GmiGsT6nZ1jHEzW2GEQq1jdj2mRwTH4ix6L3QRmFfPpU",
    "GmxtmwNTayjg72jxcyQwug2D8qbAiwoXMeJaLMSXDb53",
    "Gn1QGBo319qRUdk36f4FNLc9jaLr4YwvaVodJZET2oyX",
    "GqJznRJd7pUQAGxWucugvpgMFBxu34GhCua7SVwHKRcb",
    "Gqo3zFNMmsPbomXSPGVsFjwt3p5C7koWYtrcX7Nbvsu2",
    "GrLZuytVhbp1Hmp5Z1oWSmZ3E5kfSV2Vyu4vUU3XxAkH",
    "GsTyk3MdXCSbVaSMM1KdvBWcaxYCu5pDLKLEydfWTmqY",
    "GtQiBL9sn8Ck2VRroHfHgTwMvAp84i5fyK5J8oHdZKPg",
    "GugQYyBdnF3SqLvGRPyEZ98kNVm5zfqMTpn5EcKDZKSA",
    "GwbT8FpMsWBmveL3nicJqtqHL3ik2mmGHqeDbSsp7gLo",
    "Gwu4fKRUJNJwivpU9e6JobdFB56HXmCVnKoAh8x7rW3d",
    "H1wjwb55QeXEhPMn2cUA3W2vB3hZKyydimRJDSt6WJJz",
    "H3Au6r8RqX3sCC6NmCWy17ypd2GfCxX3rrbD8HwJBPCe",
    "H3SpVjFEFgrPnvCPntQDZBy188qsSPtPUEDzryMWVabU",
    "H48pkJkHkWq9sTtwWCd67zW8hjjA6nNqqsKKX5XFZwia",
    "H6ennCjemFmTVgkJK3EcDPxEqdPn8KNfPa4PMzqLWsvZ",
    "H8SK4tg67FESDyxKcTebc5RBMieECF5YiVpgtLHzJ8GE",
    "HAFKCcBxoSUNvuJyL8sMtwR1Q81Eb6QgCUN8NwoQe6jc",
    "HBDhBa2TRvo39btL6xfhRttus7uPBGW5RSRihbgMPFci",
    "HCcRton9eQeZfb5HDu3vd9AqTzPSRPXBpanZWCKpDGs8",
    "HExLHLyNhsgTxBJwLtVMQQyFTSLrb5j7MFqXyoxLemy",
    "HGb2v2u2N1CSkjvrMeF9TED1M1zdmrD4yzk3K3qpFGow",
    "HGhqtmyXRKWFBE22kk5tQjCFDEH2eWDf5KYuR45KxsaU",
    "HJN2zJiXuumtTDqWTztc5j6wcFJmQw6dARi7gxhUS3xs",
    "HKQjdApzz3pUev1mZAFMfN8u5nRnZzC8FZePUvMyDjmh",
    "HKbHWy2aq85MzGyPGQBeg2mXN63HBLLJaG7dzzkuCegv",
    "HKnmoxNEq19ctuFZVD7pix2Qmu6gdu2d959Y4MaX3J5M",
    "HLDtUBL4vG7F2BXhEHYphHp2NaEDY9LfGqAxwst3itP2",
    "HLdvmpW1NhV2rrc1vKn7ygU9BpSC3NMfkKSSHjxtNvnT",
    "HRf5DXQ4uTcNo6QR7jC2bnhNwhFFqvbHTKmkxXjGGh28",
    "HRfoAiQmiT3BoB3Ja8KnodzwbAtfQAcC2uGM3FjvzU5i",
    "HTNUoAxtsmEXVrM1s2Uz6NoHhShT5vSmGiru9jAT1yEj",
    "HVXrwy28HwyhxnDgjVJj6nPu8N62FFuW8rZoWuP7HX9x",
    "HVkK39YYn7DrxeHQ9GnUDD8mHkpctRCQxuoX3yHXonSg",
    "HYebQBTxmK964XCrcEQy1PyqYu6xoFRXEkU8vmNeEYUh",
    "HZcv1ko8hcrGUqngscmxVZpidEycQTxzNkBfdFjG849Y",
    "HZgk1T8LBbyDzJCBiUJWQ5Z1uZGrYMSQponSo33i25Gs",
    "Ha4GpH7vJfLeUgjgW5CzmuKyGTjcurHVoUVq1Rjrxvju",
    "HaCJcVESjUeuS52JxPVGdtEJUfQSL2UCU2gyH4emi5hG",
    "HaQzWBj71tFehKicFJ71yQbiANwKe8k557trxj3LcLVn",
    "HbFtTwuSADjbMCWkTRh5WKECgiiAejDPWHiJ549kotpy",
    "Hc3EyoKT1MhqmABMCLpXLEqQeLt3bVnvHrU6puuFX49Q",
    "HcVqU7juiXCt6tGxfmqAfM57vFAmGmUoJRsinFwzdfQt",
    "HeJ5JTGy8F5D9jT1MNfoPWPQ9FoFJDjwU9B7TjKvWkJF",
    "Hfr3Dpzdj4uE3qp1RuceFPFEDqHqC8paitZz1kiep257",
    "HhoJFJWnrTNMbssce7uxCTkcBkF6iHsYfCt9JxEUHRQc",
    "HiZJJMLXYi5LUj5DtEEawusarV8MP3qjSoq7aLsVJWpx",
    "Hia9mx4CdMBhe89AMtXQDDtNNarvBzN7Z9XY86a13k7",
    "His3FTtvcXDAYvpTFKTuvYCj77NGBPidxNj47195uGRt",
    "HjR2E7EBHZic4nXrApq6mJ8zuEP3PzaXuLr61z15asqZ",
    "HjbnZaEo7J6WhSKeFQor5nuPZFAG2G6Q8kmSanAQxXTz",
    "HkESYJ8KoGmFUk7WHxK1p97znTJvwwn4FmFqq8aGvu5Y",
    "HkZGPo1VWUzecyRV9R6AVYnAqnjwXi6ZsyNYhRH2F5u3",
    "Hm9HoeGNtvRiB8NvPBDHyZJsgVUZmJJo8Dc9anPbPkka",
    "HoBXxjd42Y4JL5ebZiavpJGNkP5p8krPa5CZgTELDGgg",
    "Hp9Dsca72xpZBpvbZ45fcAuTK573hmvVMqD9a83ZQJSa",
    "HpTRqGj719LHmhXzvfC2iYakp4fNQ31947SZBgJo5kWg",
    "HpbX2i3KdcVJYgpthcfVxrp2RRco57SKufdwm9DTPYTw",
    "HqMPPtVba5ouSpBcp87dPQ7nPCRD1hhTAVpkJkhp8q49",
    "HqhL81CDqpEYzRTiR8WcZjU2zUeLdk5CsmKB54HcmQ1n",
    "HrF525bziR3LmmfyYmA56y91u1GNLov3iPhx9u2wiV38",
    "HsPx2PcC8RHd3N9tC8hVvNe4TdahNoHth9biufaNfrSB",
    "Ht3Qc3Pn1H7wVCuMKNwY1wTv2bQrMeoeWP2hLnuaSpGu",
    "Hu77xgsqzA7tXCFAkKoy3wJCevzHhYQSKE9cetNKDC4e",
    "HuwENHKWWnNJxRUwRv4sUGi48FPqYTxdS7LKXGKQn5k2",
    "HvcVkZeB77x1rurTBE2pPthxmx3C894rEBf3odpy6g7d",
    "HwmA9NMysTbH3EmuvpoQ8PZz3J1UmZNKMXKHt9UXuRf8",
    "Hx8yP8BNLn7UUbyaa4PQJK7KsRBcbRmhdt11TWvsArZD",
    "HxCxo2eaFDfUDeSqm3B2BrabT6TEUW5RmDt1tYJhYzTq",
    "HxJYEob8a4CrKi1Jbk9N5qpro4SY9naZSLD7hb4E3a5n",
    "HxVsh9XUL77LM4voUT43LA61ycPQsJNmVq6ekbhun3pd",
    "HyExMoDDMoKrCPaKV5yt9eKfND6rhMRMSkSjcSeGiBgn",
    "J2HjVLMmjyoijhNLoV4ahUakaJ3iN9NAhL12JbuLtya9",
    "J2vFYYZEBCabAvhbMvSxZdsftKuAKkja2imRJdvJHV7s",
    "J5L2mN8WKynXNSDM4KraZ3EHQDGVpkegnkB2PQt63VUA",
    "JBtfXzuR8JvbioTeHw7EdFkBRUxRJ3FE1mn4TQaScSkE",
    "JCzG8vPANTAQae7QRjjnJd8GzAuMcdRqVZ3Qhg7XfCje",
    "JD1Yv2PiaP3hGJsMp3jXr7LFe2d58dB3rLzmhQAG1nBC",
    "JDTcTdJ5zftASCrb4Frvp3gFEXTUymSyUsA3HAYstGnD",
    "JDXimREYU1BVjDY9NaWzNpv2TETMkvU7FaNe4wTLwEED",
    "JJm8Dywvq5Zjkc15WebWvn3qssXjeXMsmUDVuC7ZvsR",
    "Jm47KSQ5mhvZYmzcP9k2cufcLygFG8QBi2Dutwo5ZJN",
    "JpGvwQvNvAudXnssywuuazddj78etpZf3PXz1UHQTqc",
    "KAsUdP7z8Z8UWSAr6uAAe2x2EHBZwpGbTvmVWfC67sk",
    "N88bZPVxATrCFfGFbNih3kNwk9yzfTQ5PSSMjFZETms",
    "PBWe6bKsh1JetjtgdGNkZJj1upyckmVzhbyKAPbRJky",
    "PgF9cQcjnv7Wa52x32pp8vWpkichA29kP7KXWjQuBQX",
    "RnVHSZwWSqNExdvMiDwZVpdvs4RHjrSr4StqXacuw4s",
    "Rzv2jCnbQRJUwjxruUqPdH7zs2K13hYQUkgwVySngHA",
    "STVmLQYrykiSLFQeNmYZwHrMeb1q13BQ1nTsSXn17aP",
    "TEwPYguqN4P8Tq7kDcZmTzkkgUJcRLCy4tAcct2cxfS",
    "TbuLv96hGoRewPxEdHJreX8842MaQSbsZoPxdyZpQc1",
    "UQEX9ATRqQAaFFeq34evYyHRB8mUF6W1fEsweShDvVZ",
    "UUeFQTr46yoKcinU7kQ7uKsc4PKW9abcJRAMnzvtWrh",
    "VBwiSqoVFwDEpzHFDmX6TwS8RmSNpW1gjvZFS2jwGd5",
    "VSaaJvmXe1MEmJBSK9d9FQktcxWuxqeXCAAVpzt3UFz",
    "WVKjN7CwJpCtCfF2k2fcWGkcsYbk2EPM9h5yEVdBeiG",
    "XCymx4iDCBkWTcuHUvdkEHHJ42rFK1xnUM8yJM2M7WK",
    "XVRLJYKB5Ez1czgAhHHzTub8B5wLV5e2Ub69DSurW3L",
    "Ygo9L65jUB7jTZf1wyxJbzQep6f8HdBzen2Gct47XuE",
    "ZirQjCrMPgasKAVa9pmaPFDXhtaz26vt12AF3qVFbEW",
    "cHoiTMAjfteAqo2yGkgqpDxiSRFgzMK5xgJSS4gKtdp",
    "dSKiRc1pGkcdZJoMKJrHpay5gjMjPy11paDUaNctKvo",
    "frGZNkYz8B8AQgNtiJH9VRHUcLEvYKLz6SdacwwZj7v",
    "frepf9XSj2RnkqfuuWDoqaKCDpha8UZYQacT86Rxjfe",
    "gH3aUi2yQgVAz8zpDmLsNkBkgZ7dx6ih3QxnS4LG9Mo",
    "gUiuYVj6VcUpEtLLzCTnd9J2LkEq4hoR1dTzh3ZTJmZ",
    "htoSvTTi3auTBKujThAjSNwvbv4wqdDvLBHFQQ7DVAf",
    "iM9P5KYZ2R5uvvW8zXCcueA56X5kHodVUHJ6Fn7tzPM",
    "jZSfT62hxZ6YZZ3ZscqJb27gJrAqqR8eNPFzkd1b8jn",
    "pXaAFMxZxxx7Z7aBrob9No5Y939wRuBqY1cgmfpbd4u",
    "q3cgPkwNwaV34ZbBStJMjLDUVkVCSiCZCEqLZhYtSDk",
    "qZ8hrnPU2xjvwnsxjgNg9BwD2mgP31TxeZWDPkC9XRZ",
    "qZxSBfSNoER6ojwG2G8KaSBWBpmv3gYmFCPxjkMFRUg",
    "rfB8fjbKsbZXDuCUzuX9a8d4qqeMm7HRytZpQUeRovx",
    "t8VUrP9tx1HU6cAX8Fp9z2JXWomFWRZYx7WSfhXdhWw",
    "tZzrefRTjT2A56pN66VtKVsfsR3sgTNMeyf1XBu25Wz",
    "vvWtDLKsTm4hwRYGigmTgRNj4ZMzbdAiHwX2D7AdnW1",
    "wTWvTVoKvHEaQ7hcxqcuSYjkk9DvM3P5Je4GwoGLEP2",
    "ww6BJkZbEfJCh6kdHQWrWC6eDq4n9CvkiXJiDevHSKt",
    "xZoXrczGQjqvceV8d5tCbkQcMhz4zfCBvTTjzCv85CD",
    "zG5qdtHiE2BD4pzwwYTncmgkQVio6jUxxZWfm8ewavP"
  ])
  const [metadataRequirements, setMetadataRequirements] = useState(['Tail'])
  const [currentlyUpgrading, setCurrentlyUpgrading] = useState({})

  //user variables
  const [sortedData, setSortedData] = useState()
  const [selectedNFT, setSelectedNFT] = useState()
  const [imageArray, setImageArray] = useState([]);
  const [readyToUpgrade, setReadyToUpgrade] = useState(false)
  const [metadata, setMetadata] = useState({})
  const [newMetadataID, setNewMetadataID] = useState({})
  const [metadataLink, setMetadataLink] = useState();


  // Grabbing the wallet NFTs - will only grab it once
  // TODO: reload NFTs on a wallet change (new connection, disconnect, and change wallet)
  useEffect(() => {
    if (publicKey && !fetchedWalletNFTs){
      const getNFTs = async () => {
        let myNfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        let walletDictTemp = {}
        myNfts.forEach((nft) => {
          walletDictTemp[nft.mint] = nft.data.uri
        });
        setFetchedWalletNFTs(true)
        setWalletNFTs(myNfts)
      };
      getNFTs();
    }
  }, [publicKey, fetchedWalletNFTs])

  // grabbing all eligible projectNFTs
  useEffect(() => {
    if (walletNFTs.length && projectHashArray.length && !filteredProjectNFTs){
      const filterUserNFTs = async () => {
        console.log(ownedNFTDict)
        console.log(userProjectNFTs)
        let ownedNFTDictTemp = {...ownedNFTDict}
        let currentlyMutating = []

        var data = JSON.stringify({
          projectID: props.projectID,
          projectHashArray: projectHashArray,
          action: "currentlyMutating",
        });
        var config = {
          method: "post",
          url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations",
          headers: {
            "x-api-key": process.env.GATEWAY_KEY,
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(async (response) => {
            let currentlyUpgrading = {}
            await walletNFTs.map(async (nft) => {
              if (response.data.upgradingNFTs.includes(nft.mint)) {
                currentlyUpgrading[nft.mint] = response.data.currentlyUpgrading[nft.mint]
              }
            })
            setCurrentlyUpgrading(currentlyUpgrading)
            currentlyMutating = response.data.upgradingNFTs
          })
        console.log(currentlyMutating)
        await walletNFTs.map(async (nft) => {
          if (projectHashArray.includes(nft.mint)) {
            var config = {
              method: "get",
              url: nft.data.uri + '?nocache='  + (new Date()).getTime(),
              headers: {
                "Content-Type": "application/json"
              }
            };
            let nftTemp = await axios(config).then(results => {
              return (results.data)
            })
            if (metadataRequirements.length){
              let toAdd = false
              nftTemp.attributes.forEach(attribute => {
                if (metadataRequirements.includes(attribute.trait_type)){
                  toAdd = true
                }
              })

              if (toAdd && !(currentlyMutating.includes(nft.mint))){
                ownedNFTDictTemp[nft.mint] = {...nftTemp, metadataLink: nft.data.uri}
              }
            }
          }
        })
        console.log(ownedNFTDictTemp)
        setOwnedNFTDict(ownedNFTDictTemp)
        setUserProjectNFTs(ownedNFTDictTemp)
        setFilteredProjectNFTs(true)
      };
      filterUserNFTs();
    }
  }, [walletNFTs, projectHashArray, filteredProjectNFTs])

  const setUpgradeNFT = (selectedNFTDict) => {
    console.log(selectedNFTDict)
    setSelectedNFT(selectedNFTDict.hash)
    var data = JSON.stringify({
      projectID: props.projectID,
      selectedNFT: selectedNFTDict,
      mutationType: 'Tails',
      action: "RRTailsMutation"
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
    .then(function (response) {
      console.log(response.data)
      if (!response.data.error && !("errorMessage" in response.data)){
        setReadyToUpgrade(true)
        setMetadataLink(selectedNFTDict.metadataLink)
        setMetadata(response.data.newMetadata)
        setNewMetadataID(response.data.newMetadataID)
        setImageArray(response.data.imageArray)
        setSortedData(response.data.finalSortedData)
      } else {
        setPopup(true);
        setPopupState("unexpectedError");
      }
    })
    .catch(function (error) {
      // // // console.log(error);
    });
  }

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const mutationTransaction = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    let identifyingHash = randomHash(50);
    setPopup(true);
    console.log(props.projectCreator)
    const feeWallet = await new web3.PublicKey("DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix");
    const creatorDestination = await new web3.PublicKey('F745LBVxAdjpieZ1vnFxUvTfzFrt6xnz4iMLzVAjsiVj');
    const tokenPublicKey = await new web3.PublicKey('ADQwix6UMnhZ13iAd5xQMWFUuw8cJRGj1RioqP3GZebg');

    const destinationWalletCoinAccount = await getAssociatedTokenAddress(
      tokenPublicKey,
      creatorDestination
    );

    let coin_account;

    try {
      coin_account = await getAccount(connection, destinationWalletCoinAccount);

    } catch (error) {

      try {
        const coinTransaction = new web3.Transaction().add(
          createAssociatedTokenAccountInstruction(
            publicKey,
            destinationWalletCoinAccount,
            creatorDestination,
            tokenPublicKey,
            TOKEN_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        );

        const signature = await sendTransaction(coinTransaction, connection);

        await connection.confirmTransaction(signature, "processed");
      } catch (error) {
        console.log('got here')
        throw error;
      }
    }

    const sourceWalletCoinAccount = await getAssociatedTokenAddress(
      tokenPublicKey,
      publicKey
    );

    let tokenInfo = await connection.getTokenSupply(tokenPublicKey)
    let decimal = tokenInfo.value.decimals
    let finalDecimal = 10 ** decimal

    var transaction = new web3.Transaction().add(
      createTransferInstruction(
        sourceWalletCoinAccount,
        destinationWalletCoinAccount,
        publicKey,
        1 * finalDecimal,
        [],
        TOKEN_PROGRAM_ID
      )
    );

    const latestBlockhash = await connection.getLatestBlockhash();

    // transaction.add(
    //   web3.SystemProgram.transfer({
    //     fromPubkey: publicKey,
    //     toPubkey: feeWallet,
    //     lamports: web3.LAMPORTS_PER_SOL * 0.03,
    //   })
    // );
    //
    // transaction.add(
    //   web3.SystemProgram.transfer({
    //     fromPubkey: publicKey,
    //     toPubkey: creatorDestination,
    //     lamports: web3.LAMPORTS_PER_SOL * 1,
    //   })
    // )

    var data = JSON.stringify({
      action: "transactionTemp",
      nftHash: selectedNFT,
      metadata: metadata,
      attributeIDDict: newMetadataID,
      projectID: props.projectID,
      metadataLink: metadataLink,
      identifyingHash: identifyingHash,
      traitHash: "none",
      imageArray: imageArray,
      upgradeType: "tailMutation",
      secondaryHash: [],
      collectionName: 'Base',
      sortedData: sortedData
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };
    //
    axios(config)
    .then(async (response) => {
      console.log(response.data)
      const preApproved = response.data.preApproved;
      try {
          const signature = await sendTransaction(transaction, connection);
          await confirmMutation(selectedNFT, publicKey, signature)
          var data = JSON.stringify({
            action: "transactionConfirm",
            identifyingHash: identifyingHash,
            userWallet: publicKey.toBase58(),
            transactionID: signature
          });
          var config = {
            method: "post",
            url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setupgrade",
            headers: {
              "x-api-key": process.env.GATEWAY_KEY,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config).then(function (response) {
            connection
              .confirmTransaction({
                blockhash: latestBlockhash.blockhash,
                lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                signature: signature,
              })
              .then((sentData) => {
                setPopup(true);
                setPopupState('successfulMutation')
              });
          })
          .catch(function (error) {
            setPopup(true)
            setPopupState("transactionError");
          });

      } catch (error) {
          setPopup(true)
          setPopupState("transactionError");
          throw error;
        }
    })
    .catch(function (error) {
        setPopup(true)
        setPopupState("transactionError");
        // // // console.log(error);
      });
  }, [publicKey, connection, selectedNFT, newMetadataID, metadata, metadataLink, sortedData]);

  const confirmMutation = async (nftHash, publicKey, signature) => {
    // // console.log(selectedTrait)
    var data = JSON.stringify({
      action: "confirmTransformation",
      projectID: props.projectID,
      purchasingWallet: publicKey,
      transactionID: signature,
      hash: nftHash,
      transactionType: 'fusion'
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        console.log(response, "confirming fusion");
        return true;
      })
      .catch(function (error) {
        // // console.log(error);
        return false;
      });

    return returnResponse;
  };

  const renderPopup = () => {
    if (popupState === "default") {
      return (
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <div style={{ backgroundColor: `${theme?.color3}`}} className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10">
            <div className="w-24 mx-auto mb-5">
              <img className="max-w-[25px] mx-auto" src={Loader} alt="loading..." />
            </div>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Upgrade in progress - please follow the prompt on your wallet.
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white">
              {" "}
              Once transaction is approved please remain on the page for confirmation (transactions
              may take a couple of minutes to confirm).
            </p>
            <br></br>
          </div>
        </div>
      );
    }
    else if(popupState === "transactionError"){
      // // // console.log("got here error")
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}} className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10">
          <div className="w-24 mx-auto mb-5">
            <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
          </div>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            There was an issue in confirming the transaction. Please reload the page. If your NFT has been upgraded or appears in the currently upgrading section of our site you can ignore this message.
            If not you either  cancelled the transaction or the
            transaction was never processed. Please try again!
          </p>
          <button
            onClick={() => {
              resetPopup('cancel');
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
    else if (popupState === "successfulMutation") {
      let tempImageArray = [];
      imageArray.map((link) => {
        tempImageArray.push(
          <img className="absolute  top-0 left-0  w-full h-full object-contain" src={link} />
        );
      });
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}}
          className={
            "fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-sm duration-100 z-10"
          }
        >
          <div
            style={{ borderColor: theme?.color1 }}
            className="pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red"
          >
          {tempImageArray}
          </div>
          <p className="font-text font-bold text-white my-5 text-xl">
            Congrats, your upgrade was sent successfully!
          </p>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            You should see your metadata and image change soon (as soon as the project owner
            approves your upgrade)!
          </p>

          <button
          onClick={() => {
            resetPopup();
          }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
    else if(popupState === "unexpectedError"){
      // // // console.log("got here error")
      return (
        <div style={{ backgroundColor: `${theme?.color3}`}} className="fixed bg-primary-red p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10">
          <div className="w-24 mx-auto mb-5">
            <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
          </div>
          <p className="max-w-[300px] mx-auto font-text text-white">
            {" "}
            There was an unexpected error please reload the page.
          </p>
          <button
            onClick={() => {
              resetPopup();
            }}
            className="text-2xl text-white"
          >
            &#10761;
          </button>
        </div>
      );
    }
  };

  const resetPopup = async (reason) => {
    if (reason === "cancel") {
      setPopup(false);
      setPopupState("default");
    } else {
      setLoadNFTs(false)
      setReadyToUpgrade(false)
      setFetchedWalletNFTs(false)
      setFilteredProjectNFTs(false)
      setSelectedNFT()
      setOwnedNFTDict({})
      setUserProjectNFTs({})
      setPopup(false);
      setPopupState("default");
    }
  };

  const reloadData = () => {
    setReadyToUpgrade(false)
    setFetchedWalletNFTs(false)
    setFilteredProjectNFTs(false)
    setSelectedNFT()
    setPopup(false);
    setPopupState("default");
  }

  const renderNFTs = () => {
    return(
      <>
      {
        Object.keys(userProjectNFTs)?.length ?
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {Object.keys(userProjectNFTs)
            .map((nft) => {
              console.log(nft)
              return (
                <div>
                  <img
                    src={userProjectNFTs[nft].image}
                    onClick={() => setUpgradeNFT({...userProjectNFTs[nft], hash: nft})}
                    className={`w-full cursor-pointer border-2 ${
                      selectedNFT === nft
                        ? "border-primary-red"
                        : "border-black"
                    } p-1`}
                    style={{ marginTop: 10, borderRadius: 10 }}
                  />
                  <p
                    className={
                      "text-center pt-2 text-gray-deep uppercase font-gilroy-bold text-[12px]"
                    }
                  >
                    {userProjectNFTs[nft].name}
                  </p>
                </div>
              );
            })}
        </div>
        :
        <p className="text-center pb-5">You don't have any NFTs eligible for mutation in your wallet</p>
      }
      </>
    )
  }

  return (
    <div className="w-full lg:pl-28">
      {popup ? renderPopup() : ""}
      <div className="bg-white-off  w-[95%] lg:w-full   relative  py-10 grid md:grid-cols-[max-content,1fr]  xl:grid-cols-[max-content,minmax(400px,1fr),max(10%,250px)] gap-3 xl:gap-7  ml-auto  pl-20 pr-5  ">
        <div className="SelectedNFT lg:min-w-[234px] 2xl:min-w-[364px]">
          {readyToUpgrade ?
              <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full">
                <div
                  style={{ borderColor: theme?.color1 }}
                  className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
                >
                  <img className="absolute  top-0 left-0  w-full h-full object-contain" src={MysteryUpgrade} />
                </div>
              </div>
              :
              <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full ">
                <div
                  style={{ borderColor: `${theme?.color1}` }}
                  className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
                >
                  <img
                    className="absolute bg-white top-0 left-0 d w-full h-full object-contain"
                    src={NoImage}
                  />
                </div>
              </div>
          }
          <button
            disabled={!readyToUpgrade}
            onClick={() => mutationTransaction()}
            className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50"
          >
            UPGRADE NOW
          </button>
        </div>
        <div className=" bg-white p-5 rounded-md min-h-[600px]">
          <div className="grid grid-cols-2">
            {
              loadNFTs ?
              <P className="font-title-bold text-primary-red text-[24px] text-left mb-5 relative">
                READY TO UPGRADE
              </P>
              :
              <button
              disabled={!publicKey}
              onClick={() => setLoadNFTs(true)}
              className="w-full bg-dark-gray object-center object-cover text-sm rounded-md text-white py-2 px-3 disabled:opacity-50 hover:opacity-50">
                LOAD YOUR TAILS
              </button>
            }
            <img
              className="font-title-bold text-primary-red text-[26px] col-start-3 mb-2 relative max-w-[30px]"
              src={Reload}
              alt={"reload"}
              onClick={() => reloadData()}
            />
          </div>
          {
            loadNFTs ?
            <> {renderNFTs()} </>
            :
            ""
          }
        </div>
        <div className="bg-white p-5  rounded-md  xl:max-h-full h-full xl:max-w-[250px] xl:order-3 -order-2 col-span-2 xl:col-span-1 flex flex-col  ">
          <P className="font-title-bold text-primary-red text-sm text-center mb-3 relative">
            CURRENTLY MUTATING
          </P>
          {Object.keys(currentlyUpgrading)?.length > 0 ? (
            <div className="w-full h-3/4 xl:h-full overflow-auto flex-grow max-h-[600px]">
              <div className="flex flex-nowrap xl:flex-col  w-max h-full  gap-2.5 xl:w-full  ">
                {Object.entries(currentlyUpgrading).map(([key, value], i) => (
                  <div
                    key={i}
                    className="shadow-md p-1 xl:p-2 rounded-md transition ease-in-out hover:-translate-y-2 duration-300  w-full h-full flex flex-col max-w-[96px] xl:max-w-full "
                  >
                    <div
                      style={{ borderColor: theme?.color1 }}
                      className="w-full relative border-2 rounded-md"
                    >
                      <img
                        src={`${currentlyUpgrading[key].image}?${new Date().getTime()}`}
                        className=" w-full h-full"
                        style={{ borderRadius: 3 }}
                      />{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-center pb-5">You don't have upgrading NFTs</p>
          )}
        </div>
      </div>

    </div>
  );
};


export default MutationTails;
